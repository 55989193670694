import { composeReducer } from 'redux-compose-reducer';

const initialState = {
  data: {
    experts: [],
    expertsTotalLength: 0,
    titles: null
  },
  expertsState: {
    page: 1,
    title: null
  },
  expertsLazyLoading: false,
  expertsLoading: false
};

function getExpertsFilters(state, { data }) {
  const titles = data.experts.filter(field => field.name === 'title')[0].choices;
  return {
    ...state,
    data: {
      ...state.data,
      titles
    }
  };
}

function getInitialExperts(state, { data }) {
  const experts = data.experts;
  const count = data.count;
  return {
    ...state,
    data: {
      ...state.data,
      experts,
      expertsTotalLength: count
    },
    expertsState: {
      ...state.expertsState,
      page: 1
    }
  };
}

function getAllExperts(state, { data, page }) {
  const expertsArray = data.experts;
  let experts = [];
  if (page > 1) {
    const { experts: expertsFromState } = state.data;
    experts = expertsFromState.concat(expertsArray);
  } else {
    experts = expertsArray;
  }
  return {
    ...state,
    data: {
      ...state.data,
      experts,
      expertsTotalLength: data.count
    }
  };
}

function setExpertsState(state, { data }) {
  return {
    ...state,
    expertsState: {
      ...state.expertsState,
      ...data
    }
  };
}

function switchExpertsLazyLoading(state, { data }) {
  return {
    ...state,
    expertsLazyLoading: data
  };
}

function switchExpertsLoading(state, { data }) {
  return {
    ...state,
    expertsLoading: data
  };
}

function resetFilters(state) {
  return {
    ...state,
    expertsState: {
      ...initialState.expertsState,
      title: null
    },
    data: {
      ...state.data
    }
  };
}


export default composeReducer(
  'experts',
  {
    getExpertsFilters,
    getInitialExperts,
    getAllExperts,
    setExpertsState,
    switchExpertsLazyLoading,
    switchExpertsLoading,
    resetFilters
  },
  initialState
);
