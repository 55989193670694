import React from 'react';
import { routes } from 'routes';

const meta = {
  title: 'Contact Us',
  description: 'Learn how to stop dieting and start succeeding with expert medical and health guidance',
  keywords: ["weight loss", "nutrition in bellevue"]
}

const page = {
  name: 'Contact Us',
  subTitle: 'At 20/20 LifeStyles, our goal is to help you lose weight and keep it off. Should you need assistance, please refer to the following information.'
};

const sections = [
  {
    sectionExtraClassName: 'noTopPadding',
    textBlocks: [
      {
        align: 'left',
        text: (
          <p>
            Before contacting us, you may want to review
            our <a href={routes.RESOURCES_AND_TOOLS_FAQS}>Frequently Asked Questions (FAQs)</a>. Many common issues and answers are available to you immediately.
          </p>
        ),
      },
      {
        align: 'left',
        title: 'General',
        text: (
          <div>
            <p>
              Program inquiries and appointment scheduling: 425.861.6258 or 877.559.2020
            </p>
            <em>Billing Inquiries</em>
            <p>
              Email: <a href='mailto:billingsupport@2020lifestyles.com'>billingsupport@2020lifestyles.com</a><br />
              Phone: <a href='tel:+1-425-895-6508'>425-895-6508</a>, Monday-Friday, 8 am–4 pm Pacific time.
            </p>
          </div>
        ),
      },
      {
        align: 'left',
        title: 'Address',
        text: (
          <p>
          4455 148th Avenue Northeast<br />Bellevue, WA 98007
          </p>
        ),
      },
      {
        align: 'left',
        title: 'Technical Support & Feedback',
        text: (
          <a href='mailto:support@2020lifestyles.com'>support@2020lifestyles.com</a>
        ),
      },
      {
        align: 'left',
        title: 'Suggest a Food for the 20/20 LifeStyles Food Database',
        text: (
          <a href='mailto:support@2020lifestyles.com'>support@2020lifestyles.com</a>
        ),
      },
      {
        align: 'left',
        title: 'Store and Products',
        text: (
          <div>
            <em>Order Inquiries</em>
            <p>
              Email: <a href='mailto:shop2020@2020lifestyles.com'>shop2020@2020lifestyles.com</a><br />
              Phone: <a href='tel:+1-866-757-1076'>866-757-1076</a> or <a href='tel:+1-425-376-3500'>425-376-3500</a>, Monday-Friday, 8 am–4 pm Pacific time.
            </p>
          </div>
        ),
      }
    ]
  },
];

export {
  page,
  sections,
  meta
};
