import React from 'react';
import IconBase from 'react-icon-base';

export default function Search(props) {
  return (
    <IconBase viewBox='0 0 30 30' {...props}>
      <path
        d='M90.1,84.4L75.2,69.5c11.4-14.2,10.3-35.2-2.7-48.1c-6.8-6.8-15.9-10.6-25.6-10.6c-9.7,0-18.7,3.8-25.6,10.6
        c-6.8,6.8-10.6,15.9-10.6,25.6s3.8,18.7,10.6,25.6c6.8,6.8,15.9,10.6,25.6,10.6c8.2,0,16.2-2.8,22.6-7.9l14.9,14.9
        c0.8,0.8,1.8,1.2,2.8,1.2c1.1,0,2.1-0.4,2.8-1.2C91.6,88.5,91.6,86,90.1,84.4z M75.1,46.9c0,7.5-2.9,14.6-8.2,19.9
        s-12.4,8.2-19.9,8.2c-7.5,0-14.6-2.9-19.9-8.2s-8.2-12.4-8.2-19.9c0-7.5,2.9-14.6,8.2-19.9s12.4-8.2,19.9-8.2
        c7.5,0,14.6,2.9,19.9,8.2S75.1,39.4,75.1,46.9z'
      />
    </IconBase>
  );
}
