import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import Swiper from 'swiper/dist/js/swiper';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';
import { Modal, SliderArrow } from 'components';
import { heroSlider } from '../../../../mocks/pages/HomePage';
import styles from './HeroSlider.scss';

const overlayStyles = {
  backgroundColor: 'rgba(0, 0, 0, 0.75)'
};

const options = {
  slidesPerView: 1,
  simulateTouch: false,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev'
  },
  autoplay: {
    delay: 3000
  }
};

class HeroSlider extends Component {
  state = {
    activeIndex: 0,
    visible: false,
    selectedSlide: {}
  };

  componentDidMount() {
    this.swiperInit();
  }

  componentWillUnmount() {
    this.swiper.destroy();
  }

  setActiveIndex = () => {
    this.setState({
      activeIndex: this.swiper.activeIndex
    });
  };

  swiperInit = () => {
    this.swiper = new Swiper(this.slider, {
      ...options
    });
    this.setActiveIndex();
    this.swiper.on('slideChange', this.setActiveIndex);
  };

  openModal = (item) => {
    this.setState({
      selectedSlide: item,
      visible: true
    });
  };
  closeModal = () => {
    this.setState({
      selectedSlide: {},
      visible: false
    });
  };

  renderButton = (btn) => {
    if (btn.type === 'popup') {
      return (
        <a key={btn.name} className={styles.link} onClick={() => this.openModal(btn)}>{btn.name}</a>
      );
    }
    if (btn.type === 'link') {
      if (btn.isExternal) {
        return (
          <a
            key={btn.name}
            href={btn.link}
            target={btn.link.includes('tel:') ? '_self' : '_blank'}
            className={styles.link}
          >
            {btn.name}
          </a>
        );
      } else if (btn.anchored) {
        return (
          <HashLink
            to={btn.link}
            className={styles.link}
          >
            {btn.name}
          </HashLink>
        );
      }
      return (
        <Link key={btn.name} className={cx(styles.link, 'link')} to={btn.link}>{btn.name}</Link>
      );
    }
  }

  render() {
    const { visible, selectedSlide } = this.state;
    // const sliderCount = Object.keys(heroSlider).length;
    // const randInitial = Math.floor(Math.random() * sliderCount);
    const isOneItem = Object.keys(heroSlider).length === 1;
    return (
      <div className={styles.sliderWrapper}>
        <div className='swiper-container' ref={(node) => { this.slider = node; }}>
          <div className='swiper-wrapper'>
            {heroSlider.map((item, index) => (
              <div
                key={index}
                className={cx('swiper-slide', styles.slide)}
              >
                <img
                  className={styles.img}
                  src={item.image}
                  alt={item.altText}
                />
                <div className={styles.container}>
                  <div className={styles.content}>
                    <h2 className={styles.title}>{item.title}</h2>
                    {item.buttons ?
                      <div className={styles.buttonsGroup}>
                        {item.buttons.map(button => (
                          this.renderButton(button)
                        ))}
                      </div> : this.renderButton(item.button)
                    }
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        {
          !isOneItem ?
            <Fragment>
              <div className={cx('swiper-button-prev', styles.prevArrow, styles.iconContainer, 'swiper-arrow')}>
                <SliderArrow className={styles.icon} />
              </div>
              <div className={cx('swiper-button-next', styles.nextArrow, styles.iconContainer, 'swiper-arrow')}>
                <SliderArrow className={styles.icon} />
              </div>
            </Fragment> : <div />
        }
        {visible ?
          <Modal
            visible={visible}
            closeModal={this.closeModal}
            wrapClassName={styles.modalWrapper}
            maskStyle={overlayStyles}
            width={1220}
            className={styles.heroModal}
          >
            <div className={styles.textWrapper}>
              {selectedSlide.description ?
                <div className={cx(styles.description)}>
                  <h2>{selectedSlide.description.title}</h2>
                  <div>{selectedSlide.description.text}</div>
                </div> : <div />
              }
            </div>
          </Modal> : <div />
        }
      </div>
    );
  }
}

export default withStyles(styles)(HeroSlider);
