import React from 'react';

const meta = {
  title: 'Recipes',
  description: 'Discover healthy and easy to make recipes to support your weight loss program.  ',
  // keywords: ["weight loss", "nutrition in bellevue"]
}

const page = {
  name: 'Healthy Recipes to Support Your Weight Loss',
  subTitle: 'Cooking at home can be easy, delicious, and help you avoid the hidden calories and ingredients you’ll find in restaurant meals. Consult your 20/20 LifeStyles dietitian with any questions about these recipes.'
};

export {
  page,
  meta
};
