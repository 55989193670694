exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ScheduleConsultationPage__breadcrumb--3xONw{margin:40px 50px 0px}@media (max-width: 550px){.ScheduleConsultationPage__breadcrumb--3xONw{margin:40px 20px 0px}}.ScheduleConsultationPage__pageTitle--1Vef2{padding:0 20px}\n", ""]);

// exports
exports.locals = {
	"breadcrumb": "ScheduleConsultationPage__breadcrumb--3xONw",
	"pageTitle": "ScheduleConsultationPage__pageTitle--1Vef2"
};