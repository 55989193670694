import { connect } from 'react-redux';
import {
  getRecipe,
  getRelatedRecipes
} from 'actions/recipes';
import { RecipeTemplate } from 'components';

const mapStateToProps = ({
  recipes: {
    data,
    recipesLoading
  }
}) => ({
  ...data,
  recipesLoading
});

const mapDispatchToProps = {
  getRecipe,
  getRelatedRecipes
};

export default connect(mapStateToProps, mapDispatchToProps)(RecipeTemplate);
