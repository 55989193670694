import React from 'react';
import { hydrate } from 'react-dom';
import './css/style.css';
import App from './App';

function render(Component) {
  hydrate(
    <Component />,
    document.getElementById('app')
  );
}

render(App);

if (module.hot) {
  module.hot.accept('./App', () => {
    const NextApp = require('./App').default; // eslint-disable-line
    render(NextApp);
  });
}
