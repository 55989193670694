import React from 'react';

const meta = {
  title: 'About Us',
  description:
    'A physician led team of weight loss experts in Bellevue and Seattle - doctors, registered dietitians, personal trainers, and licensed counselors'
};

const page = {
  name: '20/20 LifeStyles Medical Experts',
  subTitle:
    'At 20/20 LifeStyles, we’re here to help you make the rest of your life the best of your life.'
};

const sections = [
  {
    sectionExtraClassName: 'noTopPadding',
    textBlocks: [
      {
        align: 'left',
        text: (
          <div>
            <p>
              Founded in 1992 by Dr. Mark Dedomenico, a pioneer in
              cardiovascular surgery, 20/20 LifeStyles has helped thousands of
              people reclaim control of their health. After years of seeing
              people on the operating table, Dr. Dedomenico chose to devote his
              life and research to helping people before they needed surgery to
              correct metabolic disorders as well as help to reduce growing
              health care costs.
            </p>
            <p>
              Far from a short-term diet, 20/20 LifeStyles is a medically based
              program that gives you the tools, support, and education you need
              to live healthier now and forever. Not only do our clients lose
              weight safely and keep it off long-term, they’re able to reverse
              heart disease, reduce high blood pressure, avoid Type 2 diabetes,
              and free themselves of medication they’ve been dependent on for
              years.
            </p>
            <p>
              The 20/20 LifeStyles team of experts includes physicians,
              registered dietitians, certified exercise professionals, and
              licensed counselors who are specially trained in helping patients
              struggling with weight and metabolic issues. Each 20/20 Lifestyles
              staff member goes through a minimum of 160 hours of continuing
              education every year, ensuring that our clients benefit from the
              most current research and evidence-based science available. As a
              team, these highly skilled professionals provide the comprehensive
              care you need to make positive and lasting health changes.
            </p>
          </div>
        )
      },
      {
        align: 'left',
        title: 'REGISTERED DIETITIANS',
        text: (
          <div>
            <p>
              Our dietitians have a unique blend of empathy and expertise. As
              specialists in treating weight-related metabolic imbalances, they
              understand the unique challenges clients face in undoing unhealthy
              habits and support them in creating healthy ones.
            </p>
          </div>
        )
      },
      {
        align: 'left',
        title: 'CERTIFIED PERSONAL TRAINERS',
        text: (
          <div>
            <p>
              Most of our certified exercise professionals on the 20/20
              Lifestyles team have Bachelor of Science degrees and several have
              Master of Science degrees in exercise science/kinesiology. Plus,
              they are nationally certified by American College of Sports
              Medicine and/or the National Strength and Conditioning
              Association, the top two fitness accreditation organizations in
              the country.
            </p>
          </div>
        )
      },
      {
        align: 'left',
        title: 'LICENSED COUNSELORS',
        text: (
          <div>
            <p>
              All of the 20/20 Lifestyles counselors are Washington
              state-licensed, and have expertise in helping individuals with
              weight-related metabolic imbalances. All have Master’s degrees and
              several have Ph.D.’s.
            </p>
          </div>
        )
      },
      {
        align: 'left',
        title: 'PHYSICIANS',
        text: (
          <div>
            <p>
              Our physicians are all highly trained specialists who share a
              common dedication to providing the care, programs and support you
              need to stay healthy. Our licensed medical team is committed to
              staying current with the latest trends and research on metabolic
              imbalances.
            </p>
          </div>
        )
      }
    ]
  }
];

export { page, sections, meta };
