import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { Select } from 'antd';

import styles from './Genders.scss';

const Option = Select.Option;

const dropdownAlign = { offset: [-2, 0] };

class Genders extends PureComponent {
  static propTypes = {
    genders: PropTypes.object,
    selectedGender: PropTypes.string,
    storiesState: PropTypes.object.isRequired,
    setStoriesState: PropTypes.func.isRequired
  };

  onChange = (value) => {
    const { setStoriesState } = this.props;

    setStoriesState({
      gender: value === 'gender' ? null : value
    });
  };

  render() {
    const { genders } = this.props;
    const { gender } = this.props.storiesState;

    return (
      <div className={styles.selectWrapper}>
        <Select
          className='select'
          style={{ width: '100%' }}
          placeholder='Gender'
          dropdownAlign={dropdownAlign}
          dropdownClassName={styles.list}
          onChange={this.onChange}
          value={gender || 'gender'}
        >
          <Option value='gender' key='gender'>Gender</Option>
          {Object.keys(genders).map((keyName, i) => (
            <Option value={genders[keyName]} key={i}>{genders[keyName]}</Option>
          ))}
        </Select>
      </div>
    );
  }
}

export default withStyles(styles)(Genders);
