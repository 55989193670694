import React from 'react';
import cx from 'classnames';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { Select } from 'antd';
import { Input } from 'components';
import { PricingTable } from './components';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import styles from './BMICalculator.scss';

const Option = Select.Option;

const dropdownAlign = { offset: [-2, 0] };

const schema = Yup.object().shape({
    lbs: Yup.number().when("$exist", {
        is: (exist) => exist,
        then: Yup.number().required(""),
        otherwise: Yup.number(),
    }),
    feet: Yup.number().when("$exist", {
        is: (exist) => exist,
        then: Yup.number().required(""),
        otherwise: Yup.number(),
    }),
    inches: Yup.number().when("$exist", {
        is: (exist) => exist,
        then: Yup.number().required(""),
        otherwise: Yup.number(),
    }),
    kilo: Yup.number().when("$exist", {
        is: (exist) => exist,
        then: Yup.number().required(""),
        otherwise: Yup.number(),
    }),
    centimeters: Yup.number().when("$exist", {
        is: (exist) => exist,
        then: Yup.number().required(""),
        otherwise: Yup.number(),
    })
});
  
const initialValues = {
    lbs: '',
    kilo: '',
    centimeters: '',
    feet: '',
    inches: '',
};

const measurementOptions = [
    {value: 'imperial', text: 'Imperial'},
    {value: 'metric', text: 'Metric'}
];

class BMICalculator extends React.Component {
  state = {
    measurement: 'imperial',
    result: null,
    hasFormError: false
  };

  calculation = (weight, height) => {
    let bmi = null;

    if (weight && height) {
        if (this.state.measurement === 'metric') {
            height = height / 100;
            bmi = weight / (height * height);
        } else {
            bmi = 703 * (weight / (height * height));
        }
        bmi = Math.round(bmi * 100 / 100);
    }

    return bmi;
  }

  handleOptionChange = (value) => {
    this.setState({ measurement: value });
    this.setState({ hasFormError: false });
  };

  onSubmit = (values) => {
    const height = this.state.measurement === 'imperial' ? (parseInt(values.feet)* 12 + parseInt(values.inches)) : parseInt(values.centimeters);
    const weight = this.state.measurement === 'imperial' ? parseInt(values.lbs) : parseInt(values.kilo);
    const bmi = this.calculation(weight, height);
    
    if (bmi === null || parseInt(values.feet) <= 0 || parseInt(values.lbs) <= 0 || parseInt(values.inches) <= 0 || parseInt(values.centimeters) <= 0 || parseInt(values.kilo) <= 0 ) {
        this.setState({ hasFormError: true });
        this.setState({ result: null });
    } else {
        this.setState({ result: bmi });
        this.setState({ hasFormError: false });
    }
  };

  render() {
    return (
        <div className={styles.bmiCalculatorMain}>
            <div className={styles.bmiCalculatorWrapper}>
                <h2>BMI Calculator</h2>
                <div className={styles.measurement}>
                    <Select
                        className='select'
                        style={{ width: '100%' }}
                        placeholder='Imperial'
                        dropdownAlign={dropdownAlign}
                        dropdownClassName={styles.list}
                        onChange={this.handleOptionChange}
                        value={this.state.measurement}
                    >
                        {measurementOptions.map(option => (
                            <Option key={option.value} value={option.value}>{option.text}</Option>
                        ))}
                    </Select>
                </div>
                {this.state.hasFormError ? <div className={styles.fieldErrorMessage}>Please check your field inputs. They must be numbers larger than 0</div> : <div />}
                <div className={styles.formContent}>
                    <Formik
                        enableReinitialize
                        initialValues={initialValues}
                        validateOnChange={false}
                        validateOnBlur={false}
                        onSubmit={this.onSubmit}
                    >
                    {({
                        values,
                        errors,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        setFieldError,
                        dirty
                    }) => (
                        <Form onSubmit={handleSubmit} className={styles.form}>
                            <div className={styles.formGroup}>
                                <h3 className={styles.title}>Height</h3>
                                {this.state.measurement === 'imperial' ?
                                    <div className={styles.heightFieldsGroup}>
                                        <Input
                                            id='feet'
                                            type='text'
                                            name='feet'
                                            label='Feet'
                                            isRequiredAfterLabel
                                            value={values.feet}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            inputClassName={styles.input}
                                            className={cx(styles.inputWrapper, { [styles.formError]: this.state.hasFormError })}
                                            labelClassName={styles.label}
                                            error={errors.feet}
                                            setFieldError={setFieldError}
                                            isFormik
                                        />
                                        <Input
                                            id='inches'
                                            type='text'
                                            name='inches'
                                            label='Inches'
                                            isRequiredAfterLabel
                                            value={values.inches}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            inputClassName={styles.input}
                                            className={cx(styles.inputWrapper, { [styles.formError]: this.state.hasFormError })}
                                            labelClassName={styles.label}
                                            error={errors.inches}
                                            setFieldError={setFieldError}
                                            isFormik
                                        />
                                    </div> :
                                    <Input
                                        id='centimeters'
                                        type='text'
                                        name='centimeters'
                                        label='Centimeters'
                                        isRequiredAfterLabel
                                        value={values.centimeters}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        inputClassName={styles.input}
                                        className={cx(styles.inputWrapper, { [styles.formError]: this.state.hasFormError })}
                                        labelClassName={styles.label}
                                        error={errors.centimeters}
                                        setFieldError={setFieldError}
                                        isFormik
                                    />
                                }
                                <h3 className={styles.title}>Weight</h3>
                                {this.state.measurement === 'imperial' ?
                                    <Input
                                        id='lbs'
                                        type='text'
                                        name='lbs'
                                        label='Lbs'
                                        isRequiredAfterLabel
                                        value={values.lbs}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        inputClassName={styles.input}
                                        className={cx(styles.inputWrapper, { [styles.formError]: this.state.hasFormError })}
                                        labelClassName={styles.label}
                                        error={errors.lbs}
                                        setFieldError={setFieldError}
                                        isFormik
                                    /> :
                                    <Input
                                        id='kilo'
                                        type='text'
                                        name='kilo'
                                        label='Kg'
                                        isRequiredAfterLabel
                                        value={values.kilo}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        inputClassName={styles.input}
                                        className={cx(styles.inputWrapper, { [styles.formError]: this.state.hasFormError })}
                                        labelClassName={styles.label}
                                        error={errors.kilo}
                                        setFieldError={setFieldError}
                                        isFormik
                                    />

                                }
                            </div>
                            <button
                                type='submit'
                                className={styles.Button}
                            >
                                Calculate
                            </button>
                        </Form>
                    )}
                    </Formik>
                </div>
                <div className={styles.result}>
                    <div className={styles.yourBMI}>Your BMI:</div>
                    <div className={styles.yourResult}>{this.state.result}</div>
                </div>
            </div>
            {this.state.result !== null ? <div className={styles.arrowWrapper} /> : <div />}
            <div className={styles.pricingTableWrapper}>
                <PricingTable bmi={this.state.result} />
            </div>
        </div>
    );
  }
}

export default withStyles(styles)(BMICalculator);
