import { connect } from 'react-redux';
import {
  getExpertsFilters,
  setExpertsState,
  getAllExperts,
  getInitialExperts
} from 'actions/experts';
import { ExpertsPage } from 'components';

const mapStateToProps = ({
  experts: {
    data,
    expertsState,
    expertsLazyLoading,
    expertsLoading
  }
}) => ({
  ...data,
  expertsState,
  expertsLazyLoading,
  expertsLoading
});

const mapDispatchToProps = {
  setExpertsState,
  getAllExperts,
  getInitialExperts,
  getExpertsFilters
};

export default connect(mapStateToProps, mapDispatchToProps)(ExpertsPage);
