const filterPostCategories = (categories) => {
  const filtered = categories.filter(dataRow =>
    dataRow[1] !== '2020-lifestyles-insights'
    && dataRow[1] !== 'hidden-2020-posts'
    && dataRow[1] !== 'imported-2020-post'
    && dataRow[1] !== 'stages');
  filtered.shift();
  return filtered;
};

export default filterPostCategories;
