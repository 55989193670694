exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ListView__recipesListWrapper--1nA2Q{margin-bottom:70px}.ListView__recipesListWrapper--1nA2Q .ListView__recipesList--2vmC5{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-flex-wrap:wrap;-moz-flex-wrap:wrap;-ms-flex-wrap:wrap;flex-wrap:wrap;max-width:1000px;margin:0 auto}@media (max-width: 1115px){.ListView__recipesListWrapper--1nA2Q .ListView__recipesList--2vmC5{max-width:800px}}.ListView__recipesListWrapper--1nA2Q .ListView__recipesList--2vmC5 .ListView__recipe--3MHRx{width:500px;padding:0 10px}@media (max-width: 1115px){.ListView__recipesListWrapper--1nA2Q .ListView__recipesList--2vmC5 .ListView__recipe--3MHRx{width:400px}}@media (max-width: 930px){.ListView__recipesListWrapper--1nA2Q .ListView__recipesList--2vmC5 .ListView__recipe--3MHRx{width:100%}}.ListView__overlayLoader--3qkww{min-height:100px;display:-webkit-box;display:-ms-flexbox;display:flex;-moz-justify-content:center;-ms-justify-content:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-align-items:center;-moz-align-items:center;-ms-align-items:center;align-items:center;background-color:rgba(255,255,255,0.7);position:absolute;top:0;left:0;right:0;bottom:0;z-index:10}@media (max-width: 767px){.ListView__overlayLoader--3qkww{position:fixed;z-index:100}}.ListView__loader--2aSba{min-height:100px;display:-webkit-box;display:-ms-flexbox;display:flex;-moz-justify-content:center;-ms-justify-content:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-align-items:center;-moz-align-items:center;-ms-align-items:center;align-items:center}.ListView__loaderIcon--AVqSN{height:100px}\n", ""]);

// exports
exports.locals = {
	"recipesListWrapper": "ListView__recipesListWrapper--1nA2Q",
	"recipesList": "ListView__recipesList--2vmC5",
	"recipe": "ListView__recipe--3MHRx",
	"overlayLoader": "ListView__overlayLoader--3qkww",
	"loader": "ListView__loader--2aSba",
	"loaderIcon": "ListView__loaderIcon--AVqSN"
};