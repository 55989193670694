import React, { Fragment } from 'react';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { routes } from 'routes';
import styles from './CTAButton.scss';

const CTAButtons = ({
  mobileMenuOff
}) => (
  <Fragment>
    <div className={cx(styles.ctaButtons)}>
      <NavLink
        className={cx(styles.appointmentButton, styles.ctaButton)}
        to={routes.SCHEDULE_A_CONSULTATION}
        onClick={mobileMenuOff}
      >
        Schedule a Free <br />Consultation
      </NavLink>
      <NavLink
        className={cx(styles.appointmentButton, styles.ctaButton)}
        to={routes.REFER_A_FRIEND}
        onClick={mobileMenuOff}
      >
        Refer<br />a Friend
      </NavLink>
      <NavLink
        className={cx(styles.seminarButton, styles.ctaButton)}
        to={{pathname: "tel:+1-425-861-6258"}}
        target="_blank"
        onClick={mobileMenuOff}
      >
        Call<br />(425) 861-6258
      </NavLink>
    </div>
  </Fragment>
);

CTAButtons.propTypes = {
  mobileMenuOff: PropTypes.func
};
export default withStyles(styles)(CTAButtons);
