import React from 'react';
import IconBase from 'react-icon-base';

export default function LeftArrow(props) {
  return (
    <IconBase viewBox='0 0 12 12' {...props} style={{ transform: 'rotate(180deg)' }}>
      <defs>
        <path id='s7gua' d='M775 801l-12 6v-12z' />
      </defs>
      <use xlinkHref='#s7gua' transform='translate(-763 -795)' />
    </IconBase>
  );
}
