import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { routes } from 'routes';
import logo from 'assets/images/2020logo.png';
import {
  Icon,
  Button,
  Navigation,
  BrowserRequirements
} from 'components';
import { Link } from 'react-router-dom';
import MediaQuery from 'react-responsive';
import {
  CTAButtons
  // SearchButton
  // Search
} from './components';
import { navigation } from '../../mocks/navigation';
import { socials } from '../Footer/links';
import styles from './Header.scss';

class Header extends PureComponent {
  state = {
    isMenuOpen: false,
    searchOpened: false,
    browserIsOpen: false
  };

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  bodyScrollLock = () => {
    if (this.state.isMenuOpen || this.state.searchOpened) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
  };

  mobileMenuToggle = () => {
    this.setState(
      prevState => ({ isMenuOpen: !prevState.isMenuOpen }),
      this.bodyScrollLock
    );
  };

  mobileMenuOff = () => {
    this.setState({ isMenuOpen: false });
  };

  // searchModalHandler = () => {
  //   this.setState(
  //     prevState => ({ searchOpened: !prevState.searchOpened }),
  //     this.bodyScrollLock
  //   );
  // };

  handleResize = () => {
    if (window.innerWidth > 1024) {
      this.setState({ isMenuOpen: false });
    }
  };

  openModal = () => {
    this.setState({ browserIsOpen: true });
  };

  closeModal = () => {
    this.setState({ browserIsOpen: false });
  };

  render() {
    const {
      location: { pathname }
    } = this.props;
    const {
      isMenuOpen,
      // searchOpened,
      browserIsOpen
    } = this.state;

    return (
      <header
        className={cx(
          styles.header,
          { [styles.menuOpen]: isMenuOpen }
        )}
      >
        <div className={cx(styles.headerTop)}>
          <Link className={cx(styles.logo, styles.mainLogo)} to={routes.HOME}>
            <img src={logo} />
          </Link>
          <div className={cx(styles.headerTopRightItems)}>
            <MediaQuery minWidth={1025}>
              <CTAButtons />
            </MediaQuery>
            <MediaQuery minWidth={601}>
              <div className={cx(styles.SignInLinks)}>
                <div className={cx(styles.linksGroup)}>
                  <a className={cx(styles.link)} href='https://account.2020lifestyles.com/login'>Sign In</a>
                  <span> | </span>
                  <a className={cx(styles.link)} href='http://account.2020lifestyles.com/register'>Register</a>
                </div>
              </div>
            </MediaQuery>
            {/* <SearchButton searchModalHandler={this.searchModalHandler} /> */}
            <Button className={styles.burgerMenu} onClick={this.mobileMenuToggle} >
              {isMenuOpen ? (
                <Icon type='BurgerMenuClose' />
              ) : (
                <Icon type='BurgerMenu' />
              )}
            </Button>
          </div>
          <div
            className={cx(styles.mobileToggleContainer, {
              [styles.showBar]: isMenuOpen
            })}
            onClick={this.mobileMenuToggle}
          />
        </div>
        <div className={styles.headerBottom}>
          <div
            className={cx(styles.navigation, { [styles.isOpen]: isMenuOpen })}
          >
            <div className={styles.navigationWrapper}>
              <div className={styles.notMobile}>
                <Navigation
                  links={navigation}
                  navigationClassName={cx(styles.mainnavigationNav)}
                  wrapClassName={styles.navigationDesktopnWrap}
                  pathname={pathname}
                />
              </div>
              <div className={styles.mobileSidebar}>
                <div className={cx(styles.SignInLinks)}>
                  <div className={cx(styles.linksGroup)}>
                    <a className={cx(styles.link)} href='https://account.2020lifestyles.com/login'>Sign In</a>
                    <span> | </span>
                    <a className={cx(styles.link)} href='http://account.2020lifestyles.com/register'>Register</a>
                  </div>
                </div>
                <CTAButtons mobileMenuOff={this.mobileMenuToggle} />
                <Navigation
                  isMobile
                  navigationClassName={styles.mainnavigationNav}
                  links={navigation}
                  pathname={pathname}
                  mobileMenuOff={this.mobileMenuToggle}
                  openModal={this.openModal}
                />
                <div className={styles.socials}>
                  {socials.map((item, index) => (
                    <a key={index} href={item.path} target='_blank'>
                      <Icon className={styles.socialIcon} type={item.icon} />
                    </a>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* searchOpened ? (
          <div className={styles.search}>
            <div className={styles.overlay} />
            <Search
              searchModalHandler={this.searchModalHandler}
            />
          </div>
        ) : (
          <div />
        ) */}
        <BrowserRequirements
          visible={browserIsOpen}
          closeModal={this.closeModal}
        />
      </header>
    );
  }
}

Header.propTypes = {
  pathname: PropTypes.string,
  location: PropTypes.object
};

export default withStyles(styles)(Header);
