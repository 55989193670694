import React from 'react';
import { routes } from 'routes';

const meta = {
  title: 'Weight Loss Meal Plans',
  description:
    'Staged meal plans created by registered dietitians promote healthy weight loss, reduce cravings, and teach sustainable habits',
  keywords: ['weight loss', 'nutrition in bellevue']
};

const page = {
  name: 'Weight Loss Meal Plans',
  subTitle:
    'Achieve your weight loss goals with 20/20 Lifestyles and a balanced, smarter diet.'
};

const sections = [
  {
    sectionExtraClassName: 'noPadding',
    // textBeforeTitle: 'another test',
    noMobilePadding: true,
    textBlocks: [
      {
        align: 'left',
        title: 'CUSTOM HEALTHY LIFESTYLE MEAL PLAN',
        text: (
          <div>
            <p>
              Eating healthy doesn’t have to be complicated. No one ever said it
              would be easy, but a customized meal plan for weight loss that’s
              catered to your nutritional needs can definitely help you meet
              your health goals and achieve the weight loss results you desire.
              When you register for our nationally-recognized, medically-based
              weight loss program, we’ll guide you every step of the way to help
              you lose weight and keep it off.
            </p>
            <p>
              Some of our all-inclusive customized meal plans are designed for a
              variety of health conditions including:
            </p>
          </div>
        )
      }
    ]
  }
];

const sections2 = [
  {
    sectionExtraClassName: 'noTopPadding',
    // textBeforeTitle: 'another test',
    noMobilePadding: true,
    textBlocks: [
      {
        align: 'left',
        text: (
          <div>
            <p>
              As you progress through the program, the Registered Dietitians in
              Bellevue, WA, at 20/20 LifeStyles will meet with you weekly and
              adjust your multi-stage meal plan to ensure you continue losing
              weight while feeling satiated and ensuring you get proper daily
              nutrition.
            </p>
            <p>
              View our <a href={routes.SUCCESS_STORIES}>success stories</a> to
              see how thousands of people like you have transformed their lives
              with our healthy lifestyle meal plans.
            </p>
            <p>
              For more information regarding our personalized meal plans for
              weight loss and how they can benefit you, please{' '}
              <a href={routes.CONTACT_US}>contact us</a>. We look forward to
              helping you on your journey to a healthy lifestyle!
            </p>
          </div>
        )
      }
    ]
  }
];

const accordions = [
  {
    title: 'LOW CARB DIET',
    text: (
      <div>
        <p>
          When you have a health condition like diabetes, it’s important to have
          a meal plan that stabilizes your blood sugar (glucose), manages your
          weight, and controls potential risks. There isn’t a single diet plan
          that works for everyone because our bodies react differently to
          various food regimens.
        </p>
        <p>
          As a person with diabetes, it may be difficult to fully understand
          what your body needs. A personalized diet plan with 20/20 LifeStyles’
          team of professional registered dietitians will ensure that you’re
          eating the appropriate amount and type of food on a regular basis. A
          customized low carb diet plan will help you maintain proper eating
          habits, and it will allow you to live a healthier and happier
          lifestyle.
        </p>
        <p>
          Diabetes may be an obstacle, but it doesn’t have to stop you from
          making your life a great one. For an individualized low carb diet plan
          that works, register with 20/20 LifeStyles today.
        </p>
      </div>
    )
  },
  {
    title: 'HEART HEALTHY DIET',
    text: (
      <div>
        <p>
          Also known as the “cardiac diet,” the heart healthy diet plan is
          designed to give your body the rich nutrients it needs while staying
          within your daily calorie goals for weight loss. Whether you have a
          cardiovascular health concern or simply want to follow a healthier
          diet, sticking to smarter food choices is important to prevent the
          risk of heart disease in the future. Not only are you fueling your
          body with what it needs to stay healthy, you’re improving your overall
          health.
        </p>
        <p>
          We know following a diet can be tough, but we’re here to help. One of
          our Registered Dietitians will work with you one-on-one to discuss
          your current eating habits and develop a heart healthy diet plan that
          will maximize weight loss and ensure you’re eating the right foods on
          a regular basis.
        </p>
      </div>
    )
  },
  {
    title: 'CHOLESTEROL DIET',
    text: (
      <div>
        <p>
          For individuals with high-cholesterol, a personalized diet plan to
          lower LDL, or “bad” cholesterol, is a necessity. Too much LDL in the
          bloodstream can put you at risk for a variety of heart diseases,
          including coronary artery disease. 20/20 Lifestyles can help you put
          together a custom high-cholesterol eating plan to keep LDLs to a
          minimum, preventing them from sticking to artery walls and,
          potentially, blocking your arteries completely.
        </p>
        <p>
          At 20/20 LifeStyles, our Registered Dietitians are ultimately
          committed to your success. They will design a multi-stage healthy
          lifestyle meal plan that ensures you lose weight while feeling
          satiated and ensuring you get proper daily nutrition.
        </p>
      </div>
    )
  }
];

export { page, sections, accordions, sections2, meta };
