import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Swiper from 'swiper/dist/js/swiper';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';
import ReactPlayer from 'react-player';
import BrightcovePlayer from '@brightcove/react-player-loader';
import { SliderArrow } from 'components';
import styles from './VideosSlider.scss';

const options = {
  slidesPerView: 1,
  simulateTouch: false,
  navigation: {
    nextEl: '.video-swiper-button-next',
    prevEl: '.video-swiper-button-prev'
  }
};

class VideosSlider extends Component {
  state = {
    activeIndex: 0,
    videoLink: null,
    videoType: null
  };

  componentDidMount() {
    this.swiperInit();
  }

  componentWillUnmount() {
    this.swiper.destroy();
  }

  setActiveIndex = () => {
    this.setState({
      activeIndex: this.swiper.activeIndex
    });
  };

  videos = [];

  insertVideo = () => {
    const { videos } = this.props;
    const type = videos[this.swiper.realIndex].type;
    const link = videos[this.swiper.realIndex].link;
    this.setState({ videoLink: link, videoType: type });
  }

  swiperInit = () => {
    this.swiper = new Swiper(this.slider, {
      ...options
    });
    this.setActiveIndex();
    this.swiper.on('slideChange', this.setActiveIndex);
    const { videos } = this.props;
    const type = videos[this.swiper.realIndex].type;
    const link = videos[this.swiper.realIndex].link;
    this.setState({ videoLink: link, videoType: type });
  };

  render() {
    const { videos } = this.props;
    const isOneItem = Object.keys(videos).length === 1;
    return (
      <div className={styles.sliderWrapper}>
        <div className='swiper-container' ref={(node) => { this.slider = node; }}>
          <div className='swiper-wrapper'>
            {videos.map((item, index) => (
              <div key={index} className={cx('swiper-slide', styles.slide)}>
                <div className={styles.container}>
                  <div className={styles.content}>
                    <div className={styles.videoFrameWrp} alt={item.alt}>
                      {this.state.videoLink && this.state.videoType === 'youtube' ?
                        <ReactPlayer
                          url={this.state.videoLink}
                          className={cx(styles.sliderVideo, `video${index}`)}
                          controls
                          ref={(video) => { this.videos[index] = video; }}
                          index={index}
                        /> : null
                      }
                      {this.state.videoLink && this.state.videoType === 'brightcove' ?
                        <div className={cx(styles.sliderVideo, `video${index}`, styles.brightcovePlayer)}>
                          <BrightcovePlayer
                            accountId='1257553580001'
                            videoId={this.state.videoLink}
                            ref={(video) => { this.videos[index] = video; }}
                            index={index}
                          />
                        </div>: null
                      }
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        {
          !isOneItem ?
            <Fragment>
              <div
                className={cx('video-swiper-button-prev', styles.prevArrow, styles.iconContainer, 'swiper-arrow')}
                onClick={this.insertVideo}
              >
                <SliderArrow className={styles.icon} />
              </div>
              <div
                className={cx('video-swiper-button-next', styles.nextArrow, styles.iconContainer, 'swiper-arrow')}
                onClick={this.insertVideo}
              >
                <SliderArrow className={styles.icon} />
              </div>
            </Fragment> : <div />
        }
      </div>
    );
  }
}

VideosSlider.propTypes = {
  videos: PropTypes.arrayOf(PropTypes.object)
};

export default withStyles(styles)(VideosSlider);
