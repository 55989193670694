import React from 'react';

const meta = {
  title: 'Refer A Friend',
}

const page = {
  name: 'Refer A Friend'
};

const sections = [
  {
    sectionExtraClassName: 'noTopPadding',
    textBlocks: [
      {
        align: 'left',
        text: (
          <div>
            <p>
                Do you have friends or family that may be interested in 
                improving their health, losing weight, overcoming a metabolic 
                disorder, or increasing their quality of life? As you know, 20/20 LifeStyles can help.
            </p>
            <p>
                If so, we’d be happy to reach out to your friend or family member to make sure 
                they get the information they need to start living their best life.
            </p>
            <p>
                Call or complete our form to refer a friend or family member and our staff 
                will contact them to give them the information to start living their best life. 
                If they enroll in the program, we'll send you a $500 PRO Club gift card.
            </p>
          </div>
        ),
      }
    ]
  },
];

export {
  page,
  sections,
  meta
};
