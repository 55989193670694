import React from 'react';
import PropTypes from 'prop-types';
import { Modal as AntModal } from 'antd';
import { Icon } from 'components';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import styles from './Modal.scss';

const Modal = ({
  children,
  isOpen,
  closeModal,
  ...props
}) => (
  <AntModal
    visible={isOpen}
    onCancel={closeModal}
    style={{
      borderRadius: '20px',
      overflow: 'hidden',
      paddingBottom: 0
    }}
    bodyStyle={{
      height: 'auto',
      padding: '5px',
      backgroundColor: '#fff'
    }}
    footer={null}
    {...props}
  >
    <div className={styles.modal}>
      <button onClick={closeModal} className={cx(styles.close, 'modal-close-btn')}>
        <div className={styles.mark}><Icon type='BurgerMenuClose' /></div>
      </button>
      {children}
    </div>
  </AntModal>
);

Modal.propTypes = {
  children: PropTypes.any,
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func
};

export default withStyles(styles)(Modal);
