exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".WhyItWorks__breadcrumb--1QVyJ{margin:40px 50px 0px}@media (max-width: 550px){.WhyItWorks__breadcrumb--1QVyJ{margin:40px 20px 0px}}.WhyItWorks__pageTitle--2i6Om{padding:0 20px}\n", ""]);

// exports
exports.locals = {
	"breadcrumb": "WhyItWorks__breadcrumb--1QVyJ",
	"pageTitle": "WhyItWorks__pageTitle--2i6Om"
};