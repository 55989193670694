import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import maintenanceImage from '../../assets/images/maintenance-background.jpg';
import css from './MaintenancePage.scss';

const MaintenancePage = () => (
  <div className={css.maintenanceWrapper} style={{ backgroundImage: `url(${maintenanceImage})` }}>
    <div className={css.maintenanceText}>
      <h1>WE'LL BE BACK SOON</h1>
      <p>WE'RE PERFORMING MAINTENANCE AND EXPECT TO BE BACK IN A FEW HOURS</p>
      <p>IN THE MEANTIME, CHECK OUT OUR HEALTHY RECIPES, MEAL TRACK OR GET SOME STEPS IN!</p>
      <p>THANK YOU FOR YOUR PATIENCE.</p>
    </div>
  </div>
);

export default withStyles(css)(MaintenancePage);
