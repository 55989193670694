import { createTypes } from 'redux-compose-reducer';
import { get } from 'utils/wordPressApiRequester';
import { clearBlankParamsFromObject } from 'utils/clearBlankParamsFromObject';

export const TYPES = createTypes('posts', [
  'getPostsFilters',
  'getInitialPosts',
  'getAllPosts',
  'setPostsState',
  'switchPostsLazyLoading',
  'switchPostsLoading',
  'getPostsForWidget'
]);

export const getPostsFilters = () => async (dispatch) => {
  const route = 'powerapi/v1/siteacf';
  try {
    const { data } = await get(route);
    await dispatch({ type: TYPES.getPostsFilters, postData: data });
  } catch (e) {
    console.error(e);
  }
};

export const getPostsForWidget = () => async (dispatch) => {
  const route = 'wp/v2/2020blog?per_page=12&blog_widget=true';
  try {
    const { data } = await get(route);
    await dispatch({ type: TYPES.getPostsForWidget, postData: data });
  } catch (e) {
    console.error(e);
  }
};

export const getInitialPosts = () => async (dispatch) => {
  try {
    dispatch({ type: TYPES.switchPostsLoading, postData: true });
    await get('wp/v2/2020blog?per_page=10&page=1').then((res) => {
      dispatch({ type: TYPES.getAllPosts, postData: res.data, page: 1 });
    });
    dispatch({ type: TYPES.switchPostsLoading, postData: false });
  } catch (e) {
    console.error(e);
  }
};

export const getAllPosts = ({
  page,
  isLoader
}) => (dispatch) => {
  const loaderType = isLoader ? TYPES.switchPostsLazyLoading : TYPES.switchPostsLoading;
  const params = {
    per_page: 10,
    page
  };
  const finalParams = clearBlankParamsFromObject(params);
  const queryString = Object.keys(finalParams).map(key => (`${key}=${finalParams[key]}`)).join('&');
  return Promise.resolve(dispatch({ type: loaderType, postData: true }))
    .then(() => get(`wp/v2/2020blog?${queryString}`))
    .then((res) => {
      dispatch({ type: TYPES.getAllPosts, postData: res.data, page });
    })
    .then(() => {
      dispatch({ type: TYPES.switchPostsLoading, postData: false });
      dispatch({ type: TYPES.switchPostsLazyLoading, postData: false });
    })
    .catch((e) => {
      dispatch({ type: TYPES.switchPostsLoading, postData: false });
      dispatch({ type: TYPES.switchPostsLazyLoading, postData: false });
      // add error handler
      console.error(e);
      throw e;
    });
};

export const setPostsState = (postData, isLoader) => async (dispatch, getState) => {
  await dispatch({ type: TYPES.setPostsState, postData });
  const { postsState } = getState().posts;
  const { page } = postsState;
  const pageNumber = isLoader ? page : 1;
  await dispatch(getAllPosts({ ...postsState, page: pageNumber, isLoader }));
};
