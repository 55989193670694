import { connect } from 'react-redux';
import { getSeminars } from 'actions/seminars';
import { AttendASeminarPage } from 'components';

const mapStateToProps = ({
  seminars: {
    data
  }
}) => ({
  ...data
});

const mapDispatchToProps = {
  getSeminars
};

export default connect(mapStateToProps, mapDispatchToProps)(AttendASeminarPage);
