import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import styles from './PricingTable.scss';
import { pricings } from '../../../../mocks/pages/PricingPage';

const PricingTable = (props) => {
  return (
    <div className={styles.PricingTable}>
        {props.bmi !== null && props.bmi < 27 ?
          <div className={styles.lowBMIMsg}>
              While we may not have a 20/20 LifeStyles program recommendation at this time 
              based on the information you entered, there may be other health related goals 
              we can support you with. We’d be happy to introduce you to our other programs 
              and services to help you with better habits, nutrition, exercise, health challenges, 
              and/or losing twenty pounds or less. Please contact us for further information at{' '}
              <a href='mailto:2020lifestyles@proclub.com'>2020lifestyles@proclub.com</a>. 
              Our experts are here to help you be a healthier you.
          </div> 
          : <div />
        }
      <table>
        {pricings.map((item) => (
          <Fragment key={item.name}>
            <thead className={styles.phaseName}><tr><th colSpan="5">{item.name}</th></tr></thead>
            <thead className={styles.hideOnMobile}>
              <tr>
                {item.prices[0].tableHead.Plan ? <th scope='col' className={styles.hidden}>Plan</th> : <th scope='col' className={styles.hideOnMobile}></th>} 
                {item.prices[0].tableHead.BMI ? <th scope='col'>BMI</th> : <th scope='col' className={styles.hideOnMobile}></th>}
                <th scope='col'>Member</th>
                <th scope='col'>Non-member</th>
                <th scope='col'>Duration</th>
              </tr>
            </thead>
            <tbody>
              {item.prices.map((plan) => (
                <tr 
                  key={plan.memberPricing} 
                  className={(plan.endBMI && props.bmi >= plan.startBMI && props.bmi <= plan.endBMI) || (!plan.endBMI && props.bmi >= plan.startBMI) ? styles.activeRow : ''}
                >
                  {plan.tableHead.Plan ? 
                    <td className={styles.planCell} data-label={plan.tableHead.Plan}>{plan.plan}</td> : 
                    <td className={styles.hideOnMobile}></td>
                  }
                  {plan.tableHead.BMI ? 
                    <td data-label={plan.tableHead.BMI}>
                      {plan.endBMI ? `${plan.startBMI} - ${plan.endBMI}` : `${plan.startBMI}+`}
                    </td> : 
                    <td className={styles.hideOnMobile}></td>
                  }
                  <td data-label={plan.tableHead.Member}>{plan.memberPricing}</td>
                  <td data-label={plan.tableHead.NonMember}>{plan.nonMemberPricing}</td>
                  <td data-label={plan.tableHead.Duration}>{plan.duration}</td>
                </tr>
              ))}
            </tbody>
          </Fragment>
        ))}  
      </table>
    </div>
  );
};

PricingTable.propTypes = {
  bmi: PropTypes.number
};

export default withStyles(styles)(PricingTable);
