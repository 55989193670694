export const clearParamsFromObjectByKeys = (obj, clearKeys) => {
  const updatedParams = {};
  // eslint-disable-next-line
  for (const item in obj) {
    if (
      !clearKeys.includes(item)
    ) {
      updatedParams[item] = obj[item];
    }
  }
  return updatedParams;
};

export default clearParamsFromObjectByKeys;
