import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import parse from 'html-react-parser';
import cx from 'classnames';
import moment from 'moment';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { phoneMask, phoneSchema } from 'utils/validationSchemas';
import { Modal, Input } from 'components';
import style from './AttendASeminarForm.scss';

const overlayStyles = {
  backgroundColor: 'rgba(0, 0, 0, 0.75)'
};

const schema = Yup.object().shape({
  first_name: Yup.string().required('Required'),
  last_name: Yup.string().required('Required'),
  phone: phoneSchema.required('Required'),
  email: Yup.string().email('Invalid email address').required('Required'),
  seminarDate: Yup.string(),
  seminarTime: Yup.string()
});

const initialValues = {
  first_name: '',
  last_name: '',
  phone: '',
  email: '',
  seminarDate: '',
  seminarTime: ''
};

class AttendASeminarForm extends PureComponent {
  static propTypes = {
    seminar: PropTypes.object.isRequired
  };

  state = {
    preferredDay: moment(),
    submissionPopupIsOpen: false,
    submissionMessage: ''
  };

  onSubmit = (values, { resetForm }) => {
    values.seminarDate = parse(this.props.seminar.ACF.date);
    values.seminarTime = parse(this.props.seminar.ACF.time);

    axios({
      method: 'POST',
      url: '/send-seminar',
      data: values
    }).then((response) => {
      if (response.data === 'Success') {
        resetForm();
        this.setState({ submissionMessage: 'success' });
        this.openSubmissionPopup();
      } else if (response.data === 'Fail') {
        this.setState({ submissionMessage: 'fail' });
        this.openSubmissionPopup();
      }
    });
  };

  openSubmissionPopup = () => {
    this.setState({ submissionPopupIsOpen: true });
  };

  closeSubmissionPopup = () => {
    this.setState({ submissionPopupIsOpen: false });
  };

  render() {
    const { submissionPopupIsOpen } = this.state;
    const { seminar } = this.props;
    return (
      <Fragment>
        <div
          id='attend-a-seminar-form'
          className={style.AttendASeminarFormMain}
        >
          <div className={style.AttendASeminarFormContent}>
            <Formik
              enableReinitialize
              initialValues={initialValues}
              validationSchema={schema}
              validateOnChange={false}
              validateOnBlur={false}
              onSubmit={this.onSubmit}
            >
              {({
                values,
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldError
              }) => (
                <Form onSubmit={handleSubmit} className={style.form}>
                  <div className={style.fieldsGroup}>
                    <span className={style.seminarDateTimeLabel}>Seminar Date & Time</span>
                    <div className={style.seminarDateTime}>{parse(seminar.ACF.date)}<span>-</span>{parse(seminar.ACF.time)}</div>
                  </div>
                  <div className={style.fieldsGroup}>
                    <Input
                      id='first_name'
                      type='text'
                      name='first_name'
                      label='first name'
                      value={values.first_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputClassName={style.input}
                      className={style.inputWrapper}
                      labelClassName={style.label}
                      error={errors.first_name}
                      setFieldError={setFieldError}
                      isFormik
                    />
                    <Input
                      id='last_name'
                      type='text'
                      name='last_name'
                      label='last name'
                      value={values.last_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputClassName={style.input}
                      className={style.inputWrapper}
                      labelClassName={style.label}
                      error={errors.last_name}
                      setFieldError={setFieldError}
                      isFormik
                    />
                    <Input
                      id='phone'
                      type='text'
                      name='phone'
                      label='phone number'
                      value={values.phone}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputClassName={cx('ant-input', style.input)}
                      className={style.inputWrapper}
                      labelClassName={style.label}
                      error={errors.phone}
                      setFieldError={setFieldError}
                      isFormik
                      isMask
                      mask={phoneMask}
                    />
                    <Input
                      id='email'
                      type='email'
                      name='email'
                      label='email'
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputClassName={style.input}
                      className={style.inputWrapper}
                      labelClassName={style.label}
                      error={errors.email}
                      setFieldError={setFieldError}
                      isFormik
                    />
                  </div>
                  <button
                    type='submit'
                    className={style.Button}
                  >
                    Submit
                  </button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
        <Modal
          visible={submissionPopupIsOpen}
          closeModal={this.closeSubmissionPopup}
          wrapClassName={style.modalWrapper}
          maskStyle={overlayStyles}
          width={707}
        >
          <div className={style.textWrapper}>
            {this.state.submissionMessage === 'success' ?
              <div>
                <h2>THANK YOU</h2>
                <p>Thanks for taking the first step in your healthy journey. 
                  You’re in good hands! We’re excited to help you reach your goals.</p>
                <p>One of our Program Coordinators will contact you soon. 
                  We look forward to meeting you, answering all your questions 
                  about 20/20 LifeStyles, and introducing you to your team of 
                  experts dedicated to your success.</p>
              </div> : <div />
            }
            {this.state.submissionMessage === 'fail' ?
              <div>
                <h2>SORRY</h2>
                <p>Your request was not successfully submitted. Please try again.</p>
                <p>If you continue to experience errors, please contact our program directly
                  at <a href='mailto:support@2020lifestyles.com'>support@2020lifestyles.com</a> or call us at 425.861.6258
                </p>
              </div> : <div />
            }
          </div>
        </Modal>
      </Fragment>
    );
  }
}

export default withStyles(style)(AttendASeminarForm);
