import AttendASeminarPage from "./AttendASeminarPage/AttendASeminarPage";
import PricingPage from "./PricingPage/PricingPage";

export HomePage from './HomePage';
export Navigation from './Navigation';
export Header from './Header';
export Icon from './Icon';
export Button from './Button';
export Loader from './Loader';
export Footer from './Footer';
export Modal from './Modal';
export BrowserRequirements from './BrowserRequirements';
export SliderArrow from './SliderArrow';
export SuccessStoryCard from './SuccessStoryCard';
export SuccessStoriesPage from './SuccessStoriesPage';
export Breadcrumbs from './Breadcrumbs';
export PageTitle from './PageTitle';
export ExpertsPage from './ExpertsPage';
export LandingButton from './LandingButton';
export Sections from './Sections';
export VideosSlider from './VideosSlider';
export ResourcesToolsPage from './ResourcesToolsPage';
export BlogPage from './BlogPage';
export RecipesPage from './RecipesPage';
export RecipeTemplate from './RecipeTemplate';
export FaqPage from './FaqPage';
export TrackerPage from './TrackerPage';
export WorkoutsPage from './WorkoutsPage';
export RewardsPage from './RewardsPage';
export ProgramPage from './ProgramPage';
export WhyItWorks from './WhyItWorks';
export CorporateSolutions from './CorporateSolutions';
export Calendar from './Calendar';
export ScheduleConsultationPage from './ScheduleConsultationPage';
// export Form from './Form';
export MetaData from './MetaData';
export Select from './Select';
export Input from './Input';
export MaintenancePage from './MaintenancePage';
export MealPlansPage from './MealPlansPage';
export Genetics from './Genetics';
export HealthMonitoringPage from './HealthMonitoringPage';
export ReferAFriendPage from './ReferAFriendPage';
export PricingPage from './PricingPage';
export AttendASeminarPage from './AttendASeminarPage';
export BMICalculator from './BMICalculator';