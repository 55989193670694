import React from 'react';
import IconBase from 'react-icon-base';

export default function SlideControl(props) {
  return (
    <IconBase viewBox='0 0 16 10' {...props}>
      <g>
        <path
          className='fil0'
          d='M0.013 0.076c-0.017,-0.017 -0.017,-0.045 0,-0.063 0.018,-0.017
          0.046,-0.017 0.063,0l0.212 0.211c0.017,0.018 0.017,0.046 0,0.064l-0.212 0.211c-0.017,0.017
          -0.045,0.017 -0.063,0 -0.017,-0.018 -0.017,-0.046 0,-0.064l0.18 -0.179 -0.18 -0.18z'
        />
      </g>
    </IconBase>
  );
}
