import React, { Component } from 'react';
import Swiper from 'swiper/dist/js/swiper';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';
import { routes } from 'routes';
import recipePlaceholder from 'assets/images/recipe-place-holder.png';
import { SliderArrow } from 'components';
import styles from './RelatedRecipesSlider.scss';

const options = {
  slidesPerView: 2,
  simulateTouch: false,
  slidesPerGroup: 2,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev'
  },
  breakpoints: {
    935: {
      slidesPerView: 1,
      slidesPerGroup: 1
    }
  }
};

class RelatedRecipesSlider extends Component {
  state = {
    activeIndex: 0
  };

  componentDidMount() {
    this.swiperInit();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.relatedRecipes !== this.props.relatedRecipes) {
      this.swiper.slideTo(0, 1, false);
    }
  }

  componentWillUnmount() {
    this.swiper.destroy();
  }

  setActiveIndex = () => {
    this.setState({
      activeIndex: this.swiper.activeIndex
    });
  };

  swiperInit = () => {
    this.swiper = new Swiper(this.recipesSlider, {
      ...options
    });
    this.setActiveIndex();
    this.swiper.on('slideChange', this.setActiveIndex);
  };

  render() {
    const { relatedRecipes } = this.props;
    return (
      <div className={styles.sliderWrapper}>
        <div className={cx('swiper-container', styles.sliderContainer)} ref={(node) => { this.recipesSlider = node; }}>
          <div className='swiper-wrapper'>
            {relatedRecipes.length ?
              relatedRecipes.map((item, index) => (
                <div
                  key={index}
                  className={cx('swiper-slide', styles.slide)}
                >
                  <Link to={`${routes.RESOURCES_AND_TOOLS_RECIPES}/${item.slug}`} className={styles.recipeLink}>
                    {item.featured_image ?
                      <img
                        className={styles.thumbImg}
                        // style={{ backgroundImage: `url(${recipe.featured_image})` }}
                        src={item.featured_image_medium}
                        alt={parse(item.title.rendered)}
                      /> :
                      <div
                        className={styles.thumbImg}
                        style={{ backgroundImage: `url(${recipePlaceholder})` }}
                      />
                    }
                    <p className={styles.title}>{parse(item.title.rendered)}</p>
                  </Link>
                </div>
              )) : null
           }
          </div>
        </div>
        <div className={cx('swiper-button-prev', styles.prevArrow, styles.iconContainer, 'swiper-arrow')}>
          <SliderArrow className={styles.icon} />
        </div>
        <div className={cx('swiper-button-next', styles.nextArrow, styles.iconContainer, 'swiper-arrow')}>
          <SliderArrow className={styles.icon} />
        </div>
      </div>
    );
  }
}

RelatedRecipesSlider.propTypes = {
  relatedRecipes: PropTypes.arrayOf(PropTypes.object)
};

export default withStyles(styles)(RelatedRecipesSlider);
