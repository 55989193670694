exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".CorporateSolutions__breadcrumb--1rm6M{margin:40px 50px 0px}@media (max-width: 550px){.CorporateSolutions__breadcrumb--1rm6M{margin:40px 20px 0px}}.CorporateSolutions__pageTitle--ncOFW{padding:0 20px}\n", ""]);

// exports
exports.locals = {
	"breadcrumb": "CorporateSolutions__breadcrumb--1rm6M",
	"pageTitle": "CorporateSolutions__pageTitle--ncOFW"
};