import { composeReducer } from 'redux-compose-reducer';

const initialState = {
  postData: {
    posts: [],
    postsForWidget: [],
    postsTotalLength: 0,
    categories: null
  },
  postsState: {
    page: 1
  },
  postsLazyLoading: false,
  postsLoading: false
};

function getPostsFilters(state, { postData }) {
  const categories = postData.blog.filter(field => field.name === 'blog_widget_category')[0].choices;
  return {
    ...state,
    postData: {
      ...state.postData,
      categories
    }
  };
}

function getPostsForWidget(state, { postData }) {
  const postsForWidget = postData.blog;
  return {
    ...state,
    postData: {
      ...state.postData,
      postsForWidget
    }
  };
}

function getInitialPosts(state, { postData }) {
  const posts = postData.blog;
  const count = postData.count;
  return {
    ...state,
    postData: {
      ...state.postData,
      posts,
      postsTotalLength: count
    },
    postsState: {
      ...state.postsState,
      page: 1
    }
  };
}

function getAllPosts(state, { postData, page }) {
  const postsArray = postData.blog;
  let posts = [];
  if (page > 1) {
    const { posts: postsFromState } = state.postData;
    posts = postsFromState.concat(postsArray);
  } else {
    posts = postsArray;
  }
  return {
    ...state,
    postData: {
      ...state.postData,
      posts,
      postsTotalLength: postData.count
    }
  };
}

function setPostsState(state, { postData }) {
  return {
    ...state,
    postsState: {
      ...state.postsState,
      ...postData
    }
  };
}

function switchPostsLazyLoading(state, { postData }) {
  return {
    ...state,
    postsLazyLoading: postData
  };
}

function switchPostsLoading(state, { postData }) {
  return {
    ...state,
    postsLoading: postData
  };
}

// function resetFilters(state) {
//   return {
//     ...state,
//     postsState: {
//       ...initialState.postsState,
//       title: null
//     },
//     postData: {
//       ...state.postData
//     }
//   };
// }


export default composeReducer(
  'posts',
  {
    getPostsFilters,
    getInitialPosts,
    getAllPosts,
    setPostsState,
    switchPostsLazyLoading,
    switchPostsLoading,
    getPostsForWidget
  },
  initialState
);
