import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import loader from 'assets/images/2020lifestyles-loader.gif';
import { getInitialPosts } from 'actions/posts';
import {
  Breadcrumbs,
  PageTitle,
  MetaData
} from 'components';
import { ListView } from './components';
import { page, meta } from '../../mocks/pages/BlogPage';
import styles from './BlogPage.scss';

class BlogPage extends PureComponent {
  static propTypes = {
    getInitialPosts: PropTypes.func.isRequired,
    setPostsState: PropTypes.func.isRequired,
    posts: PropTypes.arrayOf(PropTypes.object),
    location: PropTypes.object,
    postsTotalLength: PropTypes.number,
    postsState: PropTypes.object.isRequired,
    postsLazyLoading: PropTypes.bool,
    postsLoading: PropTypes.bool
  };

  static fetching({ dispatch }) {
    return [
      dispatch(getInitialPosts())
    ];
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const {
      getInitialPosts
    } = this.props;

    getInitialPosts();
  }

  render() {
    const {
      posts,
      location,
      // postsTotalLength,
      // setPostsState,
      // postsLazyLoading,
      // postsState,
      postsLoading
    } = this.props;
    const newMeta = {
      ...meta,
      path: location.pathname
    }
    return (
      <div>
        <MetaData meta={newMeta} />
        <div className={cx(styles.blogWrapper, 'bodyWrapper')}>
          <Breadcrumbs location={location} />
          <PageTitle page={page} />
          {posts.length ?
            <ListView
              // postsTotalLength={postsTotalLength}
              // postsLazyLoading={postsLazyLoading}
              // postsState={postsState}
              // setPostsState={setPostsState}
              posts={posts}
              postsLoading={postsLoading}
            /> : null
          }
          {postsLoading ?
            <div className={styles.loader}>
              <img src={loader} alt='loader' className={styles.loaderIcon} />
            </div> : null
          }
          {!posts.length && !postsLoading ?
            <div className='notFoundMsg'>
              No match found. Please update your criteria
            </div> : null
          }
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(BlogPage);
