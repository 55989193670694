import { connect } from 'react-redux';
import { getStoriesForSlider } from 'actions/successStories';
import { getPostsForWidget, getPostsFilters } from 'actions/posts';
import { HomePage } from 'components';

const mapStateToProps = ({
  successStories: {
    data
  },
  posts: {
    postData
  }
}) => ({
  ...data,
  ...postData
});

const mapDispatchToProps = {
  getStoriesForSlider,
  getPostsForWidget,
  getPostsFilters
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
