import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import styles from './StagesBlocks.scss';

const StagesBlocks = ({ blocks }) => (
  <div className={styles.blocksWrapper}>
    {blocks.map((item, index) => (
      <div className={cx(styles.block, { [styles[item.borderColor]]: item.borderColor })} key={index}>
        <div className={styles.info}>
          {item.title ? <div className={styles.title}>{item.title}</div> : <div />}
          {item.text ? <div className={styles.text}>{item.text}</div> : <div />}
        </div>
      </div>
    ))}
  </div>
);

StagesBlocks.propTypes = {
  blocks: PropTypes.arrayOf(PropTypes.object)
};

export default withStyles(styles)(StagesBlocks);
