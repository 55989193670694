exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".SuccessStoriesSlider__sliderWrapper--3aGvA .SuccessStoriesSlider__slide--1QdIE{display:-webkit-box;display:-ms-flexbox;display:flex;-moz-justify-content:center;-ms-justify-content:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center}.SuccessStoriesSlider__sliderWrapper--3aGvA .ant-carousel{position:relative;z-index:2}.SuccessStoriesSlider__sliderWrapper--3aGvA .success-swiper-button-prev{padding-top:2px;background-size:15px}.SuccessStoriesSlider__sliderWrapper--3aGvA .success-swiper-button-prev.swiper-button-disabled{opacity:0.4}.SuccessStoriesSlider__sliderWrapper--3aGvA .success-swiper-button-next{background-size:15px}.SuccessStoriesSlider__sliderWrapper--3aGvA .success-swiper-button-next.swiper-button-disabled{opacity:0.4}.SuccessStoriesSlider__sliderWrapper--3aGvA .SuccessStoriesSlider__sliderControls--I0696{width:70px;margin:20px auto 30px;display:-webkit-box;display:-ms-flexbox;display:flex;-moz-justify-content:space-between;-ms-justify-content:space-between;-webkit-box-pack:space-between;-ms-flex-pack:space-between;justify-content:space-between;-webkit-align-items:flex-start;-moz-align-items:flex-start;-ms-align-items:flex-start;align-items:flex-start}.SuccessStoriesSlider__sliderWrapper--3aGvA .SuccessStoriesSlider__sliderControls--I0696 .SuccessStoriesSlider__prevArrow--312yp{transform:rotate(-180deg);cursor:pointer;position:relative}.SuccessStoriesSlider__sliderWrapper--3aGvA .SuccessStoriesSlider__sliderControls--I0696 .SuccessStoriesSlider__nextArrow--3jxmv{cursor:pointer;position:relative}@media (max-width: 1100px){.SuccessStoriesSlider__sliderWrapper--3aGvA{width:820px;margin:auto}}@media (max-width: 934px){.SuccessStoriesSlider__sliderWrapper--3aGvA{width:550px}}@media (max-width: 600px){.SuccessStoriesSlider__sliderWrapper--3aGvA{width:270px}}\n", ""]);

// exports
exports.locals = {
	"sliderWrapper": "SuccessStoriesSlider__sliderWrapper--3aGvA",
	"slide": "SuccessStoriesSlider__slide--1QdIE",
	"sliderControls": "SuccessStoriesSlider__sliderControls--I0696",
	"prevArrow": "SuccessStoriesSlider__prevArrow--312yp",
	"nextArrow": "SuccessStoriesSlider__nextArrow--3jxmv"
};