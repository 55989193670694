import React from 'react';
import PropTypes from 'prop-types';
import _toLower from 'lodash/toLower';
import toTitleCase from 'utils/toTitleCase';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import styles from './PageTitle.scss';

const PageTitle = ({ page }) => {
  const title = toTitleCase(_toLower(page.name));

  return (
    <div className={styles.pageTitle}>
      <h1 className={styles.title}>{title}</h1>
      {page.subTitle ?
        <h2 className={styles.subTitle}>{page.subTitle}</h2> : null
      }
      <hr className={styles.divider} />
    </div>
  );
};

PageTitle.propTypes = {
  page: PropTypes.object
};

export default withStyles(styles)(PageTitle);
