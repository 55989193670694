import React from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import formatPostExcerpt from 'utils/formatPostExcerpt';
import filterPostCategories from 'utils/filterPostCategories';
import styles from './PostCard.scss';

const PostCard = ({ post }) => (
  <div className={styles.card}>
    <div className={styles.content}>
      <div className={styles.meta}>
        {filterPostCategories(post.categories_names).map((item, index) => (
          <p key={index} className={styles.cat}>
            <a
              href={`https://360degrees.blog/category/${item[1]}`}
              target='_blank'
              rel='noopener noreferrer'
            >
              {item[0]}
            </a>
            <span>|</span>
          </p>
        ))}
        <div className={styles.timeStamp}>{post.formatted_date}</div>
      </div>
      <h3 className={styles.title}><a href={post.link} target='_blank'>{parse(post.title.rendered)}</a></h3>
      <div className={styles.excerpt}>{formatPostExcerpt(parse(post.excerpt.rendered), post.link)}</div>
    </div>
  </div>
);

PostCard.propTypes = {
  post: PropTypes.object
};

export default withStyles(styles)(PostCard);
