import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import loader from 'assets/images/2020lifestyles-loader.gif';
import { getInitialStories, getStoriesFilters } from 'actions/successStories';
import {
  Breadcrumbs,
  PageTitle,
  MetaData
} from 'components';
import { ListView, Filters } from './components';
import { page, meta } from '../../mocks/pages/SuccessStoriesPage';
import styles from './SuccessStoriesPage.scss';

class SuccessStoriesPage extends PureComponent {
  static propTypes = {
    getInitialStories: PropTypes.func.isRequired,
    getStoriesFilters: PropTypes.func.isRequired,
    setStoriesState: PropTypes.func.isRequired,
    successStories: PropTypes.arrayOf(PropTypes.object),
    location: PropTypes.object,
    storiesTotalLength: PropTypes.number,
    storiesState: PropTypes.object.isRequired,
    storiesLazyLoading: PropTypes.bool,
    genders: PropTypes.object,
    diets: PropTypes.object,
    storiesLoading: PropTypes.bool
  };

  static fetching({ dispatch }) {
    return [
      dispatch(getInitialStories()),
      dispatch(getStoriesFilters())
    ];
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const {
      getInitialStories,
      getStoriesFilters
    } = this.props;

    getInitialStories();
    getStoriesFilters();
  }

  render() {
    const {
      successStories,
      location,
      storiesTotalLength,
      setStoriesState,
      storiesLazyLoading,
      storiesState,
      genders,
      diets,
      storiesLoading
    } = this.props;

    const newMeta = {
      ...meta,
      path: location.pathname
    }

    return (
      <div>
        <MetaData meta={newMeta} />
        <div className='bodyWrapper'>
          <Breadcrumbs location={location} />
          <PageTitle page={page} />
          {genders !== null || diets !== null ?
            <Filters
              storiesState={storiesState}
              genders={genders}
              diets={diets}
              setStoriesState={setStoriesState}
            /> : null
          }
          {successStories.length ?
            <ListView
              storiesTotalLength={storiesTotalLength}
              storiesLazyLoading={storiesLazyLoading}
              storiesState={storiesState}
              setStoriesState={setStoriesState}
              successStories={successStories}
              storiesLoading={storiesLoading}
            /> : null
          }
          {storiesLoading ?
            <div className={styles.loader}>
              <img src={loader} alt='loader' className={styles.loaderIcon} />
            </div> : null
          }
          {!successStories.length && !storiesLoading ?
            <div className='notFoundMsg'>
              No match found. Please update your criteria
            </div> : null
          }
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(SuccessStoriesPage);
