import React, { PureComponent, Fragment } from 'react';
import axios from 'axios';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';
import moment from 'moment';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { phoneMask, phoneSchema } from 'utils/validationSchemas';
import { Select, Modal, Input } from 'components';
import style from './ReferAFriendForm.scss';

const overlayStyles = {
    backgroundColor: 'rgba(0, 0, 0, 0.75)'
};

const schema = Yup.object().shape({
    patient_name: Yup.string().required('Required'),
    patient_email: Yup.string().email('Invalid email address').required('Required'),
    referral_first_name: Yup.string().required('Required'),
    referral_last_name: Yup.string().required('Required'),
    referral_email: Yup.string().email('Invalid email address').required('Required'),
    referral_phone: phoneSchema.required('Required'),
    preference: Yup.string()
});
  
const initialValues = {
    patient_name: '',
    patient_email: '',
    referral_first_name: '',
    referral_last_name: '',
    referral_email: '',
    referral_phone: '',
    preference: 'email'
};

const options = [
    {
        id: 0,
        label: 'Email',
        value: 'email'
    },
    {
        id: 1,
        label: 'Phone',
        value: 'phone'
    }
];

class ReferAFriendForm extends PureComponent {
  state = {
    preferredDay: moment(),
    submissionPopupIsOpen: false,
    submissionMessage: ''
  };

  onSubmit = (values, { resetForm }) => {
    axios({
      method: 'POST',
      url: '/send-referral',
      data: values
    }).then((response) => {
      if (response.data === 'Success') {
        resetForm();
        this.setState({ submissionMessage: 'success' });
        this.openSubmissionPopup();
      } else if (response.data === 'Fail') {
        this.setState({ submissionMessage: 'fail' });
        this.openSubmissionPopup();
      }
    });
  };

  openSubmissionPopup = () => {
    this.setState({ submissionPopupIsOpen: true });
  };

  closeSubmissionPopup = () => {
    this.setState({ submissionPopupIsOpen: false });
  };

  render() {
    const { submissionPopupIsOpen } = this.state;
    return (
      <Fragment>
        <div
          id='ReferAFriend-form'
          className={style.ReferAFriendFormMain}
        >
          <div className={style.ReferAFriendFormContent}>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={schema}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={this.onSubmit}
            >
            {({
                values,
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldError,
                setFieldValue,
                dirty
            }) => (
                <Form onSubmit={handleSubmit} className={style.form}>
                <Input
                    id='patient_name'
                    type='text'
                    name='patient_name'
                    label='Current Patient Name'
                    isRequiredAfterLabel
                    value={values.patient_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    inputClassName={style.input}
                    className={style.inputWrapper}
                    labelClassName={style.label}
                    error={errors.patient_name}
                    setFieldError={setFieldError}
                    isFormik
                />

                <Input
                    id='patient_email'
                    type='email'
                    name='patient_email'
                    label='Current Patient Email'
                    isRequiredAfterLabel
                    value={values.patient_email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    inputClassName={style.input}
                    className={style.inputWrapper}
                    labelClassName={style.label}
                    error={errors.patient_email}
                    setFieldError={setFieldError}
                    isFormik
                />

                <Input
                    id='referral_first_name'
                    type='text'
                    name='referral_first_name'
                    label="Referral's First Name"
                    isRequiredAfterLabel
                    value={values.referral_first_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    inputClassName={style.input}
                    className={style.inputWrapper}
                    labelClassName={style.label}
                    error={errors.referral_first_name}
                    setFieldError={setFieldError}
                    isFormik
                />

                <Input
                    id='referral_last_name'
                    type='text'
                    name='referral_last_name'
                    label="Referral's Last Name"
                    isRequiredAfterLabel
                    value={values.referral_last_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    inputClassName={style.input}
                    className={style.inputWrapper}
                    labelClassName={style.label}
                    error={errors.referral_last_name}
                    setFieldError={setFieldError}
                    isFormik
                />

                <Input
                    id='referral_email'
                    type='email'
                    name='referral_email'
                    label="Referral's Email"
                    isRequiredAfterLabel
                    value={values.referral_email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    inputClassName={style.input}
                    className={style.inputWrapper}
                    labelClassName={style.label}
                    error={errors.referral_email}
                    setFieldError={setFieldError}
                    isFormik
                />

                <Input
                    id='referral_phone'
                    type='text'
                    name='referral_phone'
                    label="Referral's Phone"
                    isRequiredAfterLabel
                    value={values.referral_phone}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    inputClassName={cx('ant-input', style.input)}
                    className={style.inputWrapper}
                    labelClassName={style.label}
                    error={errors.referral_phone}
                    setFieldError={setFieldError}
                    isFormik
                    isMask
                    mask={phoneMask}
                />

                <Select
                    type='select'
                    name='preference'
                    label='Best Way to Reach Them'
                    value={values.preference}
                    error={errors.preference}
                    setFieldValue={setFieldValue}
                    setFieldError={setFieldError}
                    isFormik
                    dropdownClassName={style.list}
                    className={style.selectWrapper}
                    labelClassName={style.label}
                >
                    {options.map((item) => (
                    <Select.Option key={item.id} value={item.value}>
                        {item.label}
                    </Select.Option>
                    ))}
                </Select>
                <button
                    type='submit'
                    className={style.Button}
                >
                    Submit
                </button>
                </Form>
            )}
            </Formik>
          </div>
        </div>
        <Modal
          visible={submissionPopupIsOpen}
          closeModal={this.closeSubmissionPopup}
          wrapClassName={style.modalWrapper}
          maskStyle={overlayStyles}
          width={707}
        >
          <div className={style.textWrapper}>
            {this.state.submissionMessage === 'success' ?
              <div>
                <h2>THANK YOU</h2>
                <p>Thank you! One of our Program Coordinators will be in touch, we appreciate your referral.</p>
              </div> : <div />
            }
            {this.state.submissionMessage === 'fail' ?
              <div>
                <h2>SORRY</h2>
                <p>Your request was not successfully submitted. Please try again.</p>
                <p>If you continue to experience errors, please contact our program directly
                  at <a href='mailto:support@2020lifestyle.com'>support@2020lifestyle.com</a> or call us at 425.861.6258
                </p>
              </div> : <div />
            }
          </div>
        </Modal>
      </Fragment>
    );
  }
}

export default withStyles(style)(ReferAFriendForm);
