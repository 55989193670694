import * as Yup from 'yup';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

export const phoneRegExp = /^[0-9]{3}-[0-9]{3}-[0-9]{4}/;
export const phoneSchema = Yup.string().matches(phoneRegExp, 'Invalid phone number');
export const phoneMask = [/[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

export const phoneDashboardRegExp = /^\([0-9]{3}\)\s?[0-9]{3}-[0-9]{4}/;
export const phoneDashboardMask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
export const phoneDashboardSchema = Yup.string().matches(phoneDashboardRegExp, 'Invalid phone number');

export const zipRegExp = /^[0-9]{5}/;
export const zipSchema = Yup.string().matches(zipRegExp, 'Invalid ZIP code');
export const zipMask = [/\d/, /\d/, /\d/, /\d/, /\d/];

export const passwordRegEx = /^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9])(?=.*[a-z])[A-Za-z0-9@$!%*#?&]{8,}$/;
export const passwordSchema = Yup.string().required('Required').matches(passwordRegEx, "Password doesn't match safety criteria");

export const cvcRegExp = /^[0-9]{3}/ || /^[0-9]{4}/;
export const cvcSchema = Yup.string().matches(cvcRegExp, 'Invalid CVC');
export const cvcMask = [/\d/, /\d/, /\d/, /\d/];

export const cardNumberRegExp = /^[0-9]{4}-[0-9]{4}-[0-9]{3}/ || /^[0-9]{4}-[0-9]{4}-[0-9]{4}-[0-9]{4}/;
export const cardNumberSchema = Yup.string().matches(cardNumberRegExp, 'Invalid card number');
export const cardNumberMask = [/\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

export const expDateRegExp = /^((0[1-9])|(1[0-2]))\/((\d{2})|(\d{4}))$/;
export const expDateSchema = Yup.string().matches(expDateRegExp, 'Invalid exp. date');
export const expDateMask = [/\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];

export const amountSchema = Yup.number().required().min(5, 'Invalid amount').max(5000, 'Invalid amount');

export const amountMask = (prefix = '$') => createNumberMask({
  includeThousandsSeparator: false,
  allowDecimal: true,
  prefix
});

export const numberMask = createNumberMask({
  prefix: '',
  includeThousandsSeparator: false
});

export const stateSchema = Yup.string().max(2, 'Please enter 2 symbols');
export const stateMask = [/[A-Z]/i, /[A-Z]/i];

export const giftCardRegExp = /^[0-9]{4}-[0-9]{4}-[0-9]{4}-[0-9]{4}/;
export const giftCardSchema = Yup.string().matches(giftCardRegExp, 'Invalid card code');
