import React from 'react';

import geneticNutrition from 'assets/images/GeneticsPage/MEDICAL_IMAGE_GENETICS_NUTRITION_020423.jpg';
import geneticFitness from 'assets/images/GeneticsPage/MEDICAL_IMAGE_GENETICS_FITNESS_022423.jpg';
import geneticBehavior from 'assets/images/GeneticsPage/MEDICAL_IMAGE_GENETICS_BEHAVIOR_020423.jpg';

const noMarginBottom = { marginBottom: '0px' };

const meta = {
  title: 'Genetics',
  // description: 'Welcome to 2020 Lifestyles',
  // keywords: ["weight loss", "nutrition in bellevue"]
}

const page = {
  name: 'GENETICALLY GUIDED WEIGHT LOSS',
  subTitle: 'Uncover the link between your genes and long-term weight loss'
};

const sections = [
  {
    sectionExtraClassName: 'noTopPadding',
    sideBySideSections: [
      {
        imageAlign: 'right',
        img: geneticNutrition,
        title: 'GENETIC NUTRITION',
        text: (
          <div>
            <p><strong>Unlock key nutritional genes.</strong></p>
            <p>
              Discover exactly how genes may impact your weight loss and gain. Guided by 
              your genetic blueprint, your registered dietitian will develop an evidence-based 
              nutrition plan 100% personalized for you. Utilize this information to 
              reduce health risks, lose weight, and love a healthier lifestyle. With the 
              ultimate nutrition roadmap, you’ll be empowered with scientific knowledge to 
              make more confident food choices.
            </p>
            <p style={noMarginBottom}>Your Nutrition Genetic PROfile uncovers:</p>
            <ul>
              <li>Appetite and hunger</li>
              <li>Ability to feel full</li>
              <li>Likelihood of craving sweets</li>
              <li>Age-related declines in metabolism</li>
              <li>Fat-processing ability</li>
              <li>Vitamin/mineral status</li>
              <li>Food intolerances</li>
              <li>And more…</li>
            </ul>
          </div>
        ),
      },
      {
        imageAlign: 'left',
        img: geneticFitness,
        title: 'GENETIC FITNESS',
        text: (
          <div>
            <p><strong>Discover your fitness potential as it relates to weight loss.</strong></p>
            <p>
                Genetically personalized fitness training provides valuable insights for you 
                and your personal trainer. Using your genetic blueprint, your trainer will 
                develop a genetically-optimized fitness plan personalized to help you reach 
                your goals faster, lose weight without losing muscle, and be the best you can be.
            </p>
            <p style={noMarginBottom}>Your Genetic Fitness PROfile includes:</p>
            <ul>
              <li>Weight loss response to exercise</li>
              <li>Metabolism</li>
              <li>Exercise recovery</li>
              <li>Muscle Strength</li>
              <li>Endurance</li>
              <li>Joint Health</li>
              <li>Movement</li>
              <li>Power performance/athletic potential</li>
            </ul>
          </div>
        ),
      },
      {
        imageAlign: 'right',
        img: geneticBehavior,
        title: 'GENETIC BEHAVIOR',
        text: (
          <div>
            <p><strong>The science behind your habits revealed.</strong></p>
            <p>
                Learn how your behavior genes can influence how you think and feel, as well as 
                your tendency toward food addiction and how your cognitive reward system responds 
                to food. With insights from your genetics, our Licensed Counselors can identify 
                actionable insights that can improve your ability to deal with stress, anxiety, 
                depression, weight loss, and more.
            </p>
            <p style={noMarginBottom}>Your Genetic Behavior PROfile tests for the following:</p>
            <ul>
              <li>Feelings – empathy to euphoria</li>
              <li>Behavior – behaviors that may impact impulsive behavior</li>
              <li>Tolerance – from stress, anxiety, and pain</li>
            </ul>
          </div>
        ),
      }
    ]
  },
];

export {
  page,
  sections,
  meta
};
