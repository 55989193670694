import React from 'react';
import { Provider } from 'react-redux';
import 'url-search-params-polyfill';
import { store } from './store';
import Router from './router';

export default function () {
  const context = {
    insertCss: (...styles) => {
      const removeCss = styles.map(x => x._insertCss());
      return () => {
        removeCss.forEach(f => f());
      };
    }
  };
  return (
    <Provider store={store}>
      <Router context={context} />
    </Provider>
  );
}
