import React from 'react';

const formatPostExcerpt = (paragraph, link) => {
  const excerpt = paragraph[0].props.children;
  const limitExcerpt = excerpt.substring(0, 220);
  const formattedExcerpt = limitExcerpt.replace(/^[.\s]+|[.\s]+$/g, '');
  return (
    <p>
      {formattedExcerpt}
      ...
      <a href={link} target='_blank'>Read more</a>
    </p>
  );
};

export default formatPostExcerpt;
