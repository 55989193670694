import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { Select } from 'antd';

import styles from './TimeOfDays.scss';

const Option = Select.Option;

const dropdownAlign = { offset: [-2, 0] };

class TimeOfDays extends PureComponent {
  static propTypes = {
    time_of_days: PropTypes.object,
    time_of_day: PropTypes.string,
    recipesState: PropTypes.object.isRequired,
    setRecipesState: PropTypes.func.isRequired
  };

  onChange = (value) => {
    const { setRecipesState } = this.props;

    setRecipesState({
      time_of_day: value === 'Time of Day' ? null : value
    });
  };

  render() {
    const { time_of_days } = this.props;
    const { time_of_day } = this.props.recipesState;

    return (
      <div className={styles.selectWrapper}>
        <Select
          className='select'
          style={{ width: '100%' }}
          placeholder='Time of Day'
          dropdownAlign={dropdownAlign}
          dropdownClassName={styles.list}
          onChange={this.onChange}
          value={time_of_day || 'Time of Day'}
        >
          <Option value='Time of Day' key='Time of Day'>Time of Day</Option>
          {Object.keys(time_of_days).map((keyName, i) => (
            <Option value={time_of_days[keyName]} key={i}>{time_of_days[keyName]}</Option>
          ))}
        </Select>
      </div>
    );
  }
}

export default withStyles(styles)(TimeOfDays);
