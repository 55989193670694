import { createTypes } from 'redux-compose-reducer';
import { get } from 'utils/wordPressApiRequester';
import { clearBlankParamsFromObject } from 'utils/clearBlankParamsFromObject';

export const TYPES = createTypes('recipes', [
  'getRecipesFilters',
  'getInitialRecipes',
  'getAllRecipes',
  'setRecipesState',
  'switchRecipesLazyLoading',
  'switchRecipesLoading',
  'resetFilters',
  'getRecipe',
  'getRelatedRecipes'
]);

export const getRelatedRecipes = ({ stage }) => async (dispatch) => {
  try {
    const { data } = await get(`wp/v2/2020recipes?per_page=10&stage=${stage}`);
    await dispatch({ type: TYPES.getRelatedRecipes, data });
  } catch (e) {
    console.error(e);
  }
};

export const getRecipe = slug => async (dispatch) => {
  try {
    dispatch({ type: TYPES.switchRecipesLoading, data: true });
    const { data } = await get(`wp/v2/recipes?slug=${slug}`);
    await dispatch({ type: TYPES.getRecipe, data });
    const stage = data[0].ACF.stage;
    await dispatch(getRelatedRecipes({ stage }));
    dispatch({ type: TYPES.switchRecipesLoading, data: false });
  } catch (e) {
    console.error(e);
  }
};

export const getRecipesFilters = () => async (dispatch) => {
  const route = 'powerapi/v1/siteacf';
  try {
    dispatch({ type: TYPES.switchRecipesLoading, data: true });
    const { data } = await get(route);
    await dispatch({ type: TYPES.getRecipesFilters, data });
    dispatch({ type: TYPES.switchRecipesLoading, data: false });
  } catch (e) {
    console.error(e);
  }
};

export const getInitialRecipes = stage => async (dispatch) => {
  let stageNum = null;
  let stageData = '';
  if (stage !== null && stage.includes('?stage-')) {
    stageData = stage.split('?stage-')[1];
    stageNum = `stage=${stageData}&`;
  }
  try {
    dispatch({ type: TYPES.resetFilters });
    dispatch({ type: TYPES.switchRecipesLoading, data: true });
    await get(`wp/v2/2020recipes?${stageNum !== null ? stageNum : ''}per_page=10&page=1`).then((res) => {
      dispatch({ type: TYPES.getAllRecipes, data: res.data, page: 1, stage: stageData });
    });
    dispatch({ type: TYPES.switchRecipesLoading, data: false });
  } catch (e) {
    console.error(e);
  }
};

export const getAllRecipes = ({
  time_of_day,
  dish,
  difficulty,
  stage,
  page,
  isLoader
}) => (dispatch) => {
  const loaderType = isLoader ? TYPES.switchRecipesLazyLoading : TYPES.switchRecipesLoading;
  let stageData = '';
  const query = window.location.search;
  if (query !== '') {
    stageData = query.split('?stage-')[1];
  }
  if (stage === 'Stage' && stageData !== '') {
    stageData = null;
  }
  if (stage === null && stageData === ''){
    stageData = null
  }
  const params = {
    per_page: 10,
    page,
    time_of_day,
    dish,
    difficulty,
    stage: stage !== null && stage !== 'Stage' ? stage : stageData
  };
  const finalParams = clearBlankParamsFromObject(params);
  const queryString = Object.keys(finalParams).map(key => (`${key}=${finalParams[key]}`)).join('&');
  return Promise.resolve(dispatch({ type: loaderType, data: true }))
    .then(() => get(`wp/v2/2020recipes?${queryString}`))
    .then((res) => {
      dispatch({ type: TYPES.getAllRecipes, data: res.data, page });
    })
    .then(() => {
      dispatch({ type: TYPES.switchRecipesLoading, data: false });
      dispatch({ type: TYPES.switchRecipesLazyLoading, data: false });
    })
    .catch((e) => {
      dispatch({ type: TYPES.switchRecipesLoading, data: false });
      dispatch({ type: TYPES.switchRecipesLazyLoading, data: false });
      // add error handler
      console.error(e);
      throw e;
    });
};

export const setRecipesState = (data, isLoader) => async (dispatch, getState) => {
  await dispatch({ type: TYPES.setRecipesState, data });
  const { recipesState } = getState().recipes;
  const { page } = recipesState;
  const pageNumber = isLoader ? page : 1;
  await dispatch(getAllRecipes({ ...recipesState, page: pageNumber, isLoader }));
};

export const resetFilters = () => async (dispatch, getState) => {
  dispatch({ type: TYPES.resetFilters });

  const { recipesState } = getState().recipes;
  await dispatch(getAllRecipes({ ...recipesState }));
};
