import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import loader from 'assets/images/2020lifestyles-loader.gif';
import { RecipeCard } from './components';

import styles from './ListView.scss';

class ListView extends PureComponent {
  state = {
    pageCount: 1
  }

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.recipesState.time_of_day !== this.props.recipesState.time_of_day ||
      prevProps.recipesState.dish !== this.props.recipesState.dish ||
      prevProps.recipesState.difficulty !== this.props.recipesState.difficulty ||
      prevProps.recipesState.stage !== this.props.recipesState.stage
    ) {
      this.setState({ pageCount: 1 });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll);
  }

  onScroll = () => {
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }

    this.timeout = setTimeout(() => {
      const { setRecipesState, recipes, recipesLazyLoading, recipesTotalLength } = this.props;
      const recipesWrappePosition = this.recipesWrapper.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      if (recipesWrappePosition.top + recipesWrappePosition.height < windowHeight &&
            recipes.length < recipesTotalLength &&
           !recipesLazyLoading) {
        this.setState({ pageCount: this.state.pageCount + 1 });
        setRecipesState({
          page: this.state.pageCount
        }, true);
      }
    }, 200);
  };

  setRecipesWrapperRef = (node) => {
    this.recipesWrapper = node;
    return this.recipesWrapper;
  };

  render() {
    const {
      recipes,
      recipesLazyLoading,
      recipesLoading
    } = this.props;
    return (
      <div className={styles.recipesListWrapper}>
        <div
          className={styles.recipesList}
          ref={node => this.setRecipesWrapperRef(node)}
        >
          {recipes.map((item, index) => (
            <div className={styles.recipe} key={index}>
              <RecipeCard recipe={item} />
            </div>
          ))}
        </div>
        {/* recipesLoading ?
          <div className={styles.loader}>
            <img src={loader} alt='loader' className={styles.loaderIcon} />
          </div> : <div />
        */}
        {recipesLazyLoading ?
          <div className={styles.loader}>
            <img src={loader} alt='loader' className={styles.loaderIcon} />
          </div> : <div />
        }
      </div>
    );
  }
}

ListView.propTypes = {
  recipes: PropTypes.arrayOf(PropTypes.object),
  setRecipesState: PropTypes.func.isRequired,
  recipesState: PropTypes.object.isRequired,
  recipesLazyLoading: PropTypes.bool.isRequired,
  recipesTotalLength: PropTypes.number.isRequired,
  recipesLoading: PropTypes.bool.isRequired
};

ListView.defaultProps = {
  recipes: []
};

export default withStyles(styles)(ListView);
