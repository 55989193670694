exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Genetics__breadcrumb--3MxUM{margin:40px 50px 0px}@media (max-width: 550px){.Genetics__breadcrumb--3MxUM{margin:40px 20px 0px}}.Genetics__pageTitle--3eWJR{padding:0 20px}\n", ""]);

// exports
exports.locals = {
	"breadcrumb": "Genetics__breadcrumb--3MxUM",
	"pageTitle": "Genetics__pageTitle--3eWJR"
};