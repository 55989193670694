import { createTypes } from 'redux-compose-reducer';
import { get } from 'utils/wordPressApiRequester';
import { clearBlankParamsFromObject } from 'utils/clearBlankParamsFromObject';

export const TYPES = createTypes('successStories', [
  'getStoriesForSlider',
  'getStoriesFilters',
  'getInitialStories',
  'getAllStories',
  'setStoriesState',
  'switchStoriesLazyLoading',
  'switchStoriesLoading',
  'resetFilters'
]);

export const getStoriesForSlider = () => async (dispatch) => {
  const route = 'wp/v2/success_stories?per_page=6';
  try {
    get(route).then((res) => {
      dispatch({ type: TYPES.getStoriesForSlider, data: res.data });
    });
  } catch (e) {
    console.error(e);
  }
};

export const getStoriesFilters = () => async (dispatch) => {
  const route = 'powerapi/v1/siteacf';
  try {
    const { data } = await get(route);
    await dispatch({ type: TYPES.getStoriesFilters, data });
  } catch (e) {
    console.error(e);
  }
};

export const getInitialStories = () => async (dispatch) => {
  try {
    dispatch({ type: TYPES.resetFilters });
    dispatch({ type: TYPES.switchStoriesLoading, data: true });
    await get('wp/v2/2020stories?per_page=8&page=1').then((res) => {
      dispatch({ type: TYPES.getAllStories, data: res.data, page: 1 });
    });
    dispatch({ type: TYPES.switchStoriesLoading, data: false });
  } catch (e) {
    console.error(e);
  }
  // try {
  //   dispatch({ type: TYPES.switchStoriesLoading, data: true });
  //   await get('wp/v2/2020stories?per_page=8&page=1').then((res) => {
  //     console.log('init', res);
  //     dispatch({ type: TYPES.getAllStories, data: res.data, page: 1 });
  //   });
  //   dispatch({ type: TYPES.switchStoriesLoading, data: false });
  // } catch (e) {
  //   console.error(e);
  // }
};

export const getAllStories = ({
  gender,
  diet,
  page,
  isLoader
}) => (dispatch) => {
  const loaderType = isLoader ? TYPES.switchStoriesLazyLoading : TYPES.switchStoriesLoading;
  const params = {
    per_page: 8,
    page,
    gender,
    diet
  };
  const finalParams = clearBlankParamsFromObject(params);
  const queryString = Object.keys(finalParams).map(key => (`${key}=${finalParams[key]}`)).join('&');
  return Promise.resolve(dispatch({ type: loaderType, data: true }))
    .then(() => get(`wp/v2/2020stories?${queryString}`))
    .then((res) => {
      dispatch({ type: TYPES.getAllStories, data: res.data, page });
    })
    .then(() => {
      dispatch({ type: TYPES.switchStoriesLoading, data: false });
      dispatch({ type: TYPES.switchStoriesLazyLoading, data: false });
    })
    .catch((e) => {
      dispatch({ type: TYPES.switchStoriesLoading, data: false });
      dispatch({ type: TYPES.switchStoriesLazyLoading, data: false });
      // add error handler
      console.error(e);
      throw e;
    });
};

export const setStoriesState = (data, isLoader) => async (dispatch, getState) => {
  await dispatch({ type: TYPES.setStoriesState, data });
  const { storiesState } = getState().successStories;
  const { page } = storiesState;
  const pageNumber = isLoader ? page : 1;
  await dispatch(getAllStories({ ...storiesState, page: pageNumber, isLoader }));
};

export const resetFilters = () => async (dispatch, getState) => {
  dispatch({ type: TYPES.resetFilters });

  const { storiesState } = getState().successStories;
  await dispatch(getAllStories({ ...storiesState }));
};
