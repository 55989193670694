import { createTypes } from 'redux-compose-reducer';

export const TYPES = createTypes('mainSettings', [
  'setClientRender',
  'setBoolAfterFirstRender',
  'setLoading'
]);

export const setClientRender = () => ({
  type: TYPES.setClientRender
});

export const setLoading = payload => dispatch => (
  dispatch({
    type: TYPES.setLoading,
    payload
  })
);

export const setBoolAfterFirstRender = () => ({
  type: TYPES.setBoolAfterFirstRender
});
