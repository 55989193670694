import React, { Component } from 'react';
import Swiper from 'swiper/dist/js/swiper';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';
import { SliderArrow, SuccessStoryCard } from 'components';
import styles from './SuccessStoriesSlider.scss';

const options = {
  slidesPerView: 4,
  simulateTouch: false,
  slidesPerGroup: 4,
  navigation: {
    nextEl: '.success-swiper-button-next',
    prevEl: '.success-swiper-button-prev'
  },
  breakpoints: {
    600: {
      slidesPerView: 1,
      slidesPerGroup: 1
    },
    934: {
      slidesPerView: 2,
      slidesPerGroup: 2
    },
    1100: {
      slidesPerView: 3,
      slidesPerGroup: 3
    }
  }
};

class SuccessStoriesSlider extends Component {
  state = {
    activeIndex: 0
  };

  componentDidMount() {
    this.swiperInit();
  }

  componentWillUnmount() {
    this.swiper.destroy();
  }

  setActiveIndex = () => {
    this.setState({
      activeIndex: this.swiper.activeIndex
    });
  };

  swiperInit = () => {
    this.swiper = new Swiper(this.storiesSlider, {
      ...options
    });
    this.setActiveIndex();
    this.swiper.on('slideChange', this.setActiveIndex);
  };

  render() {
    const {
      storiesForSlider
    } = this.props;
    return (
      <div className={styles.sliderWrapper}>
        <div className='swiper-container' ref={(node) => { this.storiesSlider = node; }}>
          <div className='swiper-wrapper'>
            {storiesForSlider.map((item, index) => (
              <div
                key={index}
                className={cx('swiper-slide', styles.slide)}
              >
                <SuccessStoryCard successStory={item} />
              </div>
            ))}
          </div>
        </div>
        <div className={styles.sliderControls}>
          <div className={cx('success-swiper-button-prev', styles.prevArrow, 'swiper-arrow')}>
            <SliderArrow className={styles.icon} />
          </div>
          <div className={cx('success-swiper-button-next', styles.nextArrow, 'swiper-arrow')}>
            <SliderArrow className={styles.icon} />
          </div>
        </div>
      </div>
    );
  }
}

SuccessStoriesSlider.propTypes = {
  storiesForSlider: PropTypes.arrayOf(PropTypes.object)
};

export default withStyles(styles)(SuccessStoriesSlider);
