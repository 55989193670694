import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { WidgetTabs, TabContent } from './components';

import styles from './BlogWidget.scss';

class BlogWidget extends PureComponent {
  render() {
    const {
      postsForWidget,
      categories
    } = this.props;
    return (
      <div className={styles.blogWidgetWrapper}>
        {categories && postsForWidget.length ?
          <WidgetTabs>
            {Object.keys(categories).map((keyName, i) => (
              <div tab={categories[keyName]} key={i}>
                <TabContent posts={postsForWidget.filter(post => post.ACF.blog_widget_category === categories[keyName])} />
              </div>
            ))}
          </WidgetTabs> : <div />
        }
      </div>
    );
  }
}

BlogWidget.propTypes = {
  postsForWidget: PropTypes.arrayOf(PropTypes.object),
  categories: PropTypes.object
};

export default withStyles(styles)(BlogWidget);
