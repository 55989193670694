import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { Select } from 'antd';

import styles from './Stages.scss';

const Option = Select.Option;

const dropdownAlign = { offset: [-2, 0] };

class Stages extends PureComponent {
  static propTypes = {
    stages: PropTypes.object,
    stage: PropTypes.string,
    recipesState: PropTypes.object.isRequired,
    setRecipesState: PropTypes.func.isRequired,
    defaultStage: PropTypes.string,
    location: PropTypes.object
  };

  componentDidMount() {
    const { setRecipesState, defaultStage } = this.props;
    setRecipesState({
      stage: defaultStage
    });
  }

  componentDidUpdate(prevProps) {
    const { setRecipesState, location } = this.props;
    if (prevProps.location.search !== location.search) {
      console.log('diff');
      setRecipesState({
        stage: null
      });
    }
  }

  onChange = (value) => {
    const { setRecipesState, defaultStage } = this.props;
    if (defaultStage !== null && value === 'Stage') {
      setRecipesState({
        stage: 'Stage'
      });
    } else {
      setRecipesState({
        stage: value
      });
    }
  };

  render() {
    const { stages, defaultStage } = this.props;
    const { stage } = this.props.recipesState;

    return (
      <div className={styles.selectWrapper}>
        <Select
          className='select'
          style={{ width: '100%' }}
          placeholder='Stage'
          dropdownAlign={dropdownAlign}
          dropdownClassName={styles.list}
          onChange={this.onChange}
          value={stage || defaultStage || 'Stage'}
        >
          <Option value='Stage' key='Stage'>Stage</Option>
          {Object.keys(stages).map((keyName, i) => (
            <Option value={stages[keyName]} key={i}>{stages[keyName]}</Option>
          ))}
        </Select>
      </div>
    );
  }
}

export default withStyles(styles)(Stages);
