import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { Select } from 'antd';

import styles from './Difficulties.scss';

const Option = Select.Option;

const dropdownAlign = { offset: [-2, 0] };

class Difficulties extends PureComponent {
  static propTypes = {
    difficulties: PropTypes.object,
    difficulty: PropTypes.string,
    recipesState: PropTypes.object.isRequired,
    setRecipesState: PropTypes.func.isRequired
  };

  onChange = (value) => {
    const { setRecipesState } = this.props;

    setRecipesState({
      difficulty: value === 'Difficulty' ? null : value
    });
  };

  render() {
    const { difficulties } = this.props;
    const { difficulty } = this.props.recipesState;

    return (
      <div className={styles.selectWrapper}>
        <Select
          className='select'
          style={{ width: '100%' }}
          placeholder='Difficulty'
          dropdownAlign={dropdownAlign}
          dropdownClassName={styles.list}
          onChange={this.onChange}
          value={difficulty || 'Difficulty'}
        >
          <Option value='Difficulty' key='Difficulty'>Difficulty</Option>
          {Object.keys(difficulties).map((keyName, i) => (
            <Option value={difficulties[keyName]} key={i}>{difficulties[keyName]}</Option>
          ))}
        </Select>
      </div>
    );
  }
}

export default withStyles(styles)(Difficulties);
