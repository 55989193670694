import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import loader from 'assets/images/2020lifestyles-loader.gif';
import { ExpertCard } from './components';

import styles from './ListView.scss';

class ListView extends PureComponent {
  state = {
    pageCount: 1
  }

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.expertsState.title !== this.props.expertsState.title) {
      this.setState({ pageCount: 1 });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll);
  }

  onScroll = () => {
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }

    this.timeout = setTimeout(() => {
      const { setExpertsState, experts, expertsLazyLoading, expertsTotalLength } = this.props;
      const expertsWrappePosition = this.expertsWrapper.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      // if (expertsState.title !== null) {
      //   this.setState({ pageCount: 1 });
      // }
      if (expertsWrappePosition.top + expertsWrappePosition.height < windowHeight &&
            experts.length < expertsTotalLength &&
           !expertsLazyLoading) {
        this.setState({ pageCount: this.state.pageCount + 1 });
        setExpertsState({
          page: this.state.pageCount
        }, true);
      }
    }, 200);
  };

  setExpertsWrapperRef = (node) => {
    this.expertsWrapper = node;
    return this.expertsWrapper;
  };

  render() {
    const {
      experts,
      expertsLazyLoading,
      expertsLoading
    } = this.props;
    return (
      <div className={styles.expertsListWrapper}>
        <div
          className={styles.expertsList}
          ref={node => this.setExpertsWrapperRef(node)}
        >
          {experts.map((item, index) => (
            <div key={index} className={styles.expert}>
              <ExpertCard expert={item} />
            </div>
          ))}
        </div>
        {expertsLoading ?
          <div className={styles.loader}>
            <img src={loader} alt='loader' className={styles.loaderIcon} />
          </div> : <div />
        }
        {expertsLazyLoading ?
          <div className={styles.loader}>
            <img src={loader} alt='loader' className={styles.loaderIcon} />
          </div> : <div />
        }
      </div>
    );
  }
}

ListView.propTypes = {
  experts: PropTypes.arrayOf(PropTypes.object),
  setExpertsState: PropTypes.func.isRequired,
  expertsState: PropTypes.object.isRequired,
  expertsLazyLoading: PropTypes.bool.isRequired,
  expertsTotalLength: PropTypes.number.isRequired,
  expertsLoading: PropTypes.bool.isRequired
};

ListView.defaultProps = {
  experts: []
};

export default withStyles(styles)(ListView);
