import React from 'react';
import { routes } from 'routes';

import corporateSolutiomBlockImg from 'assets/images/ProgramInformation/CORPORATE_SOLUTIONS_500x500.jpg';
import geneticsBlockImg from 'assets/images/ProgramInformation/GENETICS_500x500.jpg';
import shmBlockImg from 'assets/images/ProgramInformation/SCIENTIFIC_HEALTH_MONITORING_500x500.jpg';
import whyBlockImg from 'assets/images/ProgramInformation/WHY_IT_WORKS_500x500.jpg';
import pricingBlockImg from 'assets/images/ProgramInformation/PRICING_500x500.jpg';

const meta = {
  title: 'Program Information',
  description: 'Personalized exercise and nutrition programs',
  keywords: ['weight loss', 'nutrition in bellevue']
};

const page = {
  name: 'Expert Support for Your Success',
  subTitle: 'Bring Your Health Back in Balance.'
};

const sections = [
  {
    sectionExtraClassName: 'noTopPadding',
    noMobilePadding: true,
    descriptionBlocks: [
      {
        link: {
          to: routes.PROGRAM_INFORMATION_WHY_IT_WORKS
        },
        image: {
          src: whyBlockImg,
          alt: 'Why It Works'
        },
        title: 'Why It Works',
        text: '20/20 LifeStyles is a comprehensive program that incorporates many key components – physician supervision, body chemistry, nutrition, exercise, lifestyle behavior, education, and long-term support to help you bring your health back into balance. No other weight loss program comes close.'
      },
      {
        link: {
          to: routes.PROGRAM_INFORMATION_SCIENTIFIC_HEALTH_MONITORING
        },
        image: {
          src: shmBlockImg,
          alt: 'Scientific Health Monitoring'
        },
        title: 'Scientific Health Monitoring',
        text: 'Understanding how your body works is key to long-term success. Throughout your 20/20 program, and during your 12-week maintenance plan, our physicians will conduct a series of screenings and tests to continually monitor your progress. These ongoing assessments will allow them to adjust your program as necessary and help you maintain your metabolism without losing muscle.'
      },
      {
        link: {
          to: routes.PROGRAM_INFORMATION_PRICING
        },
        image: {
          src: pricingBlockImg,
          alt: 'Program fees'
        },
        title: 'Program Fees',
        text: 'Explore our program pricing. We offer financing and many services may be eligible for insurance reimbursement or through your company health benefits. Clients of 20/20 LifeStyles often discover that the cost of health care visits, medication, and other expenses related to their metabolic conditions are far greater than the cost of the program as well as the quality of life gained is priceless.'
      },
      {
        link: {
          to: routes.PROGRAM_INFORMATION_CORPORATE_SOLUTIONS
        },
        image: {
          src: corporateSolutiomBlockImg,
          alt: 'Corporate Solutions'
        },
        title: 'Corporate Solutions',
        text: 'Many companies have found that by offering 20/20 LifeStyles to their employees, they have decreased their corporate medical costs, improved retention and increased productivity. Our staff is available for health presentations or to review how 20/20 LifeStyles may be utilized at your company.'
      }
    ]
  }
];

export { page, sections, meta };
