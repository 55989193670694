import { connect } from 'react-redux';
import {
  setPostsState,
  getAllPosts,
  getInitialPosts
} from 'actions/posts';
import { BlogPage } from 'components';

const mapStateToProps = ({
  posts: {
    postData,
    postsState,
    postsLazyLoading,
    postsLoading
  }
}) => ({
  ...postData,
  postsState,
  postsLazyLoading,
  postsLoading
});

const mapDispatchToProps = {
  setPostsState,
  getAllPosts,
  getInitialPosts
};

export default connect(mapStateToProps, mapDispatchToProps)(BlogPage);
