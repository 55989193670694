exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Loader__overlayLoader--ydIGE{display:-webkit-box;display:-ms-flexbox;display:flex;-moz-justify-content:center;-ms-justify-content:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-align-items:center;-moz-align-items:center;-ms-align-items:center;align-items:center;position:absolute;right:0;bottom:0;z-index:50;width:100px;height:100px;background:transparent;top:50%;left:50%;transform:translate(-50%, -50%)}@media (max-width: 767px){.Loader__overlayLoader--ydIGE{position:fixed;z-index:99}}.Loader__loaderIcon--2ub8T{height:100px}.Loader__hideOverlay--1eAlo{background-color:transparent}\n", ""]);

// exports
exports.locals = {
	"overlayLoader": "Loader__overlayLoader--ydIGE",
	"loaderIcon": "Loader__loaderIcon--2ub8T",
	"hideOverlay": "Loader__hideOverlay--1eAlo"
};