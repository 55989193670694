exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".PricingPage__breadcrumb--2LGL8{margin:40px 50px 0px}@media (max-width: 550px){.PricingPage__breadcrumb--2LGL8{margin:40px 20px 0px}}.PricingPage__pageTitle--bZ5wC{padding:0 20px}\n", ""]);

// exports
exports.locals = {
	"breadcrumb": "PricingPage__breadcrumb--2LGL8",
	"pageTitle": "PricingPage__pageTitle--bZ5wC"
};