exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".TermsOfService__breadcrumb--12EmH{margin:40px 50px 0px}@media (max-width: 550px){.TermsOfService__breadcrumb--12EmH{margin:40px 20px 0px}}.TermsOfService__pageTitle--28cpj{padding:0 20px}\n", ""]);

// exports
exports.locals = {
	"breadcrumb": "TermsOfService__breadcrumb--12EmH",
	"pageTitle": "TermsOfService__pageTitle--28cpj"
};