import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import {
  Genders,
  Diets
} from './components';

import styles from './Filters.scss';

class Filters extends PureComponent {
  static propTypes = {
    genders: PropTypes.object.isRequired,
    diets: PropTypes.object.isRequired,
    storiesState: PropTypes.object.isRequired,
    setStoriesState: PropTypes.func
  };

  render() {
    const {
      genders,
      diets,
      storiesState,
      setStoriesState
    } = this.props;
    return (
      <div className={cx('clearfix', styles.filters)}>
        <Genders
          storiesState={storiesState}
          genders={genders}
          setStoriesState={setStoriesState}
        />
        <Diets
          storiesState={storiesState}
          diets={diets}
          setStoriesState={setStoriesState}
        />
      </div>
    );
  }
}

export default withStyles(styles)(Filters);
