export FaAngleDown from 'react-icons/lib/fa/angle-down';
export FaAngleLeft from 'react-icons/lib/fa/angle-left';
export FaAngleRight from 'react-icons/lib/fa/angle-right';
export FaAngleUp from 'react-icons/lib/fa/angle-up';
export FaCheckCircleO from 'react-icons/lib/fa/check-circle-o';
export FaCheckCircle from 'react-icons/lib/fa/check-circle';
export FaCheck from 'react-icons/lib/fa/check';
export FaClose from 'react-icons/lib/fa/close';
export FaCommenting from 'react-icons/lib/fa/commenting';
export FaCircleO from 'react-icons/lib/fa/circle-o';
export FaEnvelopeSquare from 'react-icons/lib/fa/envelope-square';
export FaFacebook from 'react-icons/lib/fa/facebook';
export FaFacebookSquare from 'react-icons/lib/fa/facebook-square';
export FaInstagram from 'react-icons/lib/fa/instagram';
export FaLinkedinSquare from 'react-icons/lib/fa/linkedin-square';
export FaMinus from 'react-icons/lib/fa/minus';
export FaPlus from 'react-icons/lib/fa/plus';
export FaPinterest from 'react-icons/lib/fa/pinterest';
export FaPinterestP from 'react-icons/lib/fa/pinterest-p';
export FaSearch from 'react-icons/lib/fa/search';
export FaSortAmountDesc from 'react-icons/lib/fa/sort-amount-desc';
export FaShoppingBag from 'react-icons/lib/fa/shopping-bag';
export FaTwitter from 'react-icons/lib/fa/twitter';
export FaTwitterSquare from 'react-icons/lib/fa/twitter-square';
export FaYoutube from 'react-icons/lib/fa/youtube-play';
export FaYoutubeFull from 'react-icons/lib/fa/youtube';
export FaRefresh from 'react-icons/lib/fa/refresh';
export FaUser from 'react-icons/lib/fa/user';
export FaEdge from 'react-icons/lib/fa/edge';
export FaChrome from 'react-icons/lib/fa/chrome';
export FaOpera from 'react-icons/lib/fa/opera';
export FaFirefox from 'react-icons/lib/fa/firefox';
export FaSafari from 'react-icons/lib/fa/safari';
export FaMapMarker from 'react-icons/lib/fa/map-marker';
export FaTrash from 'react-icons/lib/fa/trash';
export FaDownload from 'react-icons/lib/fa/download';
export Star from 'react-icons/lib/fa/star';
export CaretDown from 'react-icons/lib/fa/caret-down';
export QuestionCircle from 'react-icons/lib/fa/question-circle-o';
// export FaRedo from 'react-icons/lib/fa/redo';
export FaPlusCircle from 'react-icons/lib/fa/plus-circle';

export Logo from './CustomIcons/Logo.jsx';
export BurgerMenu from './CustomIcons/BurgerMenu.jsx';
export BurgerMenuClose from './CustomIcons/BurgerMenuClose.jsx';
export Triangle from './CustomIcons/Triangle.jsx';
export RightArrow from './CustomIcons/RightArrow.jsx';
export LeftArrow from './CustomIcons/LeftArrow.jsx';
export Search from './CustomIcons/Search.jsx';
export ChevronDown from './CustomIcons/ChevronDown';
export ChevronUp from './CustomIcons/ChevronUp';
export SlideControl from './CustomIcons/SlideControl';
export Print from './CustomIcons/Print';
