import React from 'react';
import IconBase from 'react-icon-base';

export default function Triangle(props) {
  return (
    <IconBase viewBox='0 0 16 10' {...props}>
      <defs>
        <path id='ijgka' d='M336 1546l-8-10h16z' />
      </defs>
      <use
        fill='#b99765'
        xlinkHref='#ijgka'
        transform='translate(-328 -1536)'
      />
    </IconBase>
  );
}
