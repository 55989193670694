import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { Select } from 'antd';

import styles from './Diets.scss';

const Option = Select.Option;

const dropdownAlign = { offset: [-2, 0] };

class Diets extends PureComponent {
  static propTypes = {
    diets: PropTypes.object,
    diet: PropTypes.string,
    storiesState: PropTypes.object.isRequired,
    setStoriesState: PropTypes.func.isRequired
  };

  onChange = (value) => {
    const { setStoriesState } = this.props;

    setStoriesState({
      diet: value === 'diet' ? null : value
    });
  };

  render() {
    const { diets } = this.props;
    const { diet } = this.props.storiesState;

    return (
      <div className={styles.selectWrapper}>
        <Select
          className='select'
          style={{ width: '100%' }}
          placeholder='Diet'
          dropdownAlign={dropdownAlign}
          dropdownClassName={styles.list}
          onChange={this.onChange}
          value={diet || 'diet'}
        >
          <Option value='diet' key='diet'>Diet</Option>
          {Object.keys(diets).map((keyName, i) => (
            <Option value={diets[keyName]} key={i}>{diets[keyName]}</Option>
          ))}
        </Select>
      </div>
    );
  }
}

export default withStyles(styles)(Diets);
