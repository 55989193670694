import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';
import { setBoolAfterFirstRender } from 'actions/mainSettings';
import {
  Footer,
  Header
} from 'containers';
import Routes from './Routes';
import WithStylesContext from '../hocs/WithStylesContext';

const mapDispatchToProps = {
  setBoolAfterFirstRender
};

class Router extends PureComponent {
  static propTypes = {
    setBoolAfterFirstRender: PropTypes.func,
    context: PropTypes.object
  };

  componentDidMount() {
    const { setBoolAfterFirstRender } = this.props;
    setBoolAfterFirstRender();
  }

  render() {
    const { context } = this.props;
    return (
      <BrowserRouter>
        <WithStylesContext context={context}>
          <div className='appWrapper'>
            <Header />
            <div id='contentWrapper' className='contentWrapper'>
              <div>{renderRoutes(Routes)}</div>
            </div>
            <Footer />
          </div>
        </WithStylesContext>
      </BrowserRouter>
    );
  }
}

Router.propTypes = {
  context: PropTypes.object.isRequired,
  setBoolAfterFirstRender: PropTypes.func
};

export default connect(null, mapDispatchToProps)(Router);
