import { composeReducer } from 'redux-compose-reducer';

const initialState = {
  data: {
    successStories: [],
    storiesTotalLength: 0,
    storiesForSlider: [],
    genders: null,
    diets: null
  },
  storiesState: {
    page: 1,
    gender: null,
    diet: null
  },
  storiesLazyLoading: false,
  storiesLoading: false
};

function getStoriesFilters(state, { data }) {
  const genders = data.stories.filter(field => field.name === 'gender')[0].choices;
  const diets = data.stories.filter(field => field.name === 'diet')[0].choices;
  return {
    ...state,
    data: {
      ...state.data,
      genders,
      diets
    }
  };
}

function getInitialStories(state, { data }) {
  const successStories = data.stories;
  const count = data.count;
  return {
    ...state,
    data: {
      ...state.data,
      successStories,
      storiesTotalLength: count
    },
    storiesState: {
      ...state.storiesState,
      page: 1
    }
  };
}

function getStoriesForSlider(state, { data }) {
  const storiesForSliderArray = data;
  let storiesForSlider = [];
  storiesForSlider = storiesForSliderArray;
  return {
    ...state,
    data: {
      ...state.data,
      storiesForSlider
    }
  };
}

function getAllStories(state, { data, page }) {
  const storiesArray = data.stories;
  let successStories = [];
  if (page > 1) {
    const { successStories: successStoriesFromState } = state.data;
    successStories = successStoriesFromState.concat(storiesArray);
  } else {
    successStories = storiesArray;
  }
  return {
    ...state,
    data: {
      ...state.data,
      successStories,
      storiesTotalLength: data.count
    }
  };
}

function setStoriesState(state, { data }) {
  return {
    ...state,
    storiesState: {
      ...state.storiesState,
      ...data
    }
  };
}

function switchStoriesLazyLoading(state, { data }) {
  return {
    ...state,
    storiesLazyLoading: data
  };
}

function switchStoriesLoading(state, { data }) {
  return {
    ...state,
    storiesLoading: data
  };
}

function resetFilters(state) {
  return {
    ...state,
    storiesState: {
      ...initialState.storiesState,
      gender: null,
      diet: null
    },
    data: {
      ...state.data
    }
  };
}


export default composeReducer(
  'successStories',
  {
    getStoriesForSlider,
    getStoriesFilters,
    getInitialStories,
    getAllStories,
    setStoriesState,
    switchStoriesLazyLoading,
    switchStoriesLoading,
    resetFilters
  },
  initialState
);
