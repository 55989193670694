exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".HealthMonitoringPage__breadcrumb--DpK2p{margin:40px 50px 0px}@media (max-width: 550px){.HealthMonitoringPage__breadcrumb--DpK2p{margin:40px 20px 0px}}.HealthMonitoringPage__pageTitle--248D5{padding:0 20px}\n", ""]);

// exports
exports.locals = {
	"breadcrumb": "HealthMonitoringPage__breadcrumb--DpK2p",
	"pageTitle": "HealthMonitoringPage__pageTitle--248D5"
};