import React from 'react';
import IconBase from 'react-icon-base';
import PropTypes from 'prop-types';

export default function Logo({ color = '#3b3a3a', ...props }) {
  return (
    <IconBase viewBox='0 0 135 54' {...props}>
      <defs><path
        id='nwa1a'
        d='M64.02 41.01h13.42a9.08 9.08 0 0 0 0-18.15H64.02zm-8.8 25.57V14.06h22.22A17.9 17.9 0 0 1 95.3
       31.94a17.9 17.9 0 0 1-17.86 17.88H64.02v16.76z'
      /><path
        id='nwa1b'
        d='M163.8 23.38a17.21 17.21 0 0 0-17.19 17.2c0 9.48 7.71
        17.2 17.19 17.2a17.21 17.21 0 0 0 0-34.4m0 43.2a25.8 25.8 0 0 1-18.38-7.62 25.84 25.84 0 0 1-7.6-18.38c0-6.95 2.7-13.48
         7.6-18.39a25.8 25.8 0 0 1 18.38-7.61 25.8 25.8 0 0 1 18.37 7.61 25.84 25.84 0 0 1 7.6 18.39c0 6.94-2.7 13.47-7.6 18.38a25.8
          25.8 0 0 1-18.37 7.62'
      /><path
        id='nwa1c'
        d='M123.06 65.35l-14-25.36h7.05a9.05 9.05
           0 0 0 9.07-9c0-4.97-4.07-9.01-9.07-9.01h-17l-4.82-8.74h21.82c9.85 0 17.87 7.96 17.87 17.74 0 3.38-.97 6.67-2.8 9.51-1.77
            2.77-4.28 5-7.24 6.43l-.55.27 10.2 18.16z'
      /><path
        id='nwa1d'
        d='M55.22 84.28c0-5.62 4.24-9.46 9.58-9.46 4.16 0 6.5 2.2
             7.78 4.47l-3.34 1.64a5 5 0 0 0-4.44-2.65c-3.17 0-5.58 2.54-5.58 6 0 3.45 2.4 6 5.58 6a4.95 4.95 0 0 0 4.44-2.66l3.34
              1.62a8.47 8.47 0 0 1-7.78 4.5c-5.34 0-9.58-3.85-9.58-9.46'
      />

        <path id='nwa1e' d='M95.99 75.13h3.88v14.85h7.72v3.42H96z' />
        <path
          id='nwa1f'
          d='M130.73 75.13h3.94v10.85c0 2.55 1.4 4.3 4.24 4.3 2.8 0 4.2-1.75 4.2-4.3V75.13h3.94v10.96c0
              4.55-2.6 7.64-8.14 7.64-5.58 0-8.18-3.12-8.18-7.62z'
        />
        <path
          id='nwa1g'
          d='M175.62 90.08v-4.3h5.01c1.54 0 2.36 1 2.36 2.14 0 1.32-.88 2.16-2.36 2.16zm0-7.61v-4.03h4.88c1.31
               0 2.13.88 2.13 2 0 1.18-.82 2.03-2.13 2.03zm-3.88 10.93h9.9c3.49 0 5.32-2.19 5.32-4.93 0-2.3-1.56-4.2-3.5-4.5 1.72-.35
                3.14-1.88 3.14-4.19 0-2.43-1.78-4.65-5.25-4.65h-9.61z'
        />
      </defs>
      <g>
        <g transform='translate(-55 -13)'>
          <g>
            <g><use fill={color} xlinkHref='#nwa1a' /></g>
            <g><use fill={color} xlinkHref='#nwa1b' /></g>
            <g><use fill={color} xlinkHref='#nwa1c' /></g>
            {/* <g><use fill="#03050c" xlinkHref="#nwa1d"/></g>
                  <g><use fill="#03050c" xlinkHref="#nwa1e"/></g>
                  <g><use fill="#03050c" xlinkHref="#nwa1f"/></g>
                  <g><use fill="#03050c" xlinkHref="#nwa1g"/></g> */}
          </g>
        </g>
      </g>
    </IconBase>
  );
}

Logo.propTypes = {
  color: PropTypes.string
};
