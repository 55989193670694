import React from 'react';

const meta = {
  title: 'Blog',
  noFollow: true
  // keywords: ["weight loss", "nutrition in bellevue"]
}

const page = {
  name: 'Healthy Articles for Weight Loss',
  subTitle: 'Expertise you can use.'
};

export {
  page,
  meta
};
