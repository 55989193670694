exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".CTAButton__ctaButtons--1kWCC{display:-webkit-box;display:-ms-flexbox;display:flex;-moz-justify-content:center;-ms-justify-content:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-align-items:center;-moz-align-items:center;-ms-align-items:center;align-items:center}.CTAButton__ctaButtons--1kWCC .CTAButton__ctaButton--2bsMR{height:42px;border-radius:30px;font-size:12px;text-transform:uppercase;width:180px;text-align:center;font-weight:700;letter-spacing:0.5px;margin:0px 5px}.CTAButton__ctaButtons--1kWCC .CTAButton__ctaButton--2bsMR.CTAButton__appointmentButton--cW6AX{color:#fff;background-color:#3B97B8;border:2px solid #3B97B8}.CTAButton__ctaButtons--1kWCC .CTAButton__ctaButton--2bsMR.CTAButton__appointmentButton--cW6AX:hover{background-color:#fff;color:#3B97B8}.CTAButton__ctaButtons--1kWCC .CTAButton__ctaButton--2bsMR.CTAButton__seminarButton--2DgCI{color:#3B97B8;background-color:#fff;border:2px solid #3B97B8}.CTAButton__ctaButtons--1kWCC .CTAButton__ctaButton--2bsMR.CTAButton__seminarButton--2DgCI:hover{background-color:#3B97B8;color:#fff}.CTAButton__ctaButtons--1kWCC .CTAButton__ctaButton--2bsMR:hover{text-decoration:none}@media (max-width: 980px){.CTAButton__ctaButtons--1kWCC .CTAButton__ctaButton--2bsMR{margin-bottom:10px}}@media (max-width: 980px){.CTAButton__ctaButtons--1kWCC{flex-wrap:wrap}}\n", ""]);

// exports
exports.locals = {
	"ctaButtons": "CTAButton__ctaButtons--1kWCC",
	"ctaButton": "CTAButton__ctaButton--2bsMR",
	"appointmentButton": "CTAButton__appointmentButton--cW6AX",
	"seminarButton": "CTAButton__seminarButton--2DgCI"
};