import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';
import { Input as AntInput } from 'antd';
import CN from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { Icon } from 'components';
import { isEmpty } from 'lodash';
import { clearParamsFromObjectByKeys } from 'utils/clearParamsFromObjectByKeys';
import css from './Input.scss';

class Input extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    borderError: PropTypes.bool,
    className: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    floatingLabel: PropTypes.bool,
    labelClassName: PropTypes.string,
    floatedLabelClassName: PropTypes.string,
    inputClassName: PropTypes.string,
    maxLength: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    type: PropTypes.string,
    allowClear: PropTypes.bool,
    clearClassName: PropTypes.string,
    isMask: PropTypes.bool,
    guide: PropTypes.bool,
    mask: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),
    placeholder: PropTypes.string,
    isFormik: PropTypes.bool,
    setFieldError: PropTypes.func,
    isRequiredBeforeLabel: PropTypes.bool,
    isRequiredAfterLabel: PropTypes.bool,
    setRef: PropTypes.func,
    clearError: PropTypes.func,
    autoFocus: PropTypes.bool
  };

  static defaultProps = {
    floatingLabel: false,
    floatedLabelClassName: ''
  };

  state = {
    floatedLabel: false
  };

  get isLabelFloated() {
    return this.state.floatedLabel || this.props.value;
  }

  handleChange = (e) => {
    const { onChange, isFormik, setFieldError, clearError } = this.props;
    if (isFormik) {
      onChange(e);

      // Reset error for changed field of Formik form
      const { name } = e.target;
      setFieldError([name], false);
      clearError && clearError();
    } else {
      onChange(e.target.value, e);
    }
  };

  handleFocus = (e) => {
    this.setState({ floatedLabel: true });
    const { onFocus } = this.props;
    if (onFocus) onFocus(e);
  };

  handleBlur = (e) => {
    this.setState({ floatedLabel: false });
    const { onBlur } = this.props;
    if (onBlur) onBlur(e);
  };

  handleClear = () => {
    this.props.onChange('');
  };

  render() {
    const {
      borderError,
      error,
      label,
      labelClassName,
      floatedLabelClassName,
      className,
      clearClassName,
      inputClassName,
      floatingLabel,
      value,
      allowClear,
      guide,
      isMask,
      mask,
      placeholder,
      isRequiredBeforeLabel,
      isRequiredAfterLabel,
      setRef,
      autoFocus,
      ...rest
    } = this.props;

    const inputValue = value === null ? '' : value;

    return (
      <div className={CN('relative', className)}>
        {label &&
          <label
            className={
              CN(labelClassName, {
                [css.floated]: this.isLabelFloated,
                [floatedLabelClassName]: this.isLabelFloated,
                [css.floatingLabel]: floatingLabel
              })}
          >
            {isRequiredBeforeLabel && <span className={CN(css.req, css.before)}>*</span>}
            {label}
            {isRequiredAfterLabel && <span className={CN(css.req, css.after)}>*</span>}
          </label>
        }
        <div
          className='relative'
          ref={setRef}
        >
          {isMask ?
            <MaskedInput
              mask={mask}
              className={CN(inputClassName, { inputError: error })}
              {...clearParamsFromObjectByKeys(rest, ['isFormik', 'setFieldError', 'clearError'])}
              value={inputValue}
              placeholder={placeholder}
              guide={guide}
              onChange={this.handleChange}
              onFocus={this.handleFocus}
              onBlur={this.handleBlur}
              autoFocus={autoFocus}
            /> :
            <AntInput
              // className={CN(inputClassName, { inputError: borderError && error })}
              className={CN(inputClassName, { inputError: error })}
              {...clearParamsFromObjectByKeys(rest, ['isFormik', 'setFieldError', 'clearError'])}
              placeholder={placeholder}
              value={inputValue}
              onChange={this.handleChange}
              onFocus={this.handleFocus}
              onBlur={this.handleBlur}
              autoFocus={autoFocus}
            />
          }
          {allowClear && !isEmpty(inputValue) &&
            <Icon type='MdClear' className={CN(clearClassName, css.clear)} onClick={this.handleClear} />
          }
          {!borderError && error &&
            <div className='error'>{error}</div>
          }
        </div>
      </div>
    );
  }
}

export default withStyles(css)(Input);
