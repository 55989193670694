import React from 'react';

const meta = {
  title: 'Work Outs',
  noFollow: true
  // description: 'Welcome to 2020 Lifestyles',
  // keywords: ["weight loss", "nutrition in bellevue"]
}

const page = {
  name: 'Work Outs',
  subTitle: 'Personalized fitness plans to lose weight and stay motivated.'
};

const sections = [
  {
    sectionExtraClassName: 'noPadding',
    // textBeforeTitle: 'another test',
    noMobilePadding: true,
    textBlocks: [
      {
        align: 'left',
        text: (
          <div>
            <p>
              Jumpstart your active lifestyle with the help of 20/20 LifeStyles expert exercise physiologists. Our personalized
              fitness plans are designed to keep you active, starting with the exercises you can handle today.   As you progress
              through the program, lose weight, and build strength, the intensity of the workouts will increase — but don’t worry,
              our team is readily available to support you every step of the way.
            </p>
          </div>
        ),
      }
    ]
  },
];

const blocks = [
  {
    borderColor: 'purple',
    title: 'Stage 1',
    text: 'Let’s get moving with 30-45 minutes of low to moderate intensity exercise 5 or 6 times per week.'
  },
  {
    borderColor: 'green',
    title: 'Stage 2',
    text: 'It’s time to add a few warm-up exercises before cardiovascular exercise. These will help with muscle soreness and to keep muscles loose.'
  },
  {
    borderColor: 'orange',
    title: 'Stage 3',
    text: 'Adding a strength routine stimulates the muscles and provides an additional workout challenge.'
  },
  {
    borderColor: 'red',
    title: 'Stage 4',
    text: 'Learn to maintain workout intensity and add in stretches to perform after a strength training routine.'
  },
  {
    borderColor: 'lime',
    title: 'Stage 5',
    text: 'Add more minutes of cardio to each day — both strength and non-strength days.'
  },
  {
    borderColor: 'blue',
    title: 'Stage 6',
    text: 'Let’s introduce foam rolling, which helps to massage muscles, maintain blood flow, and keep muscles feeling loose.'
  },
  {
    borderColor: 'brown',
    title: 'Stage 7',
    text: 'Maximize interval training to increase heart rate and challenge cardiovascular endurance.'
  }
]

export {
  page,
  sections,
  blocks,
  meta
};
