exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ReferAFriendPage__breadcrumb--14AOE{margin:40px 50px 0px}@media (max-width: 550px){.ReferAFriendPage__breadcrumb--14AOE{margin:40px 20px 0px}}.ReferAFriendPage__pageTitle--11CuL{padding:0 20px}\n", ""]);

// exports
exports.locals = {
	"breadcrumb": "ReferAFriendPage__breadcrumb--14AOE",
	"pageTitle": "ReferAFriendPage__pageTitle--11CuL"
};