exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".TabContent__tabContentWrapper--3ta6_{display:-webkit-box;display:-ms-flexbox;display:flex;-moz-justify-content:center;-ms-justify-content:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center}.TabContent__tabContentWrapper--3ta6_ .TabContent__content--1ZmDf{padding:0px 10px;width:300px}.TabContent__tabContentWrapper--3ta6_ .TabContent__content--1ZmDf .TabContent__timeStamp--3KIRO{color:#333;font-size:12px;font-weight:600;margin-bottom:10px}.TabContent__tabContentWrapper--3ta6_ .TabContent__content--1ZmDf .TabContent__postTitle--ZBavn{color:#3b97b8;font-weight:600;font-size:18px}.TabContent__tabContentWrapper--3ta6_ .TabContent__content--1ZmDf .TabContent__postTitle--ZBavn a:hover{text-decoration:none;color:#0E3E61}.TabContent__tabContentWrapper--3ta6_ .TabContent__content--1ZmDf .TabContent__excerpt--3fCw1{font-size:16px}.TabContent__tabContentWrapper--3ta6_ .TabContent__content--1ZmDf .TabContent__excerpt--3fCw1 a{color:#3b97b8;font-weight:500;font-style:italic;font-size:14px}.TabContent__tabContentWrapper--3ta6_ .TabContent__content--1ZmDf .TabContent__excerpt--3fCw1 a:hover{text-decoration:none;color:#0E3E61}@media (max-width: 840px){.TabContent__tabContentWrapper--3ta6_ .TabContent__content--1ZmDf{width:100%;padding:0px}}@media (max-width: 840px){.TabContent__tabContentWrapper--3ta6_{display:block}}\n", ""]);

// exports
exports.locals = {
	"tabContentWrapper": "TabContent__tabContentWrapper--3ta6_",
	"content": "TabContent__content--1ZmDf",
	"timeStamp": "TabContent__timeStamp--3KIRO",
	"postTitle": "TabContent__postTitle--ZBavn",
	"excerpt": "TabContent__excerpt--3fCw1"
};