import { createTypes } from 'redux-compose-reducer';
import { get } from 'utils/wordPressApiRequester';

export const TYPES = createTypes('seminars', [
  'getSeminars'
]);

export const getSeminars = () => async (dispatch) => {
  try {
    const { data } = await get('wp/v2/seminars');
    await dispatch({ type: TYPES.getSeminars, data });
  } catch (e) {
    console.error(e);
  }
};
