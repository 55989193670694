import React from 'react';
import { routes } from 'routes';

import blogBlockImg from 'assets/images/resources/RESOURCESTOOLS_BLOG.jpg';
import recipesBlockImg from 'assets/images/resources/RESOURCESTOOLS_HEALTHYRECIPES.jpg';
import rewardsBlockImg from 'assets/images/resources/RESOURCESTOOLS_REWARDS.jpg';
import faqBlockImg from 'assets/images/resources/RESOURCESTOOLS_FAQS.jpg';

const meta = {
  title: 'Resources & Tools',
  description:
    'Healthy recipes, educational videos, weight loss tips help support your weight loss goals',
  keywords: ['weight loss', 'nutrition in bellevue']
};

const page = {
  name: 'Weight Loss Resources & Tools',
  subTitle:
    '20/20 LifeStyles will be with you every step of the way so you can lose weight and keep it off. Our online tools provide you more resources to help you achieve success.'
};

const sections = [
  {
    sectionExtraClassName: 'noTopPadding',
    noMobilePadding: true,
    descriptionBlocks: [
      {
        link: {
          to: routes.RESOURCES_AND_TOOLS_RECIPES
        },
        image: {
          src: recipesBlockImg,
          alt: 'Recipes'
        },
        title: 'Recipes',
        text: 'Think healthy food can’t be quick and tasty? Or are you lacking quick meal and snack ideas that fit your healthy lifestyle? With hundreds of healthy recipes approved by our 20/20 LifeStyles Dietitians you are sure to find something delicious and on your plan! 20/20 LifeStyles is the number one place for healthy recipes, cooking tips, and educational videos. For your convenience we also offer step by step home cooking videos with our lead dietitian.'
      },
      {
        link: {
          to: routes.RESOURCES_AND_TOOLS_BLOG
        },
        image: {
          src: blogBlockImg,
          alt: 'Blog'
        },
        title: 'Blog',
        text: 'Our experts love to blog about what is going on in the health industry. In our blogs you will find everything from new exercises targeting your problem area, how to burn more fat, or a quick nutrition tip that could save you hundreds of calories.  Having a hard time with a certain stage of the 20/20 LifeStyles Program? Check out our stage specific blogs to help you stay on track! Or just browse through and learn something new.'
      },
      {
        link: {
          to: routes.RESOURCES_AND_TOOLS_FAQS
        },
        image: {
          src: faqBlockImg,
          alt: 'FAQs'
        },
        title: 'FAQs',
        text: 'Have a question? Please review our FAQs for answers to your weight loss questions.'
      }
    ]
  }
];

export { page, sections, meta };
