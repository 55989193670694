exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".BlogPage__blogWrapper--2dhGe{min-height:500px}.BlogPage__blogWrapper--2dhGe .BlogPage__loader--2kV3W{min-height:100px;display:-webkit-box;display:-ms-flexbox;display:flex;-moz-justify-content:center;-ms-justify-content:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-align-items:center;-moz-align-items:center;-ms-align-items:center;align-items:center}.BlogPage__blogWrapper--2dhGe .BlogPage__loaderIcon--16Xua{height:100px}\n", ""]);

// exports
exports.locals = {
	"blogWrapper": "BlogPage__blogWrapper--2dhGe",
	"loader": "BlogPage__loader--2kV3W",
	"loaderIcon": "BlogPage__loaderIcon--16Xua"
};