import { connect } from 'react-redux';
import {
  getRecipesFilters,
  setRecipesState,
  getAllRecipes,
  getInitialRecipes
} from 'actions/recipes';
import { RecipesPage } from 'components';

const mapStateToProps = ({
  recipes: {
    data,
    recipesState,
    recipesLazyLoading,
    recipesLoading
  }
}) => ({
  ...data,
  recipesState,
  recipesLazyLoading,
  recipesLoading
});

const mapDispatchToProps = {
  setRecipesState,
  getAllRecipes,
  getInitialRecipes,
  getRecipesFilters
};

export default connect(mapStateToProps, mapDispatchToProps)(RecipesPage);
