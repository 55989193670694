import React, { PureComponent, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { Icon, BrowserRequirements } from 'components';
import cx from 'classnames';
import moment from 'moment';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import styles from './Footer.scss';
import { footerLinks, socials } from './links';

class Footer extends PureComponent {
  state = {
    visible: false
  };

  openModal = () => {
    this.setState({ visible: true });
  };
  closeModal = () => {
    this.setState({ visible: false });
  };

  render() {
    const { visible } = this.state;
    const renderLink = link => (
      link.isExternal ? (
        <a href={link.path} rel='noopener noreferrer' target='_blank' className={styles.navlink}>
          {link.name}
        </a>
      ) : (
        <NavLink
          to={link.path}
          className={styles.navlink}
        >
          {link.name}
        </NavLink>
      )
    );
    return (
      <footer className={styles.footer} id='footer'>
        <div className={styles.footerTop}>
          <div className={styles.linksRow}>
            {footerLinks.map((group, index) => (
              <div key={index} className={styles.linksColumn}>
                {group.innerLinks.map(link => (
                  <Fragment key={`link_${link.name}`}>
                    {link ?
                      <Fragment>
                        {link.name === 'browser requirements' ? (
                          <div
                            onClick={this.openModal}
                            className={styles.navlink}
                          >
                            {link.name}
                          </div>) : renderLink(link)
                        }
                      </Fragment>
                      :
                      <div />
                    }
                  </Fragment>
                ))}
              </div>))
            }
            <div className={cx(styles.socials, styles.linksColumn)}>
              {socials.map((item, index) => (
                <a key={index} href={item.path} target='_blank'>
                  <Icon className={styles.socialIcon} type={item.icon} />
                </a>
              ))}
            </div>
          </div>
        </div>
        <div className={styles.copyright}>
          <p className={styles.copyrightText}>
            &copy; {moment().year()} 20/20 LifeStyles
          </p>
        </div>
        <BrowserRequirements visible={visible} closeModal={this.closeModal} />
      </footer>
    );
  }
}

export default withStyles(styles)(Footer);
