import React from 'react';

const meta = {
  title: 'Terms of Service',
  noFollow: true
  // description: 'Welcome to 2020 Lifestyles',
  // keywords: ["weight loss", "nutrition in bellevue"]
}

const page = {
  name: 'Terms of Service',
};

const sections = [
  {
    sectionExtraClassName: 'noTopPadding',
    textBlocks: [
      {
        align: 'left',
        text: (
          <div>
            <p>Last Updated: April 18, 2012</p>

            <p>BY CLICKING "I ACCEPT" OR BY ACCESSING OR USING 20/20 BEWELL-20/20 LIFESTYLES ONLINE, YOU AGREE TO BE BOUND BY THESE TERMS OF SERVICE. YOU FURTHER AGREE THAT THESE TERMS OF SERVICE SUPPLEMENT THE TERMS OF USE THAT APPLY IN CONNECTION WITH YOUR ACCESS TO, AND USE OF, 20/20 LIFESTYLES ONLINE. IF YOU DO NOT AGREE TO THESE TERMS OF SERVICE AND THE TERMS OF USE, DO NOT ACCESS OR USE 20/20 BEWELL-20/20 LIFESTYLES ONLINE.</p>

            <p>These Terms of Service ("Terms") apply solely to your access to and use of the 20/20 BeWell-20/20 LifeStyles Online website and mobile application (collectively, "20/20 BeWell-20/20 LifeStyles Online") offered by Professional Recreation Organization, Inc. (d/b/a PRO Sports Club and 20/20 LifeStyles) (hereinafter referred to as "we" or "us"). These Terms do not alter the terms or conditions of any other agreement you may have with us or our subsidiaries or affiliates for products, services or otherwise. If you use 20/20 BeWell-20/20 LifeStyles Online on behalf of any entity, you represent and warrant that you are authorized to accept these Terms on such entity's behalf and that such entity will be responsible to us if you violate these Terms.</p>

            <p>We reserve the right to change or modify the terms, conditions and notices under which the Sites are offered, at any time and in our sole discretion. Any changes or modifications will be effective immediately upon posting the revisions to the Sites and you waive any right you may have to receive specific notice of such changes or modifications. Your continued use of the Sites following the posting of changes or modifications will confirm your acceptance of such changes or modifications; therefore, you should frequently review these Terms and any applicable policies to understand the terms and conditions that apply to your use of the Sites. If you do not agree to the amended terms, conditions and notices, you must stop using the Sites. Any use of the Sites in violation of these Terms may result in, among other things, termination or suspension of your right to use the Sites.</p>

            <p>We reserve the right to change these Terms at any time and in our sole discretion. Any changes or modifications will be effective immediately upon posting the revisions to 20/20 BeWell-20/20 LifeStyles Online and you waive any right you may have to receive specific notice of such changes or modifications. Your continued use of 20/20 BeWell-20/20 LifeStyles Online following the posting of changes or modifications will confirm your acceptance of such changes or modifications; therefore, you should frequently review these Terms and all other applicable terms, policies and guidelines to understand the terms and conditions that apply to your use of 20/20 BeWell-20/20 LifeStyles Online. If you do not agree to the amended terms, you must stop using 20/20 BeWell-20/20 LifeStyles Online and you should arrange to cancel your account with us. Any use of 20/20 BeWell-20/20 LifeStyles Online in violation of these Terms may result in, among other things, termination or suspension of your right to use 20/20 BeWell-20/20 LifeStyles Online.</p>

            <p>If you have any questions or comments regarding the use of 20/20 BeWell-20/20 LifeStyles Online, please contact us at <a href='mailto:support@2020lifestyle.com'>support@2020lifestyles.com</a>.</p>

            <p>Privacy Policy<br />
            Please refer to our Privacy Policy for more information about how we collect, use and disclose personally identifiable information from our users.</p>

            <p>Eligibility, Registration and Account<br />
            To be eligible to use 20/20 BeWell-20/20 LifeStyles Online, you must be at least 18 years of age and a resident of the United States. You also agree to (a) provide accurate, truthful, current and complete information when registering for a 20/20 BeWell-20/20 LifeStyles Online account; (b) maintain and promptly update your account information; (c) maintain the security of your account by not sharing your password with others and restricting access to your account on your computer and/or mobile device; (d) promptly notify us if you discover or otherwise suspect any security breaches related to 20/20 BeWell-20/20 LifeStyles Online and (e) take responsibility for all activities that occur under your account and accept all risks of unauthorized access.</p>

            <p>Additional Services<br />
            In addition to the standard version of 20/20 BeWell-20/20 LifeStyles Online, you have the ability to purchase add-on services from the 20/20 LifeStyles Store, including Instant Answers and Weekly Insights. These services are subject to these Terms of Service in addition to our Terms of Sale Please review the Terms of Sale carefully, particularly for information regarding subscriptions, renewals and cancellations.</p>

            <p>Charges and Fees<br />
            20/20 BeWell-20/20 LifeStyles Online is currently available for no charge; however, fees will apply to any additional services you purchase, including Instant Answers and Weekly Insights. You are also responsible for all fees and charges associated with connecting to 20/20 BeWell-20/20 LifeStyles Online, including all applicable Internet service provider and mobile carrier fees.</p>

            <p>Termination of Service<br />
            Notwithstanding anything contained in these Terms, we reserve the right, in our sole discretion, to change, suspend, remove, discontinue or disable access to 20/20 BeWell-20/20 LifeStyles Online at any time, with or without notice, and to terminate or block your access to 20/20 BeWell-20/20 LifeStyles Online, or any portion thereof, at any time, for any or no reason.</p>

            <p>Class Action Waiver
            YOU AND PROFESSIONAL RECREATION ORGANIZATION, INC. ("PRO") AGREE THAT ANY PROCEEDINGS TO RESOLVE OR LITIGATE ANY DISPUTE, WHETHER IN ARBITRATION, IN COURT, OR OTHERWISE, WILL BE CONDUCTED SOLELY ON AN INDIVIDUAL BASIS, AND THAT NEITHER YOU NOR PRO WILL SEEK TO HAVE ANY DISPUTE HEARD AS A CLASS ACTION, A REPRESENTATIVE ACTION, A COLLECTIVE ACTION, A PRIVATE ATTORNEY-GENERAL ACTION, OR IN ANY PROCEEDING IN WHICH YOU OR PRO ACTS OR PROPOSES TO ACT IN A REPRESENTATIVE CAPACITY. YOU AND PRO FURTHER AGREE THAT NO ARBITRATION OR PROCEEDING WILL BE JOINED, CONSOLIDATED, OR COMBINED WITH ANOTHER ARBITRATION OR PROCEEDING WITHOUT THE PRIOR WRITTEN CONSENT OF YOU, PRO, AND ALL PARTIES TO ANY SUCH ARBITRATION OR PROCEEDING.</p>

            <p>Liability Disclaimer<br />
            20/20 LIFESTYLES ONLINE AND ANY AND ALL ADD-ON SERVICES SHOULD NOT BE RELIED UPON FOR MEDICAL ADVICE AND YOU SHOULD CONSULT AN APPROPRIATE PROFESSIONAL FOR SPECIFIC ADVICE TAILORED TO YOUR SITUATION. YOU ACKNOWLEDGE THAT YOUR DIET AND EXERCISE ACTIVITIES INVOLVE RISKS, WHICH MAY INCLUDE THE RISK OF BODILY INJURY OR DEATH, AND THAT YOU ASSUME THOSE RISKS. YOU SHOULD CONSULT A LICENSED PHYSICIAN PRIOR TO BEGINNING OR MODIFYING ANY DIET OR EXERCISE PROGRAM THAT YOU UNDERTAKE, AND YOU ACKNOWLEDGE THAT WE HAVE ADVISED YOU OF THE NECESSITY OF OBTAINING SUCH CONSULTATIONS. THE INFORMATION CONTAINED ON 20/20 BEWELL-20/20 LIFESTYLES ONLINE IS FOR EDUCATIONAL AND ENTERTAINMENT PURPOSES ONLY AND IS IN NO WAY INTENDED TO PROVIDE MEDICAL ADVICE. MEDICAL INFORMATION MAY CHANGE RAPIDLY AND WE CANNOT VERIFY THE ACCURACY OR TIMELINESS OF ANY INFORMATION IT PROVIDES. IN ADDITION TO THE OTHER LIMITATIONS AND DISCLAIMERS IN THESE TERMS, WE DISCLAIM ANY AND ALL LIABILITY OR LOSS IN CONNECTION WITH YOUR USE OF 20/20 BEWELL-20/20 LIFESTYLES ONLINE OR ANY DIET, EXERCISE OR OTHER ACTIVITY THAT YOU UNDERTAKE IN CONNECTION WITH YOUR USE OF 20/20 BEWELL-20/20 LIFESTYLES ONLINE.</p>
          </div>
        ),
      },
    ]
  },
];

export {
  page,
  sections,
  meta
};
