import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'antd';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import {
  Breadcrumbs,
  PageTitle,
  MetaData
} from 'components';
import { page, accordions, meta } from '../../mocks/pages/FaqPage';
import styles from './FaqPage.scss';

const { Panel } = Collapse;

class FaqPage extends Component {
  static propTypes = {
    location: PropTypes.object
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const { location } = this.props;
    const newMeta = {
      ...meta,
      path: location.pathname
    }
    return (
      <div>
        <MetaData meta={newMeta} />
        <div className='bodyWrapper noMobilePadding'>
          <Breadcrumbs location={location} />
          <PageTitle page={page} />
          <Collapse ghost className={styles.accordions}>
            {accordions.map((item, index) => (
              <Panel header={item.title} key={index}>
                <p>{item.text}</p>
              </Panel>
            ))}
          </Collapse>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(FaqPage);
