import React from 'react';

const meta = {
  title: 'Schedule a Weight Loss Consultation',
  description: 'Get your questions answered with a private one-on-one consultation to assess your health and weight loss goals',
  keywords: ["weight loss", "nutrition in bellevue"]
}

const page = {
  name: 'schedule a weight loss consultation',
  subTitle: (
    <div>
      <p>They say the first step is the hardest, and you’re on your way. Good work!</p>
      <p>
        Complete the form below to speak with one of our weight loss experts. 
        During your personal consultation, we’ll learn more about you and your 
        weight loss goals and provide you with all the information about our program. 
        We offer private consultations by phone, virtual, or in-person.
      </p>
    </div>
  )
};

export {
  page,
  meta
};
