import React from 'react';

const meta = {
  title: 'Attend a 20/20 Lifestyles Seminar',
  description: 'Attend a 20/20 Lifestyles Seminar',
  keywords: ["weight loss", "nutrition in bellevue"]
}

const page = {
  name: 'Attend a 20/20 Lifestyles Seminar',
  subTitle: (
    <div>
      <p>They say the first step is the hardest, and you’re on your way. Good work!</p>
    </div>
  )
};

export {
  page,
  meta
};
