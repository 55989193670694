import React from 'react';
import IconBase from 'react-icon-base';

export default function BurgerMenuClose(props) {
  return (
    <IconBase viewBox='0 0 22 25' {...props}>
      <defs>
        <path id='4dvfa' d='M12 26 h30 l2 2 H14 z' />
        <path id='4dvfb' d='M12 26 h30 l2 -2 H14 z' />
      </defs>
      <g>
        <g transform=''>
          <g transform='translate(-17.5 -15) rotate(45 28 27.5)'>
            <g>
              <use fill='#3B97B8' xlinkHref='#4dvfa' />
            </g>
          </g>
          <g transform='translate(-15.3 -13.7) rotate(-45 28 27.5)'>
            <g>
              <use fill='#3B97B8' xlinkHref='#4dvfb' />
            </g>
          </g>
        </g>
      </g>
    </IconBase>
  );
}
