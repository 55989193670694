import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import loader from 'assets/images/2020lifestyles-loader.gif';
import { getInitialExperts, getExpertsFilters } from 'actions/experts';
import {
  Breadcrumbs,
  PageTitle,
  MetaData,
  Sections
} from 'components';
import { ListView, Filters } from './components';
import { page, meta, sections } from '../../mocks/pages/ExpertsPage';
import styles from './ExpertsPage.scss';

class ExpertsPage extends PureComponent {
  static propTypes = {
    getInitialExperts: PropTypes.func.isRequired,
    getExpertsFilters: PropTypes.func.isRequired,
    setExpertsState: PropTypes.func.isRequired,
    experts: PropTypes.arrayOf(PropTypes.object),
    location: PropTypes.object,
    expertsTotalLength: PropTypes.number,
    expertsState: PropTypes.object.isRequired,
    expertsLazyLoading: PropTypes.bool,
    titles: PropTypes.object,
    expertsLoading: PropTypes.bool
  };

  static fetching({ dispatch }) {
    return [
      dispatch(getInitialExperts()),
      dispatch(getExpertsFilters())
    ];
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const {
      getInitialExperts,
      getExpertsFilters
    } = this.props;

    getInitialExperts();
    getExpertsFilters();
  }

  render() {
    const {
      experts,
      location,
      expertsTotalLength,
      setExpertsState,
      expertsLazyLoading,
      expertsState,
      titles,
      expertsLoading
    } = this.props;
    const newMeta = {
      ...meta,
      path: location.pathname
    }
    return (
      <div>
        <MetaData meta={newMeta} />
        <div className='bodyWrapper'>
          <Breadcrumbs location={location} />
          <PageTitle page={page} />
          <Sections sections={sections} />
          {titles !== null ?
            <Filters
              expertsState={expertsState}
              titles={titles}
              setExpertsState={setExpertsState}
            /> : null
          }
          {/*  <p className={styles.trainersText}>
            <span>Personal Trainers are not listed below. Please visit our </span>
            <a
              href='https://www.proclub.com/club/personal-training-classes'
              rel='noopener noreferrer'
              target='_blank'
            >
              PRO Trainers
            </a>
            <span> page for the complete list of personal trainers.</span>
          </p> */}
          {experts.length ?
            <ListView
              expertsTotalLength={expertsTotalLength}
              expertsLazyLoading={expertsLazyLoading}
              expertsState={expertsState}
              setExpertsState={setExpertsState}
              experts={experts}
              expertsLoading={expertsLoading}
            /> : null
          }
          {expertsLoading ?
            <div className={styles.loader}>
              <img src={loader} alt='loader' className={styles.loaderIcon} />
            </div> : null
          }
          {!experts.length && !expertsLoading ?
            <div className='notFoundMsg'>
              No match found. Please update your criteria
            </div> : null
          }
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(ExpertsPage);
