import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { Select as AntSelect, Spin } from 'antd';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import CN from 'classnames';
import css from './Select.scss';

class Select extends PureComponent {
  static Option = AntSelect.Option;

  static propTypes = {
    borderError: PropTypes.bool,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    className: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    labelClassName: PropTypes.string,
    mode: PropTypes.string,
    // selectAll: PropTypes.bool,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.array
    ]),
    onChange: PropTypes.func,
    loading: PropTypes.bool,
    unblockedLoading: PropTypes.bool,
    icon: PropTypes.string,
    iconClassName: PropTypes.string,
    children: PropTypes.node,
    optionFilterProp: PropTypes.string,
    defaultVal: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    isFormik: PropTypes.bool,
    setFieldError: PropTypes.func,
    setFieldValue: PropTypes.func,
    placeholder: PropTypes.string,
    name: PropTypes.string
  };

  static defaultProps = {
    optionFilterProp: 'children',
    loading: false,
    unblockedLoading: false
    // selectAll: true
  };

  onChange = (e) => {
    const { isFormik, onChange, name, setFieldValue, setFieldError } = this.props;

    if (isFormik) {
      setFieldValue(name, e);
      setFieldError(name, false);
    } else {
      onChange(e);
    }
  };

  getInput() {
    // eslint-disable-next-line react/no-find-dom-node
    return ReactDOM.findDOMNode(this.select).querySelector('input');
  }

  render() {
    // eslint-disable-next-line no-unused-vars
    const {
      error,
      className,
      label,
      labelClassName,
      loading,
      unblockedLoading,
      borderError,
      defaultVal,
      value,
      placeholder,
      setFieldValue,
      setFieldError,
      isFormik,
      ...rest
    } = this.props;
    const dropdownAlign = { offset: [0, 0] };
    const selectValue = value || defaultVal;

    const select = (
      <AntSelect
        key='select'
        className={CN(css.select, { selectError: error })}
        optionLabelProp='children'
        placeholder={placeholder}
        value={selectValue}
        defaultValue={defaultVal}
        dropdownAlign={dropdownAlign}
        dropdownClassName={css.list}
        onChange={this.onChange}
        ref={(n) => { this.refAntSelect = n; }}
        {...rest}
      />
    );

    return (
      <div className={className}>
        {label &&
          <label className={labelClassName}>{label}</label>
        }
        <div className='relative layout horizontal center'>
          {unblockedLoading
            ? [select, <Spin key='spinner' className={css.spinner} spinning={loading} />]
            : <Spin wrapperClassName='block full-width' spinning={loading}>{select}</Spin>
          }
        </div>
        {!borderError && error &&
          <div className='error'>{error}</div>
        }
      </div>
    );
  }
}

export default withStyles(css)(Select);
