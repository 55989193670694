import React from 'react';
import PropTypes from 'prop-types';
import loader from 'assets/images/2020lifestyles-loader.gif';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import styles from './Loader.scss';

const Loader = ({ text, hideOverlay }) => (
  <div className={cx(styles.overlayLoader, 'loaderFiles', { [styles.hideOverlay]: hideOverlay })}>
    <img src={loader} alt='loader' className={styles.loaderIcon} />
    {text ? <div className={styles.title}>{text}</div> : <div />}
  </div>
);

Loader.defaultProps = {
  hideOverlay: false
};

Loader.propTypes = {
  text: PropTypes.string,
  hideOverlay: PropTypes.bool
};

export default withStyles(styles)(Loader);
