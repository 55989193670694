import { combineReducers } from 'redux';
import successStories from './successStories';
import experts from './experts';
import posts from './posts';
import recipes from './recipes';
import seminars from './seminars';

const rootReducer = combineReducers({
  successStories,
  experts,
  posts,
  recipes,
  seminars
});

export default rootReducer;
