exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ant-modal-close{display:none !important}.Modal__close--rrHr_{cursor:pointer;color:#fff;position:absolute;right:10px;top:10px;background-color:transparent;border:none}.Modal__close--rrHr_ .Modal__mark--RKxFo{width:32px;height:32px;background:transparent;font-size:32px;line-height:32px}\n", ""]);

// exports
exports.locals = {
	"close": "Modal__close--rrHr_",
	"mark": "Modal__mark--RKxFo"
};