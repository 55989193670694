import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import ReactPlayer from 'react-player';
import BrightcovePlayer from '@brightcove/react-player-loader';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { Modal } from 'components';
import styles from './SuccessStoryCard.scss';

const overlayStyles = {
  backgroundColor: 'rgba(0, 0, 0, 0.75)'
};

class SuccessStoryCard extends Component {
  state = {
    visibleStory: false
  };

  openStoryModal = () => {
    this.setState({
      visibleStory: true
    });
  };
  closeStoryModal = () => {
    this.setState({
      visibleStory: false
    });
  };

  render() {
    const { visibleStory } = this.state;
    const {
      successStory
    } = this.props;
    return (
      <Fragment>
        <div className={styles.card}>
          <img
            className={styles.thumbImg}
            src={successStory.featured_image_medium}
            alt={parse(successStory.title.rendered)}
          />
          <div className={styles.content}>
            <h3 className={styles.title}>{parse(successStory.title.rendered)}</h3>
            <h4 className={styles.subTitle}>Lost {successStory.ACF.pounds_loss} pounds</h4>
            <button className={styles.button} onClick={() => this.openStoryModal()}>View Story</button>
          </div>
        </div>
        {visibleStory ?
          <Modal
            visible={visibleStory}
            closeModal={this.closeStoryModal}
            wrapClassName={styles.modalWrapper}
            maskStyle={overlayStyles}
            width={750}
            className={styles.successStoryModal}
          >
            <div className={styles.textWrapper}>
              <div className={styles.storyMedia}>
                {successStory.ACF.video ?
                  <div className={styles.videoFrameWrp}>
                    {successStory.ACF.video.indexOf('youtube') > -1 ?
                      <ReactPlayer
                        url={successStory.ACF.video}
                        className={styles.video}
                        controls
                      /> : null
                    }
                    {successStory.ACF.video.indexOf('brightcove') > -1 ?
                      <div className={styles.brightcovePlayer}>
                        <BrightcovePlayer
                          accountId='1257553580001'
                          videoId={successStory.ACF.video.split('?videoId=')[1]}
                        />
                      </div>: null
                    }
                  </div> :
                  <img
                    className={styles.img}
                    src={successStory.featured_image_full}
                    alt={parse(successStory.title.rendered)}
                  />
                }
              </div>
              <h2 className={styles.storyName}>{parse(successStory.title.rendered)}</h2>
              <div className={styles.storyMetaData}>
                {successStory.ACF.age ?
                  <p>Age: {successStory.ACF.age}</p> : null
                }
                {successStory.ACF.pounds_loss ?
                  <p>Lost: {successStory.ACF.pounds_loss} pounds</p> : null
                }
                {successStory.ACF.diet[0] !== 'Not Specified' ?
                  <p>Diet: {successStory.ACF.diet}</p> : null
                }
              </div>
              <div className={cx(styles.description)}>
                <div>{parse(successStory.content.rendered)}</div>
              </div>
            </div>
          </Modal> : <div />
        }
      </Fragment>
    );
  }
}

SuccessStoryCard.propTypes = {
  successStory: PropTypes.object
};

export default withStyles(styles)(SuccessStoryCard);
