import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { HashLink as Link } from 'react-router-hash-link';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import styles from './Button.scss';

class LandingButton extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
    prefix: PropTypes.node,
    suffix: PropTypes.node,
    to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    isExternal: PropTypes.bool,
    type: PropTypes.string,
    htmlType: PropTypes.string,
    isHref: PropTypes.bool,
    style: PropTypes.object,
    smooth: PropTypes.bool,
    download: PropTypes.bool,
    customAnchorName: PropTypes.string
  };

  render() {
    const { isHref, className, children, prefix, suffix, isExternal, to, type,
      htmlType, style, smooth, download, customAnchorName, ...rest } = this.props;
    const classType = type;

    if (to) {
      if (isExternal) {
        return (
          <a
            href={to}
            target='_blank'
            className={cx(styles.link, styles[classType], className)}
            style={style}
            id={customAnchorName}
          >
            { children }
          </a>);
      }
      if (isHref) {
        return (
          <a
            href={to}
            className={cx(styles.link, styles[classType], className)}
            download={download}
            id={customAnchorName}
          >
            { children }
          </a>);
      }
      return (
        <Link
          to={to}
          smooth={smooth}
          id={customAnchorName}
          className={cx(styles.link, styles[classType], className)}
        >
          { children }
        </Link>
      );
    }

    return (
      <button className={cx(styles.button, styles[classType], className)} type={htmlType} {...rest} id={customAnchorName}>
        { prefix ? <div className={cx(styles.prefix, 'prefix')}>{prefix}</div> : <div /> }
        { children || <div /> }
        { suffix ? <div className={cx(styles.suffix, 'suffix')}>{suffix}</div> : <div /> }
      </button>
    );
  }
}

export default withStyles(styles)(LandingButton);
