exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Filters__filters--WBMXE{display:-webkit-box;display:-ms-flexbox;display:flex;-moz-justify-content:center;-ms-justify-content:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;margin-bottom:40px}@media (max-width: 500px){.Filters__filters--WBMXE{flex-direction:column;-webkit-align-items:center;-moz-align-items:center;-ms-align-items:center;align-items:center}}\n", ""]);

// exports
exports.locals = {
	"filters": "Filters__filters--WBMXE"
};