import React from 'react';

const noMarginBottom = { marginBottom: '0px' };

const meta = {
  title: 'Scientific Health Monitoring'
};

const page = {
  name: 'Scientific Health Monitoring',
  subTitle:
    'Personalizing your 20/20 LifeStyles program around your body’s unique chemistry.'
};

const sections = [
  {
    sectionExtraClassName: 'noTopPadding',
    textBlocks: [
      {
        align: 'left',
        text: (
          <div>
            <p>
              Understanding how your body works is key to long-term success.
              Throughout your 20/20 program, and during your 12-week maintenance
              plan, our physicians will conduct a series of screenings and tests
              to continually monitor your progress. These ongoing assessments
              will allow them to adjust your program as necessary and help you
              maintain your metabolism.
            </p>
            <p style={noMarginBottom}>Monitoring Includes:</p>
            <ul>
              <li>Complete blood tests every 12 weeks during your program</li>
              <li>DXA Scan - measures body fat and bone density</li>
              <li>
                Resting Metabolic Rate – helps determine your precise
                caloric-intake needs
              </li>
              <li>
                VO2 Max – aids in determining your maximum heart rate needed for
                exercise to burn fat and protect muscle
              </li>
              <li>Sleep apnea screening</li>
            </ul>
          </div>
        )
      }
    ]
  }
];

export { page, sections, meta };
