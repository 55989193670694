/* eslint no-underscore-dangle: 0 */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import cx from 'classnames';
import { Redirect } from 'react-router';
import {
  FacebookShareButton,
  TwitterShareButton,
  PinterestShareButton,
  EmailShareButton,
  FacebookIcon,
  TwitterIcon,
  PinterestIcon,
  EmailIcon
} from 'react-share';
import ReactToPrint from 'react-to-print';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { routes } from 'routes';
import loader from 'assets/images/2020lifestyles-loader.gif';
import { Icon, Breadcrumbs, MetaData } from 'components';
import { RelatedRecipesSlider } from './components';

import styles from './RecipeTemplate.scss';

class RecipeTemplate extends Component {
  static propTypes = {
    location: PropTypes.object,
    recipe: PropTypes.object,
    relatedRecipes: PropTypes.arrayOf(PropTypes.object),
    getRecipe: PropTypes.func.isRequired,
    recipesLoading: PropTypes.bool
  };
  state = {
    recipeLoaded: true
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.setState({ recipeLoaded: false });
    setTimeout(() => {
      this.setState({ recipeLoaded: true });
    }, 700);
    this.renderRecipes();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location !== this.props.location) {
      window.scrollTo(0, 0);
      this.setState({ recipeLoaded: false });
      setTimeout(() => {
        this.setState({ recipeLoaded: true });
      }, 700);
      this.renderRecipes();
    }
    // console.log(this.state.recipeLoaded);
  }

  shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      const temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
  }

  renderRecipes = () => {
    const {
      getRecipe,
      location
    } = this.props;
    const path = location.pathname.split('/');
    if (path[path.length - 1] === '') {
      path.pop();
    }
    const slug = path[path.length - 1];
    getRecipe(slug);
  }

  render() {
    const {
      location,
      recipe,
      relatedRecipes,
      recipesLoading
    } = this.props;
    let filteredRecipes;
    if (recipe === undefined) {
      return <Redirect to={routes.RESOURCES_AND_TOOLS_RECIPES} />;
    }
    if (relatedRecipes.length > 1 && recipe !== null) {
      const newArray = relatedRecipes.filter(item => item.id !== recipe.id);
      this.shuffleArray(newArray);
      filteredRecipes = newArray.slice(0, 4);
    }

    const domain = window.location.origin;
    
    return (
      <div className={cx(styles.recipePage, { [styles.loaded]: this.state.recipeLoaded })}>
        {recipe !== null ?
          <Fragment>
            <MetaData
              title={parse(recipe.title.rendered)}
              meta={{ path: location.pathname }}
              recipeLdJson={
                {
                  '@context': 'https://schema.org',
                  '@type': 'Recipe',
                  'cookTime': recipe.ACF.cook_time,
                  'image': recipe.featured_image_full,
                  'name': parse(recipe.title.rendered),
                  'prepTime': recipe.ACF.prep_time
                }
              }
            />
            <div className='wrapper'>
              <div className={styles.breadcrumb}>
                <Breadcrumbs location={location} customName={parse(recipe.title.rendered)} />
              </div>
              <div className={styles.recipeWrapper}>
                <h1 className={styles.title}>{parse(recipe.title.rendered)}</h1>
                <div className={styles.meta}>
                  {recipe.ACF.time_of_day.map((time, index) => (
                    <div className={styles.acf} key={index}>
                      <p>
                        {time}
                      </p>
                      <span>|</span>
                    </div>
                  ))}
                  {recipe.ACF.dish.map((item, index) => (
                    <div className={styles.acf} key={index}>
                      <p>
                        {item}
                      </p>
                      <span>|</span>
                    </div>
                  ))}
                  <div className={styles.acf}>
                    <p>
                      {recipe.ACF.difficulty}
                    </p>
                    <span>|</span>
                  </div>
                  <div className={styles.acf}>
                    <p>
                      Stage {recipe.ACF.stage}
                    </p>
                  </div>
                </div>
                <div className={styles.socialsShare}>
                  <p>Share:</p>
                  <div className={styles.socialsButtons}>
                    <FacebookShareButton
                      url={`${domain}${routes.RESOURCES_AND_TOOLS_RECIPES}/${recipe.slug}`}
                      quote={parse(recipe.title.rendered)}
                      className={styles.socialButton}
                    >
                      <FacebookIcon size={36} />
                    </FacebookShareButton>
                    <TwitterShareButton
                      url={`${domain}${routes.RESOURCES_AND_TOOLS_RECIPES}/${recipe.slug}`}
                      quote={parse(recipe.title.rendered)}
                      className={styles.socialButton}
                    >
                      <TwitterIcon size={36} />
                    </TwitterShareButton>
                    <PinterestShareButton
                      url={`${domain}${routes.RESOURCES_AND_TOOLS_RECIPES}/${recipe.slug}`}
                      media={recipe.featured_image_full}
                      quote={parse(recipe.title.rendered)}
                      className={styles.socialButton}
                    >
                      <PinterestIcon size={36} />
                    </PinterestShareButton>
                    <EmailShareButton
                      url={`${domain}${routes.RESOURCES_AND_TOOLS_RECIPES}/${recipe.slug}`}
                      quote={parse(recipe.title.rendered)}
                      className={styles.socialButton}
                    >
                      <EmailIcon size={36} />
                    </EmailShareButton>
                    <ReactToPrint
                      trigger={() => (
                        <button className={styles.printButton}><Icon color='#fff' type='Print' /></button>
                      )}
                      content={() => this.printRef}
                    />
                  </div>
                </div>
                {recipe.ACF.prep_time && recipe.ACF.cook_time && recipe.ACF.total_time ?
                  <div className={styles.timeTable}>
                    <div className={styles.column}>
                      <span className={styles.name}>Prep Time:</span>
                      <span className={styles.value}>{recipe.ACF.prep_time}</span>
                    </div>
                    <div className={styles.column}>
                      <span className={styles.name}>Cook Time:</span>
                      <span className={styles.value}>{recipe.ACF.cook_time}</span>
                    </div>
                    <div className={styles.column}>
                      <span className={styles.name}>Total Time:</span>
                      <span className={styles.value}>{recipe.ACF.total_time}</span>
                    </div>
                  </div> : null
                }
                <div className={cx(styles.imgSection, { [styles.loaded]: this.state.recipeLoaded })}>
                  {recipe.featured_image ?
                    <img src={recipe.featured_image_full} alt={parse(recipe.title.rendered)} /> : null
                  }
                </div>
                <div className={styles.nutrition}>
                  <h2 className={styles.heading}>Nutrition Information</h2>
                  <div className={styles.items}>
                    {recipe.ACF.serving_size ?
                      <p>Serving Size: {parse(recipe.ACF.serving_size)};</p> : null
                    }
                    {recipe.ACF.recipe_yield ?
                      <p>Recipe Yield: {parse(recipe.ACF.recipe_yield)};</p> : null
                    }
                    {recipe.ACF.calories ?
                      <p>Calories: {parse(recipe.ACF.calories)};</p> : null
                    }
                    {recipe.ACF.carbohydrates ?
                      <p>Carbohydrates: {parse(recipe.ACF.carbohydrates)};</p> : null
                    }
                    {recipe.ACF.fat ?
                      <p>Fat: {parse(recipe.ACF.fat)};</p> : null
                    }
                    {recipe.ACF.protein ?
                      <p>Protein: {parse(recipe.ACF.protein)}.</p> : null
                    }
                  </div>
                </div>
                <div className={styles.ingredients}>
                  <h2 className={styles.heading}>Ingredients</h2>
                  {parse(recipe.ACF.ingredients)}
                </div>
                <div className={styles.instructions}>
                  <h2 className={styles.heading}>Instructions</h2>
                  {parse(recipe.ACF.instructions)}
                </div>
              </div>
            </div>
            <div
              className={styles.toPrint}
              ref={(el) => { this.printRef = el; }}
              style={{ padding: '70px 50px' }}
            >
              <h1
                style={{ fontSize: '30px', fontWeight: 'bold', textAlign: 'center' }}
              >
                {parse(recipe.title.rendered)}
              </h1>
              <div style={{ display: 'flex', flexWrap: 'wrap', fontSize: '16px' }}>
                {recipe.ACF.time_of_day.map((time, index) => (
                  <span key={index} style={{ marginRight: '5px' }}>
                    {time},
                  </span>
                ))}
                {recipe.ACF.dish.map((item, index) => (
                  <span key={index} style={{ marginRight: '5px' }}>
                    {item},
                  </span>
                ))}
                <span style={{ marginRight: '5px' }}>
                  {recipe.ACF.difficulty},
                </span>
                <span>
                  Stage {recipe.ACF.stage}
                </span>
              </div>
              {recipe.ACF.prep_time && recipe.ACF.cook_time && recipe.ACF.total_time ?
                <div style={{ display: 'flex', flexWrap: 'wrap', marginBottom: '20px', fontSize: '16px' }}>
                  <span style={{ marginRight: '5px' }}>Prep Time: {recipe.ACF.prep_time};</span>
                  <span style={{ marginRight: '5px' }}>Cook Time: {recipe.ACF.cook_time};</span>
                  <span>Total Time: {recipe.ACF.total_time};</span>
                </div> : null
              }
              <div>
                <h2 style={{ fontSize: '20px', fontWeight: '700', textTransform: 'uppercase' }}>Nutrition Information</h2>
                <div style={{ display: 'flex', flexWrap: 'wrap', fontSize: '16px' }}>
                  {recipe.ACF.serving_size ?
                    <p style={{ marginRight: '5px' }}>Serving Size: {parse(recipe.ACF.serving_size)};</p> : null
                  }
                  {recipe.ACF.recipe_yield ?
                    <p style={{ marginRight: '5px' }}>Recipe Yield: {parse(recipe.ACF.recipe_yield)};</p> : null
                  }
                  {recipe.ACF.calories ?
                    <p style={{ marginRight: '5px' }}>Calories: {parse(recipe.ACF.calories)};</p> : null
                  }
                  {recipe.ACF.carbohydrates ?
                    <p style={{ marginRight: '5px' }}>Carbohydrates: {parse(recipe.ACF.carbohydrates)};</p> : null
                  }
                  {recipe.ACF.fat ?
                    <p style={{ marginRight: '5px' }}>Fat: {parse(recipe.ACF.fat)};</p> : null
                  }
                  {recipe.ACF.protein ?
                    <p style={{ marginRight: '5px' }}>Protein: {parse(recipe.ACF.protein)}.</p> : null
                  }
                </div>
              </div>
              <div>
                <h2 style={{ fontSize: '20px', fontWeight: '700', textTransform: 'uppercase' }}>Ingredients</h2>
                <div style={{ fontSize: '16px' }}>{parse(recipe.ACF.ingredients)}</div>
              </div>
              <div>
                <h2 style={{ fontSize: '20px', fontWeight: '700', textTransform: 'uppercase' }}>Instructions</h2>
                <div style={{ fontSize: '16px' }}>{parse(recipe.ACF.instructions)}</div>
              </div>
            </div>
            {relatedRecipes.length > 1 && filteredRecipes !== null ?
              <div className={styles.slider}>
                <h2>More Recipes From Stage {recipe.ACF.stage}</h2>
                <RelatedRecipesSlider relatedRecipes={filteredRecipes} />
              </div> : null
            }
          </Fragment>
          : <div />
        }
        {recipesLoading ?
          <div className={styles.loader}>
            <img src={loader} alt='loader' className={styles.loaderIcon} />
          </div> : null
        }
      </div>
    );
  }
}

export default withStyles(styles)(RecipeTemplate);
