import React, { PureComponent, Fragment } from 'react';
import ReCAPTCHA from "react-google-recaptcha"
import axios from 'axios';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';
import moment from 'moment';
import * as Yup from 'yup';
import { Form, Formik, Field } from 'formik';
import { phoneMask, phoneSchema, zipSchema } from 'utils/validationSchemas';
import { Modal, Input } from 'components';
import style from './ConsultationForm.scss';

const overlayStyles = {
  backgroundColor: 'rgba(0, 0, 0, 0.75)'
};

const schema = Yup.object().shape({
  first_name: Yup.string().required('Required'),
  last_name: Yup.string().required('Required'),
  phone: phoneSchema.required('Required'),
  email: Yup.string().email('Invalid email address').required('Required'),
  city: Yup.string().required('Required'),
  zip: zipSchema.required('Required')
});

const initialValues = {
  first_name: '',
  last_name: '',
  phone: '',
  email: '',
  city: '',
  zip: ''
};

class ConsultationForm extends PureComponent {
  state = {
    preferredDay: moment(),
    submissionPopupIsOpen: false,
    submissionMessage: '',
    captchaError: '',
    captchaValue: null
  };

  captchaRef = React.createRef();

  handleCaptchaChange = value => {
    this.setState({ captchaValue: value })
  };

  onSubmit = (values, { resetForm }) => {
    if (this.state.captchaValue !== null) {
      const captchaData = {
        secret: process.env.CAPTCHA_SECRET_KEY,
        token: this.state.captchaValue
      }

      axios({
        method: 'POST',
        url: '/verify-token',
        data: captchaData
      }).then((response) => {
        if (response.data.success) {
          axios({
            method: 'POST',
            url: '/send',
            data: values
          }).then((response) => {
            if (response.data === 'Success') {
              resetForm();
              this.setState({ submissionMessage: 'success' });
              this.openSubmissionPopup();
              this.captchaRef.current.reset();
            } else if (response.data === 'Fail') {
              this.setState({ submissionMessage: 'fail' });
              this.openSubmissionPopup();
            }
          });
        } else {
          this.setState({ captchaError: 'Sorry! Something went wrong. Please try again' });
        }
      });
    } else {
      this.setState({ captchaError: 'Sorry! Verify you are not a bot' });
    }
  };

  openSubmissionPopup = () => {
    this.setState({ submissionPopupIsOpen: true });
  };

  closeSubmissionPopup = () => {
    this.setState({ submissionPopupIsOpen: false });
  };

  render() {
    const { submissionPopupIsOpen } = this.state;
    return (
      <Fragment>
        <div
          id='consultation-form'
          className={style.ConsultationFormMain}
        >
          <div className={style.ConsultationFormContent}>
            <Formik
              enableReinitialize
              initialValues={initialValues}
              validationSchema={schema}
              validateOnChange={false}
              validateOnBlur={false}
              onSubmit={this.onSubmit}
            >
              {({
                values,
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldError,
                setFieldValue
              }) => (
                <Form onSubmit={handleSubmit} className={style.form}>
                  <div className={style.fieldsGroup}>
                    <Input
                      id='first_name'
                      type='text'
                      name='first_name'
                      label='first name'
                      value={values.first_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputClassName={style.input}
                      className={style.inputWrapper}
                      labelClassName={style.label}
                      error={errors.first_name}
                      setFieldError={setFieldError}
                      isFormik
                    />
                    <Input
                      id='last_name'
                      type='text'
                      name='last_name'
                      label='last name'
                      value={values.last_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputClassName={style.input}
                      className={style.inputWrapper}
                      labelClassName={style.label}
                      error={errors.last_name}
                      setFieldError={setFieldError}
                      isFormik
                    />
                    <Input
                      id='phone'
                      type='text'
                      name='phone'
                      label='phone number'
                      value={values.phone}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputClassName={cx('ant-input', style.input)}
                      className={style.inputWrapper}
                      labelClassName={style.label}
                      error={errors.phone}
                      setFieldError={setFieldError}
                      isFormik
                      isMask
                      mask={phoneMask}
                    />
                    <Input
                      id='email'
                      type='email'
                      name='email'
                      label='email'
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputClassName={style.input}
                      className={style.inputWrapper}
                      labelClassName={style.label}
                      error={errors.email}
                      setFieldError={setFieldError}
                      isFormik
                    />
                    <div className={style.address}>
                        <Input
                          id='city'
                          type='text'
                          name='city'
                          label='city'
                          value={values.city}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          inputClassName={style.input}
                          className={cx(style.inputWrapper, style.city)}
                          labelClassName={style.label}
                          error={errors.city}
                          setFieldError={setFieldError}
                          isFormik
                        />
                        <Input
                          id='zip'
                          type='text'
                          name='zip'
                          label='zip code'
                          value={values.zip}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          inputClassName={style.input}
                          className={cx(style.inputWrapper, style.zip)}
                          labelClassName={style.label}
                          error={errors.zip}
                          setFieldError={setFieldError}
                          isFormik
                        />
                        <ReCAPTCHA 
                          className={style.captcha} 
                          sitekey={process.env.CAPTCHA_SITE_KEY} 
                          ref={this.captchaRef}  
                          onChange={this.handleCaptchaChange} />
                        {this.state.captchaError ? <p className={style.captchaError}>{this.state.captchaError}</p> : <div />}
                    </div>
                  </div>
                  <button
                    type='submit'
                    className={style.Button}
                  >
                    Submit
                  </button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
        <Modal
          visible={submissionPopupIsOpen}
          closeModal={this.closeSubmissionPopup}
          wrapClassName={style.modalWrapper}
          maskStyle={overlayStyles}
          width={707}
        >
          <div className={style.textWrapper}>
            {this.state.submissionMessage === 'success' ?
              <div>
                <h2>THANK YOU</h2>
                <p>Thank you for your interest in the 20/20 LifeStyles program.</p>
                <p>One of our Program Coordinators will contact you soon.</p>
              </div> : <div />
            }
            {this.state.submissionMessage === 'fail' ?
              <div>
                <h2>SORRY</h2>
                <p>Your request was not successfully submitted. Please try again.</p>
                <p>If you continue to experience errors, please contact our program directly
                  at <a href='mailto:support@2020lifestyles.com'>support@2020lifestyles.com</a> or call us at 425.861.6258
                </p>
              </div> : <div />
            }
          </div>
        </Modal>
      </Fragment>
    );
  }
}

export default withStyles(style)(ConsultationForm);
