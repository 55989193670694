import React from 'react';

import phoneTracker from 'assets/images/TrackerPage/2020trackerwinphone.png';
import pcTracker from 'assets/images/TrackerPage/2020pctracker.png';
import iphone from 'assets/images/TrackerPage/iphdownload.png';
import android from 'assets/images/TrackerPage/droidownload.png';

const meta = {
  title: 'Tracker',
  noFollow: true
  // description: 'Welcome to 2020 Lifestyles',
  // keywords: ["weight loss", "nutrition in bellevue"]
}

const page = {
  name: 'tracker',
  subTitle: 'If you can track it, you can improve it.'
};

const sections = [
  {
    sectionExtraClassName: 'noTopPadding',
    // textBeforeTitle: 'another test',
    noMobilePadding: true,
    textBlocks: [
      {
        align: 'left',
        text: (
          <div>
            <p>Get started with 20/20 LifeStyles Online today—it’s an innovative personal health coach
            that helps you achieve the weight loss results you desire. The tracker is available
            <a href='http://account.2020lifestyles.com/login' target='_blank'>online</a> and on <a href='http://itunes.apple.com/us/app/20-20-lifestyles-online/id534455056?mt=8' target='_blank'>iPhone</a>
            , <a href='http://itunes.apple.com/us/app/20-20-lifestyles-online/id534455056?mt=8' target='_blank'>iPad</a> and <a href='https://play.google.com/store/apps/details?id=com.ProClub.BeWell.AndroidTracker&feature=search_result' target='_blank'>Android</a>.</p>
            <p>The tracker is FREE and allows you to:</p>
            <ul>
              <li>Track daily diet and exercise</li>
              <li>Monitor weight, sleep, mood and more</li>
              <li>Connect with real experts</li>
            </ul>
          </div>
        ),
        singleVideo: {
          youtube: 'https://www.youtube.com/watch?v=aP7jlzTWKio',
          alt: '2020 Lifestyles Tracker Tutorial'
        }
      },
      {
        title: 'Track your diet and exercise daily',
        align: 'left',
        text: (
          <div>
            <p>
              Did you know that if you track what you eat on a weight loss program, you’ll double your chances of
              success? That’s why the Health Tracker is a key feature of 20/20 LifeStyles.
            </p>
            <p>
              Begin by registering at 2020Lifestyles.com. The Health Tracker is free, and you can use it on the web
              , or as an app on your phone or tablet device. After you enter some basic information, including age and
              weight, you’re ready to track your daily habits. Start by recording all the foods you eat for meals and snacks.
              Many popular foods and restaurant dishes are already in our database. You can also add your favorite foods
              and meals to make it quicker to record each time.
            </p>
            <p>
              Enter your cardio and strength training workouts to measure the calories you burn. You can even link
              a pedometer and track the number of steps you take every day.Other weight-loss factors, including the
              amount of sleep you get and how much water you drink daily, are also measured.
            </p>
            <p>
              Using the Health Tracker, you’ll also see the progress you’re making on the dashboard in real time. The Health
              Tracker is easy to use. And when you incorporate it
              into your lifestyle every day, you’ll succeed in transforming your life.
            </p>
            <div style={{ textAlign: 'center' }}>
              <img style={{ padding: '0px 20px' }} src={phoneTracker} alt='2020 Lifestyles Tracker app on mobile phones' />
              <img style={{ padding: '0px 20px' }} src={pcTracker} alt='2020 Lifestyles Tracker app on computers and laptops' />
            </div>
          </div>
        ),
        // singleVideo: {
        //   brightcove: '6248896248001',
        //   alt: '2020 Lifestyles Tracker Tutorial'
        // }
      },
      {
        title: 'Download Now!',
        align: 'left',
        text: (
          <div>
            <div style={{ marginBottom: '30px' }}>
              <p>Download for iPhone</p>
              <a href='https://web.archive.org/web/20201025082133/http://itunes.apple.com/us/app/20-20-lifestyles-online/id534455056?mt=8' target='_blank'><img src={iphone} alt='2020 Lifestyles Tracker iphone download icon' /></a>
            </div>
          </div>
        )
      }
    ]
  },
];

export {
  page,
  sections,
  meta
};
