exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".MealPlansPage__accordions--8jDjI{max-width:1200px;margin:0 auto;padding:0px 50px}.MealPlansPage__accordions--8jDjI .ant-collapse-header{padding:0px 0px 0px 20px !important;font-size:20px;font-weight:500}.MealPlansPage__accordions--8jDjI .ant-collapse-arrow{top:-4px !important;left:0px !important}.MealPlansPage__accordions--8jDjI .ant-collapse-arrow svg{color:#3b97b8}.MealPlansPage__accordions--8jDjI .ant-collapse-arrow svg path{stroke-width:10%;stroke:#3b97b8}.MealPlansPage__accordions--8jDjI .ant-collapse-content-box{padding-top:10px !important;padding-bottom:0px !important;padding-left:20px;font-size:16px}.MealPlansPage__accordions--8jDjI .ant-collapse-content-box p ol{padding-left:10px}.MealPlansPage__accordions--8jDjI .ant-collapse-item{margin-bottom:30px}@media (max-width: 767px){.MealPlansPage__accordions--8jDjI{padding:0px}}\n", ""]);

// exports
exports.locals = {
	"accordions": "MealPlansPage__accordions--8jDjI"
};