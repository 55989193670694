exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".WidgetTabs__tabsWrapper--2pL-A .ant-tabs-nav-wrap{display:-webkit-box;display:-ms-flexbox;display:flex;-moz-justify-content:center;-ms-justify-content:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center}.WidgetTabs__tabsWrapper--2pL-A .ant-tabs-ink-bar{background:#3b97b8}@media (max-width: 435px){.WidgetTabs__tabsWrapper--2pL-A .ant-tabs-ink-bar{display:none}}.WidgetTabs__tabsWrapper--2pL-A .ant-tabs-tab-btn{font-size:16px;color:#000;font-weight:700;text-transform:uppercase}.WidgetTabs__tabsWrapper--2pL-A .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{color:#3b97b8;font-size:16px;font-weight:700;text-transform:uppercase}@media (max-width: 435px){.WidgetTabs__tabsWrapper--2pL-A .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{color:#fff}}.WidgetTabs__tabsWrapper--2pL-A .ant-tabs-nav{margin-bottom:30px}.WidgetTabs__tabsWrapper--2pL-A .ant-tabs-nav::before{border-bottom:0px}.WidgetTabs__tabsWrapper--2pL-A .ant-tabs-tab{padding-bottom:5px;margin:0 15px;font-size:16px}@media (max-width: 435px){.WidgetTabs__tabsWrapper--2pL-A .ant-tabs-tab{padding:7px;width:100%;text-align:center;margin-bottom:5px;display:block;background-color:#eee}}.WidgetTabs__tabsWrapper--2pL-A .ant-tabs-nav-operations-hidden,.WidgetTabs__tabsWrapper--2pL-A .ant-tabs-nav-more,.WidgetTabs__tabsWrapper--2pL-A .ant-tabs-nav-wrap::before,.WidgetTabs__tabsWrapper--2pL-A .ant-tabs-nav-wrap::after{display:none}@media (max-width: 435px){.WidgetTabs__tabsWrapper--2pL-A .ant-tabs-nav-list{flex-direction:column;align-items:center;width:100%}}@media (max-width: 435px){.WidgetTabs__tabsWrapper--2pL-A .ant-tabs-tab.ant-tabs-tab-active{background-color:#3b97b8}}.ant-tabs>.ant-tabs-nav .ant-tabs-nav-list,.ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-list{flex:none !important}\n", ""]);

// exports
exports.locals = {
	"tabsWrapper": "WidgetTabs__tabsWrapper--2pL-A"
};