import React, { createElement } from 'react';
import PropTypes from 'prop-types';
import CN from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import * as Icons from './IconLib';

import css from './Icon.scss';

const Icon = ({ type, className, color, style, ...rest }) => {
  if (type && Icons[type]) {
    return (
      <div className={CN(css.iconWrapper, className)} style={style} {...rest}>
        {createElement(Icons[type], { color })}
      </div>
    );
  }
  return (
    <div className={CN(css.defaultIconWrapper, className)} style={style} {...rest}>
      {createElement(Icons.Default, { color })}
    </div>
  );
};

Icon.propTypes = {
  type: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object
};
Icon.defaultProps = {
  style: {}
};
export default withStyles(css)(Icon);
