exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".PageTitle__pageTitle--328bp{max-width:900px;margin:0 auto 50px}.PageTitle__pageTitle--328bp .PageTitle__title--e-LUC{font-size:40px;font-weight:700;text-align:center}@media (max-width: 500px){.PageTitle__pageTitle--328bp .PageTitle__title--e-LUC{font-size:30px}}.PageTitle__pageTitle--328bp .PageTitle__subTitle--oci7P{text-align:center;font-size:16px}.PageTitle__pageTitle--328bp .PageTitle__subTitle--oci7P a{color:#3B97B8;font-weight:500}.PageTitle__pageTitle--328bp .PageTitle__subTitle--oci7P a:hover{text-decoration:none;color:#0E3E61}.PageTitle__pageTitle--328bp .PageTitle__divider--1Fgv0{margin-top:40px;height:2px;width:200px;background-color:#3B97B8}@media (max-width: 500px){.PageTitle__pageTitle--328bp .PageTitle__divider--1Fgv0{width:150px}}\n", ""]);

// exports
exports.locals = {
	"pageTitle": "PageTitle__pageTitle--328bp",
	"title": "PageTitle__title--e-LUC",
	"subTitle": "PageTitle__subTitle--oci7P",
	"divider": "PageTitle__divider--1Fgv0"
};