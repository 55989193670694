import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import RCCalendar from 'rc-calendar';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import moment from 'moment';
import cx from 'classnames';
import styles from './Calendar.scss';

class Calendar extends PureComponent {
  static propTypes = {
    selectedValue: PropTypes.object,
    selectDay: PropTypes.func,
    hideTooltip: PropTypes.bool,
    disableAll: PropTypes.bool,
    availableDates: PropTypes.array,
    className: PropTypes.string,
    onClose: PropTypes.func,
    highlightedDates: PropTypes.array
  };

  state = {
    value: null
  };

  componentDidMount() {
    this.formatDate();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.selectedValue !== this.state.value) {
      this.setState({
        value: nextProps.selectedValue
      });
    }
  }

  componentDidUpdate() {
    const { value } = this.state;
    this.formatDate(value);
  }

  onChange = value => this.setState({ value });

  disabledDate = (current) => {
    const { availableDates, disableAll } = this.props;
    if (!current) {
      return false;
    }
    if (disableAll) {
      return true;
    }
    if (availableDates && availableDates.length >= 1) {
      return !availableDates.some(date =>
        moment(current).isSame(date, 'days'));
    }

    const date = moment();
    date.hour(0);
    date.minute(0);
    date.second(0);
    return current.valueOf() < date.valueOf();
  };

  selectDay = (day) => {
    const { selectDay } = this.props;
    selectDay(day);
  };

  formatDate = (value) => {
    if (!value) {
      value = new Date();
    }
    const monthNode = document.getElementsByClassName(
      'rc-calendar-month-select'
    );
    const weakDayNodes = document.getElementsByClassName(
      'rc-calendar-column-header-inner'
    );
    [...weakDayNodes].map((el) => {
      el.innerText = el.innerText[0];
      return el;
    });

    Array.from(monthNode).forEach((n) => { n.textContent = moment(value).format('MMMM'); });
  };

  dateRender = (current) => {
    const { highlightedDates } = this.props;
    let isHighlighted = false;
    if (highlightedDates) {
      isHighlighted = highlightedDates.some(date =>
        moment(date).isSame(current, 'day'));
    }
    const selectedValue = this.state.value;
    if (
      selectedValue &&
      current.year() === selectedValue.year() &&
      current.week() === selectedValue.week()
    ) {
      return (
        <div
          className={cx('rc-calendar-selected-day', {
            'rc-calendar-highlighted': isHighlighted
          })}
        >
          <div className='rc-calendar-date'>{current.date()}</div>
        </div>
      );
    }
    return (
      <div
        className={cx('rc-calendar-date', {
          'rc-calendar-highlighted': isHighlighted
        })}
      >
        {current.date()}
      </div>
    );
  };

  render() {
    const { value } = this.state;
    const {
      className,
      hideTooltip,
      selectedValue,
      onClose,
      ...props
    } = this.props;

    return (
      <div className={cx(styles.calendarWrapper, className, 'calendarWrapper')}>
        {onClose ? (
          <div onClick={onClose} className={styles.close}>
            +
          </div>
        ) : (
          <div />
        )}
        <RCCalendar
          value={value}
          onChange={this.onChange}
          showToday={false}
          dateRender={this.dateRender}
          onSelect={this.selectDay}
          disabledDate={this.disabledDate}
          selectedValue={selectedValue}
          mode='date'
          {...props}
        />
        {!hideTooltip ? (
          <div className={styles.tooltip}>
            Dates in gray have no available lessons
          </div>
        ) : (
          <span />
        )}
      </div>
    );
  }
}

export default withStyles(styles)(Calendar);
