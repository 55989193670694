import React from 'react';
import IconBase from 'react-icon-base';

export default function BurgerMenu(props) {
  return (
    <IconBase viewBox='0 0 32 25' {...props}>
      <g transform='translate(-12 -15)'>
        <path id='bbar1' d='M12 15h30l2 3H14z' />
        <path id='bbar2' d='M12 37h30l2 3H14z' />
        <path id='bbar3' d='M12 26h30l2 3H14z' />
      </g>
    </IconBase>
  );
}
