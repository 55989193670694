exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".MaintenancePage__maintenanceWrapper--1peBK{max-width:1440px;margin:auto;width:100%;position:relative;display:-ms-flexbox;display:flex;-moz-justify-content:center;-ms-justify-content:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-align-items:center;-moz-align-items:center;-ms-align-items:center;align-items:center;min-height:600px;background-position:center;background-size:cover}.MaintenancePage__maintenanceWrapper--1peBK .MaintenancePage__maintenanceImage--2U25f{object-fit:cover}.MaintenancePage__maintenanceWrapper--1peBK .MaintenancePage__maintenanceText--fKa_2{z-index:2;padding:0 20px}.MaintenancePage__maintenanceWrapper--1peBK .MaintenancePage__maintenanceText--fKa_2 h1{text-align:center;font-family:\"Open Sans\",sans-serif !important;color:#fff;font-weight:bold;margin-bottom:20px;font-size:34px}.MaintenancePage__maintenanceWrapper--1peBK .MaintenancePage__maintenanceText--fKa_2 p{font-family:\"Open Sans\",sans-serif !important;color:#fff;font-weight:bold;text-align:center;font-size:16px}.MaintenancePage__maintenanceWrapper--1peBK::after{content:'';position:absolute;height:100%;width:100%;top:0;left:0;background-color:rgba(0,0,0,0.7)}\n", ""]);

// exports
exports.locals = {
	"maintenanceWrapper": "MaintenancePage__maintenanceWrapper--1peBK",
	"maintenanceImage": "MaintenancePage__maintenanceImage--2U25f",
	"maintenanceText": "MaintenancePage__maintenanceText--fKa_2"
};