import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Icon } from 'components';

export class SliderArrow extends PureComponent {
  state = {
    zIndex: 9
  };

  render() {
    const { onClick, style, className } = this.props;
    return (
      <Icon
        onClick={onClick}
        style={{
          ...style,
          zIndex: this.state.zIndex
        }}
        className={cx(className)}
        type='SlideControl'
      />
    );
  }
}

SliderArrow.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func
};

export default SliderArrow;
