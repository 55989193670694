import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'components';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import styles from './BrowserRequirements.scss';

const overlayStyles = {
  backgroundColor: 'rgba(0, 0, 0, 0.75)'
};

const BrowserRequirements = ({
  visible,
  closeModal
}) => (
  <Modal
    visible={visible}
    closeModal={closeModal}
    wrapClassName={styles.modalWrapper}
    maskStyle={overlayStyles}
    width={707}
  >
    <div className={styles.textWrapper}>
      <div>
        <p>The 2020lifestyles.com website supports the latest versions of all major browsers and mobile platforms.</p>
      </div>
    </div>
  </Modal>
);

BrowserRequirements.propTypes = {
  visible: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired
};

export default withStyles(styles)(BrowserRequirements);
