import { routes } from '../../routes';

const mobileFooterLinks = [
  {
    name: 'About Us',
    path: routes.ABOUT_US
  },
  {
    name: 'Contact Us',
    path: routes.CONTACT_US
  },
  // {
  //   name: 'Careers',
  //   path: routes.CAREERS
  // },
  {
    name: 'Browser Requirements',
    path: 'browser requirements'
  },
  {
    name: 'Terms of Use',
    path: routes.TERMS_OF_USE
  },
  {
    name: 'Terms of Service',
    path: routes.TERMS_OF_SERVICE
  },
  {
    name: 'Terms of Sale',
    path: routes.TERMS_OF_SALE
  },
  {
    name: 'Privacy',
    path: routes.PRIVACY_POLICY
  }
];

const footerLinks = [
  {
    name: 'misc pages',
    innerLinks: [
      {
        name: 'about us',
        path: routes.ABOUT_US
      },
      {
        name: 'contact us',
        path: routes.CONTACT_US
      }
      // {
      //   name: 'careers',
      //   path: routes.CAREERS
      // }
    ]
  },
  {
    name: 'policy pages',
    innerLinks: [
      {
        name: 'browser requirements',
        path: 'browser requirements'
      },
      {
        name: 'terms of use',
        path: routes.TERMS_OF_USE
      },
      {
        name: 'terms of service',
        path: '/terms-of-service'
      },
      {
        name: 'terms of sale',
        path: routes.TERMS_OF_SALE
      },
      {
        name: 'privacy',
        path: routes.PRIVACY_POLICY
      }
    ]
  }
];

const socials = [
  {
    icon: 'FaFacebook',
    path: 'https://www.facebook.com/2020lifestyles/',
    isExternal: true
  }, {
    icon: 'FaInstagram',
    path: 'https://www.instagram.com/2020lifestyles/',
    isExternal: true
  }, {
    icon: 'FaYoutubeFull',
    path: 'https://www.youtube.com/channel/UCBy_MR1Jc7MDv5_3xLpnkQg',
    isExternal: true
  }, {
    icon: 'FaPinterestP',
    path: 'https://www.pinterest.com/2020lifestyles/',
    isExternal: true
  }
];

export {
  footerLinks,
  socials,
  mobileFooterLinks
};
