import React from 'react';

const meta = {
  title: 'Why 20/20 LifeStyles Works',
  description:
    'A weight loss plan 100% personalized to your body with an expert team to work with you one on one and give you the tools for long term success.',
  keywords: ['weight loss', 'nutrition in bellevue']
};

const page = {
  name: 'Why 20/20 LifeStyles Works',
  subTitle:
    'According to the National Institutes of Health, the largest health research agency in the world, a weight loss program must have six essential components to help people lose weight successfully and, most importantly, keep it off long-term.'
};

const sections = [
  {
    sectionExtraClassName: 'noPadding',
    textBlocks: [
      {
        align: 'center',
        singleVideo: {
          youtube: 'https://www.youtube.com/watch?v=19wBVMQ4EzE',
          alt: '2020 Lifestyles Program - Why It Works'
        }
      }
    ]
  },
  {
    sectionExtraClassName: 'noPadding',
    textBlocks: [
      {
        align: 'left',
        text: '20/20 LifeStyles program features six key components to help people lose weight and keep it off long-term. Only 20/20 LifeStyles has it all. No other weight loss program even comes close.'
      }
    ]
  },
  {
    sectionExtraClassName: 'noPadding',
    textBlocks: [
      {
        align: 'left',
        title: (
          <div className='whyitworks'>
            <div className='num'>1</div>
            <div className='titleGroup'>
              <h2>PHYSICIAN SUPERVISION</h2>
              <h4>The doctor is in</h4>
            </div>
          </div>
        ),
        text: (
          <div className='whyitworks-text'>
            <p>
              Our physicians will personally review your progress each week.
              They’ll adjust as needed for maximum weight loss. Only 20/20
              LifeStyles has physicians overseeing every aspect of your weight
              loss program.
            </p>
            <h5>OUR PHYSICIANS ARE TRAINED IN:</h5>
            <ul>
              <li>Weight loss</li>
              <li>Metabolism</li>
              <li>
                Metabolic disorders, including high cholesterol, high blood
                pressure, diabetes and others
              </li>
              <li>Sleep problems</li>
              <li>Gut health</li>
              <li>Thyroid function</li>
              <li>Nutrition</li>
              <li>Exercise</li>
              <li>And more</li>
            </ul>
          </div>
        )
      }
    ]
  },
  {
    sectionExtraClassName: 'noPadding',
    textBlocks: [
      {
        align: 'left',
        title: (
          <div className='whyitworks'>
            <div className='num'>2</div>
            <div className='titleGroup'>
              <h2>LICENSED REGISTERED DIETITIANS</h2>
              <h4>Real expertise. Real food. Real results</h4>
            </div>
          </div>
        ),
        text: (
          <div className='whyitworks-text'>
            <p>
              Each week, you’ll meet with a certified registered dietitian. Your
              dietitian will personalize a nutrition plan for you, based on the
              foods to which your body responds best for maximum, long-term
              weight loss. <strong>Best of all, you’ll never be hungry</strong>.
            </p>
            <h5>A HIGHER LEVEL OF HEALTHY</h5>
            <p>
              Each of our registered dietitians has passed a national certifying
              exam, and most have a master’s degrees in nutrition. Additionally,
              we offer diabetes consultants for those with specific health
              conditions. All are highly knowledgeable about weight management
              and metabolic disorders.
            </p>
          </div>
        )
      }
    ]
  },
  {
    sectionExtraClassName: 'noPadding',
    textBlocks: [
      {
        align: 'left',
        title: (
          <div className='whyitworks'>
            <div className='num'>3</div>
            <div className='titleGroup'>
              <h2>PERSONAL TRAINING</h2>
              <h4>Leaner. Fitter. Stronger. Forever.</h4>
            </div>
          </div>
        ),
        text: (
          <div className='whyitworks-text'>
            <p>
              Exercise with an exercise physiologist/personal trainer three days
              a week. And because your body is completely unique, your exercise
              program will be, too. Your personal trainer will create a
              personalized fitness plan for you – based on activities your body
              best responds to for maximum weight loss, without muscle loss.
              Plus, they’ll develop additional plans for your personal workouts
              and a maintenance program you can follow long-term.
            </p>
            <h5>EXPERIENCE COUNTS</h5>
            <p>
              Most of our personal trainers have earned a degree in kinesiology,
              and all have undergone postgraduate training in understanding and
              treating weight management and metabolic disorders.
            </p>
          </div>
        )
      }
    ]
  },
  {
    sectionExtraClassName: 'noPadding',
    textBlocks: [
      {
        align: 'left',
        title: (
          <div className='whyitworks'>
            <div className='num'>4</div>
            <div className='titleGroup'>
              <h2>LICENSED PSYCHOLOGISTS</h2>
              <h4>Change your habits. Change your life.</h4>
            </div>
          </div>
        ),
        text: (
          <div className='whyitworks-text'>
            <p>
              Working one-on-one with our licensed psychologists, you will learn
              healthy lifestyle habits. With insight into your lifestyle and
              current habits, your psychologist can identify actionable insights
              that can improve your ability to deal with stress, anxiety, sleep,
              time management, and more. Plus, you’ll attend weekly support
              groups led by a psychologist with other individuals who are
              struggling with similar lifestyle changes.
            </p>
            <h5>THE EXPERTISE YOU NEED</h5>
            <p>
              All of the 20/20 LifeStyles psychologists are state-licensed and
              have expertise in helping individuals with weight problems and
              weight-related metabolic disorders. Each has earned a four-year
              bachelor’s degree plus a master’s degree in psychology from
              accredited university programs.
            </p>
          </div>
        )
      }
    ]
  },
  {
    sectionExtraClassName: 'noPadding',
    textBlocks: [
      {
        align: 'left',
        title: (
          <div className='whyitworks'>
            <div className='num'>5</div>
            <div className='titleGroup'>
              <h2>EDUCATION</h2>
              <h4>
                Be in control. Understand the behaviors that caused your weight
                gain.
              </h4>
            </div>
          </div>
        ),
        text: (
          <div className='whyitworks-text'>
            <p>
              There is a lot to know about how your body works. As a 20/20
              client, you’ll receive continual education about nutrition,
              exercise and behavior. And as part of your program, you’ll watch
              more than 70 educational videos developed by Dr. Mark Dedomenico,
              renowned cardiovascular surgeon and founder of 20/20 LifeStyles.
              Many clients enjoy a video while they’re warming up for their
              workouts.
            </p>
            <h5>YOU’LL DISCOVER</h5>
            <ul>
              <li>How to stay motivated to exercise and eat right</li>
              <li>How to get better sleep and cope with stress</li>
              <li>Successful strategies for dining out</li>
              <li>Coping with celebrations</li>
              <li>How to grocery shop</li>
              <li>Tips for developing a strong support network</li>
              <li>And so much more</li>
            </ul>
          </div>
        )
      }
    ]
  },
  {
    sectionExtraClassName: 'noTopPadding',
    textBlocks: [
      {
        align: 'left',
        title: (
          <div className='whyitworks'>
            <div className='num'>6</div>
            <div className='titleGroup'>
              <h2>LONG-TERM SUPPORT FOR A LIFETIME OF SUCCESS</h2>
              <h4>You’ve got this. We’ve got you.</h4>
            </div>
          </div>
        ),
        text: (
          <div className='whyitworks-text'>
            <p>
              Once a 20/20 client, always a 20/20 client. Our staff of experts
              and resources are always available to support you in maintaining
              your health and habits. It all starts with a 12-week maintenance
              plan to help you transition once you’ve completed your program.
              During this time, you’ll meet regularly with your dietitian,
              personal trainer, and psychologist.
            </p>
            <p>
              Even long after your program ends, the 20/20 staff and resources
              are always available. You’ll also have access to group exercise
              classes, health tracking apps, for continued health tracking,
              20/20 LifeStyles support groups - as well as programs for
              controlling your weight during especially difficult times like
              pregnancy, after an injury and while coping with severe stress.
            </p>
          </div>
        )
      }
    ]
  }
];

export { page, sections, meta };
