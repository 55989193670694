import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import loader from 'assets/images/2020lifestyles-loader.gif';
import { PostCard } from './components';

import styles from './ListView.scss';

class ListView extends PureComponent {
  // state = {
  //   pageCount: 1
  // }

  // componentDidMount() {
  //   window.addEventListener('scroll', this.onScroll);
  // }
  //
  // componentWillUnmount() {
  //   window.removeEventListener('scroll', this.onScroll);
  // }

  // onScroll = () => {
  //   if (this.timeout) {
  //     clearTimeout(this.timeout);
  //     this.timeout = null;
  //   }
  //
  //   this.timeout = setTimeout(() => {
  //     const { postsState, setPostsState, posts, postsLazyLoading, postsTotalLength } = this.props;
  //     const postsWrappePosition = this.postsWrapper.getBoundingClientRect();
  //     const windowHeight = window.innerHeight;
  //     if (postsWrappePosition.top + postsWrappePosition.height < windowHeight &&
  //           posts.length < postsTotalLength &&
  //          !postsLazyLoading) {
  //       this.setState({ pageCount: this.state.pageCount + 1 });
  //       setPostsState({
  //         page: this.state.pageCount
  //       }, true);
  //     }
  //   }, 200);
  // };

  // setPostsWrapperRef = (node) => {
  //   this.postsWrapper = node;
  //   return this.postsWrapper;
  // };

  render() {
    const {
      posts,
      // postsLazyLoading,
      postsLoading
    } = this.props;
    return (
      <div className={styles.postsListWrapper}>
        <div
          className={styles.postsContainer}
          // ref={node => this.setPostsWrapperRef(node)}
        >
          <div className={styles.postsList}>
            {posts.map((item, index) => (
              <PostCard post={item} key={index} />
            ))}
          </div>
          <a
            className={styles.button}
            href='https://360degrees.blog/category/2020-lifestyles-insights/'
            target='_blank'
            rel='noopener noreferrer'
          >
            View All Posts
          </a>
        </div>
        {postsLoading ?
          <div className={styles.loader}>
            <img src={loader} alt='loader' className={styles.loaderIcon} />
          </div> : <div />
        }
        {/* postsLazyLoading ?
          <div className={styles.loader}>
            <img src={loader} alt='loader' className={styles.loaderIcon} />
          </div> : <div />
        */}
      </div>
    );
  }
}

ListView.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.object),
  // setPostsState: PropTypes.func.isRequired,
  // postsState: PropTypes.object.isRequired,
  // postsLazyLoading: PropTypes.bool.isRequired,
  // postsTotalLength: PropTypes.number.isRequired,
  postsLoading: PropTypes.bool.isRequired
};

ListView.defaultProps = {
  posts: []
};

export default withStyles(styles)(ListView);
