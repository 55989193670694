import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import formatPostExcerpt from 'utils/formatPostExcerpt';

import styles from './TabContent.scss';

class TabContent extends PureComponent {
  render() {
    const {
      posts
    } = this.props;
    return (
      <div className={styles.tabContentWrapper}>
        {posts.slice(0, 4).map((post, i) => (
          <div key={i} className={styles.content}>
            <div className={styles.timeStamp}>{post.formatted_date}</div>
            <h4 className={styles.postTitle}>
              <a href={post.link} target='_blank'>
                {parse(post.title.rendered)}
              </a>
            </h4>
            <div className={styles.excerpt}>
              {formatPostExcerpt(parse(post.excerpt.rendered), post.link)}
            </div>
          </div>
        ))}
      </div>
    );
  }
}

TabContent.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.object)
};

export default withStyles(styles)(TabContent);
