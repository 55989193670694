import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import loader from 'assets/images/2020lifestyles-loader.gif';
import { getInitialRecipes, getRecipesFilters } from 'actions/recipes';
import {
  Breadcrumbs,
  PageTitle,
  MetaData
} from 'components';
import { ListView, Filters } from './components';
import { page, meta } from '../../mocks/pages/RecipesPage';
import styles from './RecipesPage.scss';

class RecipesPage extends PureComponent {
  static propTypes = {
    getInitialRecipes: PropTypes.func.isRequired,
    getRecipesFilters: PropTypes.func.isRequired,
    setRecipesState: PropTypes.func.isRequired,
    recipes: PropTypes.arrayOf(PropTypes.object),
    location: PropTypes.object,
    recipesTotalLength: PropTypes.number,
    recipesState: PropTypes.object.isRequired,
    recipesLazyLoading: PropTypes.bool,
    time_of_days: PropTypes.object,
    dishes: PropTypes.object,
    difficulties: PropTypes.object,
    stages: PropTypes.object,
    recipesLoading: PropTypes.bool
  };

  static fetching({ dispatch }) {
    return [
      dispatch(getInitialRecipes()),
      dispatch(getRecipesFilters())
    ];
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const {
      getInitialRecipes,
      getRecipesFilters,
      location
    } = this.props;
    const param = location.search;
    getInitialRecipes(param);
    getRecipesFilters();
  }

  render() {
    const {
      recipes,
      location,
      recipesTotalLength,
      setRecipesState,
      recipesLazyLoading,
      recipesState,
      time_of_days,
      dishes,
      difficulties,
      stages,
      recipesLoading
    } = this.props;
    let stage = null;
    if (location.search !== null && location.search.includes('?stage-')) {
      const stageNum = location.search.split('?stage-')[1];
      if (stageNum > 0 && stageNum < 8) {
        stage = stageNum;
      }
    }

    const newMeta = {
      ...meta,
      path: location.pathname
    }

    return (
      <div>
        <MetaData meta={newMeta} />
        <div className='bodyWrapper'>
          <Breadcrumbs location={location} />
          <PageTitle page={page} />
          {time_of_days !== null && dishes !== null && difficulties !== null && stages !== null ?
            <Filters
              recipesState={recipesState}
              time_of_days={time_of_days}
              dishes={dishes}
              difficulties={difficulties}
              stages={stages}
              setRecipesState={setRecipesState}
              defaultStage={stage}
              location={location}
            /> : null
          }
          {recipes.length ?
            <ListView
              recipesTotalLength={recipesTotalLength}
              recipesLazyLoading={recipesLazyLoading}
              recipesState={recipesState}
              setRecipesState={setRecipesState}
              recipes={recipes}
              recipesLoading={recipesLoading}
            /> : null
          }
          {recipesLoading ?
            <div className={styles.loader}>
              <img src={loader} alt='loader' className={styles.loaderIcon} />
            </div> : null
          }
          {!recipes.length && !recipesLoading ?
            <div className='notFoundMsg'>
              No match found. Please update your criteria
            </div> : null
          }
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(RecipesPage);
