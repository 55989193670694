exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".FaqPage__accordions--3Zlxm{padding-bottom:50px;max-width:1200px;margin:0 auto}.FaqPage__accordions--3Zlxm .ant-collapse-header{padding:0px 0px 0px 20px !important;font-size:20px;font-weight:500}.FaqPage__accordions--3Zlxm .ant-collapse-arrow{top:-4px !important;left:0px !important}.FaqPage__accordions--3Zlxm .ant-collapse-arrow svg{color:#3b97b8}.FaqPage__accordions--3Zlxm .ant-collapse-arrow svg path{stroke-width:10%;stroke:#3b97b8}.FaqPage__accordions--3Zlxm .ant-collapse-content-box{padding-top:10px !important;padding-bottom:0px !important;padding-left:20px;font-size:16px}.FaqPage__accordions--3Zlxm .ant-collapse-content-box p ol{padding-left:10px}.FaqPage__accordions--3Zlxm .ant-collapse-item{margin-bottom:30px}\n", ""]);

// exports
exports.locals = {
	"accordions": "FaqPage__accordions--3Zlxm"
};