import React from 'react';

const meta = {
  title: 'Corporate Solutions'
  // description: 'Welcome to 2020 Lifestyles',
  // keywords: ["weight loss", "nutrition in bellevue"]
};

const page = {
  name: 'Corporate Solutions',
  subTitle: (
    <div>
      <p>
        Not all wellness programs are created equal. 20/20 LifeStyles Wellness
        is a medically-based program with the proven results you want: healthier
        employees - and a healthier ROI.
      </p>
    </div>
  )
};

const sections = [
  {
    sectionExtraClassName: 'noTopPadding',
    textBlocks: [
      {
        align: 'left',
        title: 'Employees love it. Employers want it.',
        text: (
          <div>
            <p>
              No other wellness program has proven results like 20/20
              LifeStyles.
            </p>
            <ul>
              <li>
                98% of participants normalize their blood sugar - and get off
                medication
              </li>
              <li>
                92% normalize their blood pressure - and get off medication
              </li>
              <li>66% lower their cholesterol - and get off medication</li>
              <li>Most lose an average of 40 pounds</li>
            </ul>
          </div>
        )
      },
      {
        align: 'left',
        title: 'The 80/20 rule - and how to break it',
        text: (
          <ul>
            <li>
              80% of your healthcare dollars are spent on 20% of your employees
            </li>
            <li>
              70% of your medical dollars are related to curable chronic
              conditions brought on by lifestyle choices like high blood
              pressure and Type 2 diabetes
            </li>
          </ul>
        )
      },
      {
        align: 'left',
        title: '5 keys to success',
        text: (
          <div>
            <p>
              Curing chronic conditions like Type 2 diabetes isn’t just about
              dieting. 20/20 LifeStyles Wellness gives employees the tools,
              support and personalized care they need to change their health
              forever. It’s good for them, and for your company.
            </p>
            <ul>
              <li>Education</li>
              <li>Nutrition</li>
              <li>Exercise</li>
              <li>Lifestyle counseling</li>
              <li>Medical supervision</li>
            </ul>
          </div>
        )
      },
      {
        align: 'left',
        title: 'Adopted by some of the world’s most iconic companies',
        text: 'The 20/20 Lifestyles Program is the most original, successful and comprehensive multidisciplinary treatment for weight management and metabolic disorders without the use of medication. Thousands of people from Washington’s high tech corporations have been through the program overcoming their need for medication to treat diabetes, high cholesterol and high blood pressure, which directly impacts the bottom line by reducing health care costs, improving employee productivity and presenteeism, and aids in retention and recruiting.'
      },
      {
        align: 'left',
        title: 'Let’s talk',
        text: (
          <p>
            Find out firsthand what sets the 20/20 LifeStyles Wellness Program
            apart. Schedule a meeting, and find out how the program’s proven
            results in reducing corporate costs can work for your company. Call
            (425) 376-3333 to receive more information.
          </p>
        )
      }
    ]
  }
];

export { page, sections, meta };
