import React from 'react';
import { Link, matchPath, NavLink } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import PropTypes from 'prop-types';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
// import { routes } from '../../routes';
import styles from './DropDownMenu.scss';

class DropdownMenu extends React.Component {
  state = {
    subMenuWidth: ''
  };

  componentDidMount() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    if (this.dropdown !== null) {
      const dropdownWidth = this.dropdown.offsetWidth;
      this.setState({ subMenuWidth: dropdownWidth });
    }
  };

  render() {
    const { link, linkClassName, pathname } = this.props;
    const links = link.innerLinks;
    const path = window.location.pathname.toLowerCase().split('/')[1];

    const renderList = width => (
      <ul className={cx(styles.subMenu)} style={{ width: `${width}px` }}>
        {links ? links.map((nav) => {
          const match = matchPath(typeof window === 'undefined' ?
            pathname : window.location.pathname, { path: nav.path });

          if (nav.path.length && nav.path.indexOf('http') >= 0) {
            return (
              <li key={nav.name} className={cx({ [styles.active]: match })}>
                <a
                  href={nav.path}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  {nav.name}
                </a>
              </li>
            );
          } else if (nav.path.length && nav.anchored) {
            const hashPath = window.location.href.split('#')[1];
            const currentHash = nav.path.split('#')[1];
            return (
              <li key={nav.name} className={cx({ [styles.active]: hashPath === currentHash }, 'anchored')}>
                <HashLink
                  to={nav.path}
                >
                  {nav.name}
                </HashLink>
              </li>
            );
          }
          return (
            <li key={nav.name} className={cx({ [styles.active]: match })}>
              <Link
                to={nav.path}
              >
                {nav.name}
              </Link>
            </li>
          );
        }) : <div />
        }
      </ul>
    );
    return (
      <div className={styles.dropdown} ref={(node) => { this.dropdown = node; }}>
        {
          link && link.path ?
            <NavLink
              activeClassName={styles.selected}
              key={link.name}
              to={link.path}
              className={cx(styles.navlink, linkClassName)}
            >
              {link.name}
            </NavLink> :
            <div />
        }
        {
          link.textOnly ?
            <span
                className={cx(styles.navlink, linkClassName, { [styles.selected]: (path === 'schedule-a-consultation' || path === 'attend-a-seminar') })}
              >
                {link.name}
            </span> : 
            <div />
        }
        {renderList(this.state.subMenuWidth)}
      </div>
    );
  }
}

DropdownMenu.propTypes = {
  link: PropTypes.object.isRequired,
  linkClassName: PropTypes.string,
  pathname: PropTypes.string
};

export default withStyles(styles)(DropdownMenu);
