exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Diets__selectWrapper--3epPw{width:200px;padding:0 10px}.select{border:2px solid #3b97b8;border-radius:20px;background:#fff;height:39px;text-transform:uppercase;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-align-items:center;-moz-align-items:center;-ms-align-items:center;align-items:center}.select .ant-select-selector{border:none !important;border-radius:20px !important;height:30px !important;font-size:14px;background:transparent !important;color:#3b97b8;font-weight:700}.select.ant-select-focused .ant-select-selector{box-shadow:none !important}.select.ant-select-open{border-radius:20px 20px 0 0}.Diets__list--2A9Vs{border-radius:0 0 20px 20px;border:solid #3b97b8;border-width:0 2px 2px 2px;box-shadow:none;overflow:visible;z-index:7;padding:0px}.Diets__list--2A9Vs .ant-select-item{font-size:14px !important;text-transform:uppercase !important;font-weight:700 !important;color:#000;background-color:transparent}.Diets__list--2A9Vs .ant-select-item:hover{color:#fff;background-color:#3b97b8 !important}.Diets__list--2A9Vs .ant-select-item:last-child{border-radius:0 0 18px 18px}.Diets__list--2A9Vs .ant-select-item-option-selected:not(.ant-select-item-option-disabled){color:#fff !important;font-weight:700 !important;background-color:#3b97b8 !important;font-size:14px !important;text-transform:uppercase !important}\n", ""]);

// exports
exports.locals = {
	"selectWrapper": "Diets__selectWrapper--3epPw",
	"list": "Diets__list--2A9Vs"
};