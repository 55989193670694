var escape = require("../../../../../../../node_modules/css-loader/lib/url/escape.js");
exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Stages__selectWrapper--f9OWr{width:250px;padding:0 10px}@media (max-width: 767px){.Stages__selectWrapper--f9OWr{width:200px}}.select{border:2px solid #3b97b8;border-radius:20px;background:#fff;height:39px;text-transform:uppercase;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-align-items:center;-moz-align-items:center;-ms-align-items:center;align-items:center}.select .ant-select-selector{border:none !important;border-radius:20px !important;height:30px !important;font-size:14px;background:transparent !important;color:#3b97b8;font-weight:700}.select.ant-select-focused .ant-select-selector{box-shadow:none !important;color:#3b97b8}.select.ant-select-open{border-radius:20px 20px 0 0}.select.ant-select-open .ant-select-selector .ant-select-selection-item{color:#3b97b8}.select.ant-select-open .ant-select-arrow .anticon{transform:rotate(-90deg)}.select .ant-select-arrow .anticon{background-image:url(" + escape(require("../../../../../../assets/images/swiper-arrow.svg")) + ");background-repeat:no-repeat;background-position:center;width:14px;height:14px;background-size:contain;transform:rotate(90deg);margin-top:-2px}.select .ant-select-arrow .anticon svg{display:none}.Stages__list--fgQAo{border-radius:0 0 20px 20px;border:solid #3b97b8;border-width:0 2px 2px 2px;box-shadow:none;overflow:visible;z-index:7;padding:0px}.Stages__list--fgQAo .ant-select-item{font-size:14px !important;text-transform:uppercase !important;font-weight:700 !important;color:#000;background-color:transparent}.Stages__list--fgQAo .ant-select-item:hover{color:#fff;background-color:#3b97b8 !important}.Stages__list--fgQAo .ant-select-item:last-child{border-radius:0 0 18px 18px}.Stages__list--fgQAo .ant-select-item-option-selected:not(.ant-select-item-option-disabled){color:#fff !important;font-weight:700 !important;background-color:#3b97b8 !important;font-size:14px !important;text-transform:uppercase !important}\n", ""]);

// exports
exports.locals = {
	"selectWrapper": "Stages__selectWrapper--f9OWr",
	"list": "Stages__list--fgQAo"
};