import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import DropDownMenu from '../DropDownMenu';
import { mobileFooterLinks } from '../Footer/links';

import styles from './Navigation.scss';


const renderLink = (link, isMobile, linkClassName, mobileMenuOff) => {
  if (link.isExternal) {
    return (
      <a href={link.path} rel='noopener noreferrer' target='_blank' className={cx(styles.navlink, linkClassName)}>
        {link.name}
      </a>
    );
  } else if (link.anchored) {
    const hashPath = window.location.href.split('#')[1];
    const currentHash = link.path.split('#')[1];
    return (
      <HashLink
        to={link.path}
        className={cx(
          'anchored',
          styles.navlink,
          linkClassName,
          { [styles.dropdownParent]: link.innerLinks },
          { [styles.selected]: hashPath === currentHash && !isMobile }
        )}
        onClick={mobileMenuOff}
      >
        {link.name}
      </HashLink>
    );
  } else if (link.textOnly) {
    const path = window.location.pathname.toLowerCase().split('/')[1];
    return (
      <div
      className={cx(
        styles.textOnly,
        styles.navlink,
        linkClassName,
        { [styles.dropdownParent]: link.innerLinks },
        { [styles.selected]: (path === 'schedule-a-consultation' || path === 'attend-a-seminar') }
      )}
      >
        {link.name}
      </div>)
  }
  return (
    <NavLink
      activeClassName={cx({ [styles.selected]: link.path !== '/' && !isMobile }, { 'selected-mobile-nav': link.path !== '/' && isMobile })}
      to={link.path}
      className={cx(styles.navlink, linkClassName, { [styles.dropdownParent]: link.innerLinks })}
      onClick={mobileMenuOff}
    >
      {link.name}
    </NavLink>
  );
};

const Navigation = ({
  links,
  navigationClassName,
  linkClassName,
  isMobile,
  mobileMenuOff,
  wrapClassName,
  disableDropdown,
  pathname,
  style,
  openModal
}) => {
  return (
    <div className={cx(styles.navigation, navigationClassName, wrapClassName || '')} style={{ ...style }}>
      {links.map(link => (
        <Fragment key={`link_${link.name}`}>
          {link.innerLinks && link.innerLinks.length && !isMobile && !disableDropdown ?
            <DropDownMenu
              link={link}
              linkClassName={cx(linkClassName)}
              pathname={pathname}
            />
            :
            <div className={cx({ [styles.hasSubMenu]: link.innerLinks })}>
              <Fragment>{renderLink(link, isMobile, linkClassName, mobileMenuOff)}</Fragment>
              {
                link.innerLinks && link.innerLinks.length && isMobile ?
                  <Navigation
                    links={link.innerLinks}
                    isNested
                    mobileMenuOff={mobileMenuOff}
                    wrapClassName={styles.mobileSubnavigation}
                    disableDropdown
                    pathname={pathname}
                  /> : <div />
              }
            </div>
          }
        </Fragment>
      ))
      }
      {mobileFooterLinks.map(link => (
        <Fragment key={`link_${link.name}`}>
          {link && isMobile ?
            <Fragment>
              {link.name === 'Browser Requirements' ? (
                <div
                  onClick={openModal}
                  className={cx(styles.navlink, linkClassName, styles.browserReqLink)}
                >
                  {link.name}
                </div>) : renderLink(link, isMobile, linkClassName, mobileMenuOff)
              }
            </Fragment>
            :
            <div />
          }
        </Fragment>
      ))
      }
    </div>
  );
}

Navigation.propTypes = {
  isMobile: PropTypes.bool,
  links: PropTypes.arrayOf(PropTypes.object),
  navigationClassName: PropTypes.string,
  linkClassName: PropTypes.string,
  pathname: PropTypes.string,
  mobileMenuOff: PropTypes.func,
  style: PropTypes.object,
  wrapClassName: PropTypes.string,
  disableDropdown: PropTypes.bool,
  openModal: PropTypes.func
};

Navigation.defaultProps = {
  linkClassName: styles.navlink
};

export default withStyles(styles)(Navigation);
