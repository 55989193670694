import { connect } from 'react-redux';
import {
  getStoriesFilters,
  setStoriesState,
  getAllStories,
  getInitialStories
} from 'actions/successStories';
import { SuccessStoriesPage } from 'components';

const mapStateToProps = ({
  successStories: {
    data,
    storiesState,
    storiesLazyLoading,
    storiesLoading
  }
}) => ({
  ...data,
  storiesState,
  storiesLazyLoading,
  storiesLoading
});

const mapDispatchToProps = {
  setStoriesState,
  getAllStories,
  getInitialStories,
  getStoriesFilters
};

export default connect(mapStateToProps, mapDispatchToProps)(SuccessStoriesPage);
