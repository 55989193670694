import React from 'react';
import IconBase from 'react-icon-base';

export default function Print(props) {
  return (
    <IconBase viewBox='0 0 20 20' {...props}>
      <g style={{ transform: 'scale(0.2)' }}>
        <g>
          <path d='M88.9,22.7H73.2l-4.1-13c-0.5-1.6-2.1-2.8-3.8-2.8H34.6c-1.7,0-3.3,1.1-3.8,2.8l-4.1,13H11.1c-4.8,0-8.6,3.9-8.6,8.6     v28.6c0,4.8,3.9,8.6,8.6,8.6h1.4L6.4,88c-0.4,1.2-0.2,2.5,0.6,3.5c0.7,1,1.9,1.6,3.2,1.6h79.6c1.3,0,2.4-0.6,3.2-1.6     c0.7-1,1-2.3,0.6-3.5l-6.1-19.4h1.4c4.8,0,8.6-3.9,8.6-8.6V31.3C97.5,26.5,93.6,22.7,88.9,22.7z M82.8,32.8     c2.6,0,4.6,2.1,4.6,4.6c0,2.6-2.1,4.6-4.6,4.6s-4.6-2.1-4.6-4.6C78.2,34.9,80.3,32.8,82.8,32.8z M37.6,14.8h24.9l2.5,7.8H35.1     L37.6,14.8z M15.6,85.2l10-31.8h48.8l10,31.8H15.6z' />
          <path d='M35.4,66.1h29.1c1.8,0,3.2-1.4,3.2-3.2s-1.4-3.2-3.2-3.2H35.4c-1.8,0-3.2,1.4-3.2,3.2S33.7,66.1,35.4,66.1z' />
          <path d='M68.7,72.4H31.3c-1.8,0-3.2,1.4-3.2,3.2s1.4,3.2,3.2,3.2h37.4c1.8,0,3.2-1.4,3.2-3.2S70.4,72.4,68.7,72.4z' />
        </g>
      </g>
    </IconBase>
  );
}
