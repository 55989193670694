import { routes } from 'routes';

const navigation = [
  {
    name: 'program information',
    path: routes.PROGRAM_INFORMATION,
    innerLinks: [
      {
        name: 'why it works',
        path: routes.PROGRAM_INFORMATION_WHY_IT_WORKS
      },
      // {
      //   name: 'how it works',
      //   path: `${routes.PROGRAM_INFORMATION}#how-it-works`,
      //   anchored: true
      // },
      {
        name: 'Scientific Health Monitoring',
        path: routes.PROGRAM_INFORMATION_SCIENTIFIC_HEALTH_MONITORING
      },
      {
        name: 'Program Fees',
        path: routes.PROGRAM_INFORMATION_PRICING
      },
      {
        name: 'corporate solutions',
        path: routes.PROGRAM_INFORMATION_CORPORATE_SOLUTIONS
      },
      {
        name: 'about us',
        path: routes.ABOUT_US
      }
    ]
  },
  {
    name: 'experts',
    path: routes.EXPERTS
  },
  {
    name: 'success stories',
    path: routes.SUCCESS_STORIES
  },
  {
    name: 'resources & tools',
    path: routes.RESOURCES_AND_TOOLS,
    innerLinks: [
      {
        name: 'blog',
        path: routes.RESOURCES_AND_TOOLS_BLOG
      },
      {
        name: 'recipes',
        path: routes.RESOURCES_AND_TOOLS_RECIPES
      },
      {
        name: 'FAQ',
        path: routes.RESOURCES_AND_TOOLS_FAQS
      }
    ]
  }
  // {
  //   name: 'get started',
  //   textOnly: true,
  //   innerLinks: [
  //     {
  //       name: 'Attend a Seminar',
  //       path: routes.ATTEND_A_SEMINAR
  //     },
  //     {
  //       name: 'schedule a free consultation',
  //       path: routes.SCHEDULE_A_CONSULTATION
  //     }
  //   ]
  // }
];

export { navigation };
