import React from 'react';

const meta = {
  title: 'Rewards',
  noFollow: true
  // description: 'Welcome to 2020 Lifestyles',
  // keywords: ["weight loss", "nutrition in bellevue"]
}

const page = {
  name: 'Rewards',
  subTitle: 'Lose Weight.  Get Rewarded.  It’s Easy!'
};

const sections = [
  {
    sectionExtraClassName: 'noTopPadding',
    // textBeforeTitle: 'another test',
    noMobilePadding: true,
    textBlocks: [
      {
        align: 'left',
        text: (
          <div>
            <p>
              Our 20/20 Rewards program helps increase accountability and provide additional incentives 
              to work toward your goal with hundreds of dollars in redeemable merchandise and services 
              available at PRO Club. Earn points throughout your program by completing a variety of 
              activities including unsupervised exercise, weekly steps, meal tracking, attending appointments, and more.
            </p>
            <p>
              Getting started is easy. Once you’re enrolled in the 20/20 LifeStyles program and have meant 
              with your physician, you’ll receive an invitation with a link for registration. Once enrolled, 
              your points will start accruing as you complete various activities and will be posted to your account at the end of each week.
            </p>
            <p>
              Rewards may be redeemed throughout the program for protein shakes, Grab & Go meals, and more or 
              save up your points for bigger rewards such as massages, Pro Shop, private sports lessons, and more.
            </p>
          </div>
        )
      },
      {
        align: 'left',
        title: 'FREQUENTLY ASKED QUESTIONS',
        text: (
          <div>
            <p>
              <strong>Q: How do I earn rewards in the 20/20 LifeStyles program?</strong><br />
              A: You will earn rewards in program for the following activities:<br />
              <ul>
                <li>Arriving to all scheduled appointments on time</li>
                <li>Attending all 10 group counseling sessions</li>
                <li>Scheduling additional counseling sessions during your program</li>
                <li>ompleting 45-60 minutes of unsupervised exercise 2-3 days per week (Tracked through Fitbit)</li>
                <li>Achieving 5,000-10,000 average steps per week (Tracked through Fitbit)</li>
                <li>Achieving an average of 6-8 hours of sleep per week (Tracked through Fitbit)</li>
                <li>Meal tracking every day</li>
                <li>Any progress made at the end of the program</li>
              </ul>
            </p>
            <p>
              <strong>Q: How do I redeem my rewards?</strong><br />
              A: Simply log into your account to view your point status and available redeemable services choices based on your point total.
              After you have redeemed your points for a service, you will receive an email within two business days
              notifying you that your certificates are ready to be picked up at the PRO Club Concierge Desk.
            </p>
            <p>
              <strong>Q:  When will I see my points?</strong><br />
              A:  Weekly points are recorded by Friday of each week.
              We use your weekly dietitian appointment to monitor your activity progress for eligible rewards while you are in the 20/20 LifeStyles program.
            </p>
            <p>
              <strong>Q: What If I don’t have a Fitbit?</strong><br />
              A: If you do not have your own Fitbit, no worries! You may check one out at your fitness assessment.  Fitbits are complimentary for the duration of your program.
            </p>
            <p>
              <strong>Q:  May I continue to earn points as an alum?</strong><br />
              A: Yes.  You may continue to earn points after completing your program for remaining with any of your providers and scheduling post evaluations (every 15 weeks).
            </p>
          </div>
        ),
      }
    ]
  },
];

const blocks = [
  {
    borderColor: 'purple',
    title: 'Stage 1',
    text: 'This stage is all about getting you moving. We recommend 30-45 minutes of low to moderate intensity exercise 5 or 6 times per week.'
  },
  {
    borderColor: 'green',
    title: 'Stage 2',
    text: 'Stage 2 adds a few warm up exercises that you should do before your cardiovascular exercise. These will help with soreness and to keep your muscles loose.'
  },
  {
    borderColor: 'orange',
    title: 'Stage 3',
    text: 'Stage 3 adds a strength routine to stimulate the muscles and provide an additional workout challenge.'
  },
  {
    borderColor: 'red',
    title: 'Stage 4',
    text: 'Stage 4 maintains the intensity of your workout and adds in stretches. These exercises are perfect to do immediately after you have completed your strength training routine.'
  },
  {
    borderColor: 'lime',
    title: 'Stage 5',
    text: 'Stage 5 adds more minutes of cardio to each of your days — both strength and non-strength days.'
  },
  {
    borderColor: 'blue',
    title: 'Stage 6',
    text: 'In Stage 6 you will be adding foam rolling to your program, which will help to massage the muscles, maintain blood flow, and keep your muscles feeling loose.'
  },
  {
    borderColor: 'brown',
    title: 'Stage 7',
    text: 'In Stage 7, you will be adding intervals to your exercise program, which are designed to increase your heart rate and challenge your cardiovascular endurance.'
  }
]

export {
  page,
  sections,
  meta
};
