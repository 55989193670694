exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".BlogWidget__blogWidgetWrapper--1rR5G{padding:30px;box-shadow:0 1px 10px rgba(0,0,0,0.11);margin-top:30px}@media (max-width: 767px){.BlogWidget__blogWidgetWrapper--1rR5G{padding:0px;box-shadow:none;margin-top:0px}}\n", ""]);

// exports
exports.locals = {
	"blogWidgetWrapper": "BlogWidget__blogWidgetWrapper--1rR5G"
};