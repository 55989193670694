exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".StagesBlocks__blocksWrapper--FJx21{max-width:1200px;margin:0 auto;padding:0px 50px 65px;display:-webkit-box;display:-ms-flexbox;display:flex;-moz-justify-content:center;-ms-justify-content:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;flex-wrap:wrap}@media (max-width: 767px){.StagesBlocks__blocksWrapper--FJx21{padding:0px 0px 65px}}.StagesBlocks__blocksWrapper--FJx21 .StagesBlocks__block--2FlzA{width:250px;height:290px;border-radius:10px;box-shadow:0 2px 4px rgba(0,0,0,0.1);margin:15px 10px;padding:20px}.StagesBlocks__blocksWrapper--FJx21 .StagesBlocks__block--2FlzA.StagesBlocks__purple--3JQrP{border:3px solid #7E15D8}.StagesBlocks__blocksWrapper--FJx21 .StagesBlocks__block--2FlzA.StagesBlocks__green--1YrO_{border:3px solid #16B465}.StagesBlocks__blocksWrapper--FJx21 .StagesBlocks__block--2FlzA.StagesBlocks__orange--3l6Ir{border:3px solid #ED841B}.StagesBlocks__blocksWrapper--FJx21 .StagesBlocks__block--2FlzA.StagesBlocks__red--2KkF-{border:3px solid #F54545}.StagesBlocks__blocksWrapper--FJx21 .StagesBlocks__block--2FlzA.StagesBlocks__lime--3lBbU{border:3px solid #D7EF22}.StagesBlocks__blocksWrapper--FJx21 .StagesBlocks__block--2FlzA.StagesBlocks__blue--2ypem{border:3px solid #2C7DEB}.StagesBlocks__blocksWrapper--FJx21 .StagesBlocks__block--2FlzA.StagesBlocks__brown--3xNCL{border:3px solid #B48812}.StagesBlocks__blocksWrapper--FJx21 .StagesBlocks__block--2FlzA .StagesBlocks__info--2Wdp9 .StagesBlocks__title--3PgnJ{font-size:18px;font-weight:600;text-align:center;margin-bottom:10px}.StagesBlocks__blocksWrapper--FJx21 .StagesBlocks__block--2FlzA .StagesBlocks__info--2Wdp9 .StagesBlocks__text--fx8tg{font-size:16px}\n", ""]);

// exports
exports.locals = {
	"blocksWrapper": "StagesBlocks__blocksWrapper--FJx21",
	"block": "StagesBlocks__block--2FlzA",
	"purple": "StagesBlocks__purple--3JQrP",
	"green": "StagesBlocks__green--1YrO_",
	"orange": "StagesBlocks__orange--3l6Ir",
	"red": "StagesBlocks__red--2KkF-",
	"lime": "StagesBlocks__lime--3lBbU",
	"blue": "StagesBlocks__blue--2ypem",
	"brown": "StagesBlocks__brown--3xNCL",
	"info": "StagesBlocks__info--2Wdp9",
	"title": "StagesBlocks__title--3PgnJ",
	"text": "StagesBlocks__text--fx8tg"
};