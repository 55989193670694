import React from 'react';

const meta = {
  title: 'Medical Weight Loss Experts',
  description:
    'Work one-on-one with physicians, registered dietitians, personal trainers, and licensed counselors to guide your weight loss success.',
  keywords: ['weight loss', 'nutrition in bellevue']
};

const page = {
  name: 'Medical Weight Loss Experts',
  subTitle: 'Expert insight and support to help you achieve your goals.'
};

const sections = [
  {
    sectionExtraClassName: 'noPadding',
    textBlocks: [
      {
        align: 'left',
        text: (
          <ddiv>
            <p>
              Founded and guided by physicians, 20/20 LifeStyles gives you a
              team of experts whose sole focus is helping you lose weight and
              build habits to keep it off long-term. Working each week
              one-on-one, you’ll receive the motivation, accountability, and
              knowledge to succeed.
            </p>
            <p>
              The 20/20 LifeStyles team is comprised of physicians, registered
              dietitians, personal trainers, licensed counselors, as well as a
              support team of program coordinators and assessment team.
            </p>
          </ddiv>
        )
      }
    ]
  }
];

export { page, meta, sections };
