import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import logo from 'assets/images/2020logo.png';

const ldJson = {
  '@context': 'https://schema.org',
  '@type': 'LocalBusiness',
  'name': '20/20 LifeStyles',
  '@id': 'https://www.2020lifestyles.com',
  'url': 'https://www.2020lifestyles.com',
  'telephone': '+14258956508',
  'address': {
    '@type': 'PostalAddress',
    'streetAddress': '4455 148th Avenue Northeast',
    'addressLocality': 'Bellevue',
    'addressRegion': 'WA',
    'postalCode': ' 98007',
    'addressCountry': 'US'
  }
}

const MetaData = ({ meta, title }) => (
      <Helmet>
        <html lang='en' />
        <title>{`2020 Lifestyles | ${meta ? meta.title : title}`}</title>
        <meta name='description' content={meta && meta.description ? meta.description : '20/20 Lifestyles: The weight loss program developed by a doctor'} />
        <meta property='og:description' content={meta && meta.description ? meta.description : '20/20 Lifestyles: The weight loss program developed by a doctor'} />
        {meta && meta.keywords ?
          <meta name='keywords' content={meta.keywords.join(',')} /> : null
        }
        {meta.noFollow ? <meta name="robots" content="noindex,nofollow" /> : null}
      </Helmet>
);

MetaData.propTypes = {
  meta: PropTypes.object,
  title: PropTypes.string
};

export default MetaData;
