import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import {
  Sections,
  Breadcrumbs,
  PageTitle,
  MetaData
} from 'components';
import { page, sections, meta } from '../../mocks/pages/WhyItWorks';
import styles from './WhyItWorks.scss';

class WhyItWorks extends Component {
  static propTypes = {
    location: PropTypes.object
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const { location } = this.props;
    const newMeta = {
      ...meta,
      path: location.pathname
    }
    return (
      <div>
        <MetaData meta={newMeta} />
        <div className='noMobilePadding'>
          <div className={styles.breadcrumb}>
            <Breadcrumbs location={location} />
          </div>
          <div className={styles.pageTitle}>
            <PageTitle page={page} />
          </div>
          <Sections sections={sections} />
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(WhyItWorks);
