import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import {
  Breadcrumbs,
  PageTitle,
  MetaData,
  Sections
} from 'components';
import { ReferAFriendForm } from './components';
import { page, meta, sections } from '../../mocks/pages/ReferAFriend';
import styles from './ReferAFriendPage.scss';

class ReferAFriendPage extends Component {
  static propTypes = {
    location: PropTypes.object
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const { location } = this.props;
    return (
      <div>
        <MetaData meta={meta} />
        <div className='noMobilePadding'>
          <div className={styles.breadcrumb}>
            <Breadcrumbs location={location} />
          </div>
          <div className={styles.pageTitle}>
            <PageTitle page={page} />
          </div>
          <Sections sections={sections} />
          <ReferAFriendForm />
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(ReferAFriendPage);
