import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import {
  Sections,
  Breadcrumbs,
  PageTitle,
  MetaData,
  BMICalculator
} from 'components';
import { page, topSection, meta, bottomSection } from '../../mocks/pages/PricingPage';
import styles from './PricingPage.scss';

class PricingPage extends Component {
  static propTypes = {
    location: PropTypes.object
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const { location } = this.props;
    const newMeta = {
      ...meta,
      path: location.pathname
    }
    return (
      <div>
        <MetaData meta={newMeta} />
        <div className='noMobilePadding'>
          <div className={styles.breadcrumb}>
            <Breadcrumbs location={location} />
          </div>
          <div className={styles.pageTitle}>
            <PageTitle page={page} />
          </div>
          <Sections sections={topSection} />
          <BMICalculator />
          <Sections sections={bottomSection} />
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(PricingPage);
