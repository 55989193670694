import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { Titles } from './components';

import styles from './Filters.scss';

class Filters extends PureComponent {
  static propTypes = {
    titles: PropTypes.object.isRequired,
    expertsState: PropTypes.object.isRequired,
    setExpertsState: PropTypes.func
  };

  render() {
    const {
      titles,
      expertsState,
      setExpertsState
    } = this.props;
    return (
      <div className={cx('clearfix', styles.filters)}>
        <Titles
          expertsState={expertsState}
          titles={titles}
          setExpertsState={setExpertsState}
        />
      </div>
    );
  }
}

export default withStyles(styles)(Filters);
