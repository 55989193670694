import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import ReactPlayer from 'react-player';
import BrightcovePlayer from '@brightcove/react-player-loader';
import { LandingButton, VideosSlider } from 'components';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import styles from './Sections.scss';

class Sections extends PureComponent {
  render() {
    const { sections } = this.props;

    return (
      <Fragment>
        {sections.map((section, index) => {
          const {
            sectionExtraClassName,
            textBeforeTitle,
            pageHeading,
            title,
            type,
            textBlocks,
            videosSlider,
            bottomButton,
            noMobilePadding,
            sideBySideSections
          } = section;
          return (
            <div
              className={cx(styles.sectionWrapper,
                { [styles.blueSection]: type === 'blue' },
                { [styles.noTopPadding]: sectionExtraClassName === 'noTopPadding' },
                { [styles.noBottomPadding]: sectionExtraClassName === 'noBottomPadding' },
                { [styles.homeResourcesBlock]: sectionExtraClassName === 'homeResourcesBlock' },
                { [styles.noPadding]: sectionExtraClassName === 'noPadding' })}
              key={`index${index}`}
            >
              <div className={cx(styles.contentWrapper, { [styles.noMobilePadding]: noMobilePadding })}>
                {textBeforeTitle ? <div className={styles.beforeTitle}>{textBeforeTitle}</div> : null}
                {pageHeading ? <h1 className={styles.sectionTitle}>{pageHeading}</h1> : null}
                {title ? <h2 className={styles.sectionTitle}>{title}</h2> : null}
                {textBlocks ?
                  textBlocks.map((textBlock, index) => (
                    <div
                      className={cx(
                          styles.textBlock,
                          { [styles.alignCenter]: textBlock.align === 'center' },
                          { [styles.alignLeft]: textBlock.align === 'left' }
                        )}
                      key={`index${index}`}
                    >
                      {textBlock.title ? <div className={styles.title}>{textBlock.title}</div> : null}
                      {textBlock.text ? <div className={styles.text}>{textBlock.text}</div> : null}
                      {textBlock.image ? 
                        <img 
                          className={cx(
                            styles.image,
                            { [styles.alignCenter]: textBlock.image.align === 'center' },
                          )}
                          src={textBlock.image.src} 
                        /> : null
                      }
                      {textBlock.singleVideo ?
                        <div className={styles.videoFrameWrp} alt={textBlock.singleVideo.alt}>
                          {textBlock.singleVideo.youtube ?
                            <ReactPlayer
                              url={textBlock.singleVideo.youtube}
                              className={styles.video}
                              controls
                            /> : null
                          }
                          {textBlock.singleVideo.brightcove ?
                            <div className={styles.brightcovePlayer}>
                              <BrightcovePlayer
                                accountId='1257553580001'
                                videoId={textBlock.singleVideo.brightcove}
                              />
                            </div>: null
                          }
                        </div> : null
                      }
                      {textBlock.button ?
                        <div className={cx(styles.buttonWrapper, { [styles.centerButton]: textBlock.button.align })}>
                          <LandingButton
                            to={textBlock.button.link}
                            isExternal={textBlock.button.isExternal}
                            className={styles.button}
                            type={textBlock.button.type === 'blue' ? 'main' : 'light'}
                          >
                            {textBlock.button.text}
                          </LandingButton>
                        </div> : null
                      }
                    </div>
                  )) : <div />
                }
                {sideBySideSections ?
                  sideBySideSections.map((section, index) => (
                    <div
                      className={cx(
                          styles.sideBySideBlock,
                          { [styles.imageLeft]: section.imageAlign === 'left' },
                          { [styles.imageRight]: section.imageAlign === 'right' }
                        )}
                      key={`index${index}`}
                    >
                      <div className={styles.textColumn}>
                        {section.title ? <h2 className={styles.title}>{section.title}</h2> : null}
                        {section.text ? <div className={styles.text}>{section.text}</div> : null}
                      </div>
                      <div className={styles.imgColumn}>
                        {section.img ? <img className={styles.img} src={section.img} /> : null}
                      </div>
                    </div>
                  )) : <div />
                }
                {section.descriptionBlocks ?
                  <div className={styles.blocksWrapper}>
                    {section.descriptionBlocks.map((item, index) => (
                      <div className={styles.block} key={index}>
                        {item.link ?
                          <a
                            className={styles.imgWrapper}
                            href={item.link.to}
                            target={item.link.isExternal ? '_blank' : '_self'}
                            alt={item.image.alt}
                          >
                            <img className={styles.img} src={item.image.src} alt={item.image.alt}/>
                            <div className={styles.info}>
                              {item.title ? <div className={styles.infoTitle}>{item.title}</div> : <div />}
                              {item.text ? <div className={styles.description}>{item.text}</div> : <div />}
                            </div>
                          </a> :
                          <div className={styles.imgWrapper}>
                            <img className={styles.img} src={item.image.src} alt={item.image.alt}/>
                            <div className={styles.info}>
                              {item.title ? <div className={styles.infoTitle}>{item.title}</div> : <div />}
                              {item.text ? <div className={styles.description}>{item.text}</div> : <div />}
                            </div>
                          </div>
                        }
                      </div>
                    ))}
                  </div> : null
                }
              </div>
              {videosSlider ?
                <div className={styles.videosSlider}>
                  <VideosSlider videos={videosSlider} />
                </div> : null
              }
              {bottomButton ?
                <div className={styles.bottomButton}>
                  <LandingButton
                    to={bottomButton.link}
                    isExternal={bottomButton.isExternal}
                    className={styles.button}
                    type={type === 'blue' ? 'main' : 'light'}
                  >
                    {bottomButton.text}
                  </LandingButton>
                </div> : null
              }
            </div>
          );
        })}
      </Fragment>
    );
  }
}

Sections.propTypes = {
  sections: PropTypes.array
};

export default withStyles(styles)(Sections);
