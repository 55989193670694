import React from 'react';
import IconBase from 'react-icon-base';
import PropTypes from 'prop-types';

const ChevronUp = ({ color, ...props }) => (
  <IconBase viewBox='0 0 512 512' {...props}>
    <path
      fill={color}
      d='M264.5 163.5l122.8 122.8c4.7 4.7 4.7 12.3 0 17l-22.6 22.6c-4.7 4.7-12.3 4.7-17
      0L256 234.2l-91.7 91.7c-4.7 4.7-12.3 4.7-17 0l-22.6-22.6c-4.7-4.7-4.7-12.3 0-17l122.8-122.8c4.7-4.7 12.3-4.7
      17 0zM504 256c0 137-111 248-248 248S8 393 8 256 119 8 256 8s248 111 248 248zm-48 0c0-110.5-89.5-200-200-200S56
      145.5 56 256s89.5 200 200 200 200-89.5 200-200z'
    />
  </IconBase>
);

ChevronUp.propTypes = {
  color: PropTypes.string
};

ChevronUp.defaultProps = {
  color: '#b99765'
};

export default ChevronUp;
