exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ExpertsPage__trainersText--2WJVz{text-align:center;font-size:16px;margin-bottom:30px}.ExpertsPage__trainersText--2WJVz a{color:#3B97B8;font-weight:500}.ExpertsPage__trainersText--2WJVz a:hover{text-decoration:none;color:#0E3E61}.ExpertsPage__loader--2Sd4g{min-height:100px;display:-webkit-box;display:-ms-flexbox;display:flex;-moz-justify-content:center;-ms-justify-content:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-align-items:center;-moz-align-items:center;-ms-align-items:center;align-items:center}.ExpertsPage__loaderIcon--2AOin{height:100px}.notFoundMsg{padding:40px 20px 70px;text-align:center;color:#333;font-weight:500}\n", ""]);

// exports
exports.locals = {
	"trainersText": "ExpertsPage__trainersText--2WJVz",
	"loader": "ExpertsPage__loader--2Sd4g",
	"loaderIcon": "ExpertsPage__loaderIcon--2AOin"
};