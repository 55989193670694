import { composeReducer } from 'redux-compose-reducer';

const initialState = {
  data: {
    recipes: [],
    recipesTotalLength: 0,
    time_of_days: null,
    dishes: null,
    difficulties: null,
    stages: null,
    recipe: null,
    relatedRecipes: []
  },
  recipesState: {
    page: 1,
    time_of_day: null,
    dish: null,
    difficulty: null,
    stage: null
  },
  recipesLazyLoading: false,
  recipesLoading: false
};

function getRecipe(state, { data }) {
  const recipe = data[0];
  return {
    ...state,
    data: {
      ...state.data,
      recipe
    }
  };
}

function getRelatedRecipes(state, { data }) {
  const relatedRecipesArray = data.recipes;
  return {
    ...state,
    data: {
      ...state.data,
      relatedRecipes: relatedRecipesArray
    }
  };
}

function getRecipesFilters(state, { data }) {
  const time_of_days = data.recipes.filter(field => field.name === 'time_of_day')[0].choices;
  const dishes = data.recipes.filter(field => field.name === 'dish')[0].choices;
  const difficulties = data.recipes.filter(field => field.name === 'difficulty')[0].choices;
  const stages = data.recipes.filter(field => field.name === 'stage')[0].choices;
  return {
    ...state,
    data: {
      ...state.data,
      time_of_days,
      dishes,
      difficulties,
      stages
    }
  };
}

function getInitialRecipes(state, { data }) {
  const recipes = data.recipes;
  const count = data.count;
  return {
    ...state,
    data: {
      ...state.data,
      recipes,
      recipesTotalLength: count
    },
    recipesState: {
      ...state.recipesState,
      page: 1
    }
  };
}

function getAllRecipes(state, { data, page }) {
  const recipesArray = data.recipes;
  let recipes = [];
  if (page > 1) {
    const { recipes: recipesFromState } = state.data;
    recipes = recipesFromState.concat(recipesArray);
  } else {
    recipes = recipesArray;
  }
  return {
    ...state,
    data: {
      ...state.data,
      recipes,
      recipesTotalLength: data.count
    }
  };
}

function setRecipesState(state, { data }) {
  return {
    ...state,
    recipesState: {
      ...state.recipesState,
      ...data
    }
  };
}

function switchRecipesLazyLoading(state, { data }) {
  return {
    ...state,
    recipesLazyLoading: data
  };
}

function switchRecipesLoading(state, { data }) {
  return {
    ...state,
    recipesLoading: data
  };
}

function resetFilters(state) {
  return {
    ...state,
    recipesState: {
      ...initialState.recipesState,
      title: null
    },
    data: {
      ...state.data
    }
  };
}


export default composeReducer(
  'recipes',
  {
    getRecipesFilters,
    getInitialRecipes,
    getAllRecipes,
    setRecipesState,
    switchRecipesLazyLoading,
    switchRecipesLoading,
    resetFilters,
    getRecipe,
    getRelatedRecipes
  },
  initialState
);
