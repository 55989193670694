import React from 'react';

const meta = {
  title: 'Frequently Asked Questions',
  noFollow: true
  // description: 'Welcome to 2020 Lifestyles',
  // keywords: ["weight loss", "nutrition in bellevue"]
};

const page = {
  name: 'Frequently Asked Questions',
  subTitle: (
    <p>
      Find your answers here to the most asked questions about the 20/20
      LifeStyles program, getting started, tracking, insurance and more. If you
      need further assistance, please contact us at{' '}
      <a href='mailto:support@2020lifestyles.com'>support@2020lifestyles.com</a>
    </p>
  )
};

const accordions = [
  {
    title: 'HOW IS THE 20/20 LIFESTYLES PROGRAM DIFFERENT FROM OTHER PROGRAMS?',
    text: (
      <div>
        <p>
          20/20 LifeStyles is developed by a doctor and is the only program that
          offers one-on-one support from a team of experts. Your 20/20 team is
          physician led and includes personal trainers, registered dietitians,
          and licensed counselors. There’s no packaged food, no trendy diets, no
          gimmicks, surgery or medications used to help you lose weight and
          regain your health.
        </p>
        <p>
          Our program is a lifetime solution with long-lasting results. Once you
          leave the program, you’ll be empowered with the knowledge to change
          your life for good.
        </p>
      </div>
    )
  },
  {
    title: 'WHAT IS THE SUCCESS RATE OF 20/20 LIFESTYLES?',
    text: "We've helped thousands of people achieve life-changing results. The average weight loss is 43 lbs with weight loss over 200 lbs. 73% of our clients cure their Type II diabetes, 79% normalize their blood pressure, 61% normalize their cholesterol, and 83% avoid bariatric surgery."
  },
  {
    title: 'HOW DO I GET STARTED IN THE 20/20 LIFESTYLES PROGRAM?',
    text: 'All participants are required to attend a complimentary private consultation at PRO Club in Bellevue. Please call or visit the 20/20 Lifestyles Front Desk to reserve your space at (425) 861-6258. You may also schedule your consultation online.'
  },
  {
    title: 'WILL MY INSURANCE PAY FOR THE PROGRAM?',
    text: 'We accept most major insurance carriers. We will be happy to complete an insurance verification to determine potential coverage. Please keep in mind only the Counseling, Physician, and Dietitian are billable to insurance. Personal training is not.'
  },
  {
    title: 'ARE FINANCING OPTIONS AVAILABLE?',
    text: 'Yes, clients may take advantage of several financing options. After an initial down payment of at least $1500, clients may also elect to extend their payment plan for up to three years post program. Depending on the length of the payment period, the interest rate will vary from zero to four percent.'
  },
  {
    title: 'WILL I BE ABLE TO EAT OUT AND SOCIALIZE?',
    text: 'Yes. Dining out and socializing are important to the enjoyment of life. Our experts teach you how to dine out, enjoy celebrations, grocery shop, travel, and socialize in a variety of settings using healthy eating strategies that work for your lifestyle.'
  },
  {
    title: 'DO I HAVE TO COUNT CALORIES?',
    text: 'Your registered dietitian will develop a meal plan personalized for your caloric needs to achieve your individual weight loss goals. Using a meal tracker and following the plan is easy and doesn’t require you to count calories.'
  },
  {
    title: 'CAN I EAT CARBS?',
    text: 'The 20/20 LifeStyles program is designed around real food and some carbohydrates are part of a healthy diet. Your registered dietitians will teach you how to eat carbohydrates as well as healthy fats, snacks, sensible treats, and more. At first, your meal plan is more restrictive and as you progress, you’ll add more foods into your plan.'
  },
  {
    title: 'DO I HAVE TO EXERCISE?',
    text: 'Yes! Exercise is a key component to a healthy and active lifestyle. A diet program without exercise is dangerous for your body as you’ll lose muscle and permanently decrease your metabolism. Our personal trainers work with you one-on-one with a personalized fitness program that builds throughout your program. They are here for you to champion and coach you every step of the way. We’ve seen our clients go from not walking up the stairs to climbing mountains, running marathons, and more. And, what’s more, our clients report finding new joy in their lives from the strength, energy, and mobility they’ve achieved.'
  },
  {
    title: 'DO YOU PRESCRIBE MEDICATIONS?',
    text: 'Our program teaches you achievable lifestyle behaviors without the need for medication. Our program specializes in curing metabolic disorders such as Type II Diabetes, Hypertension, and High Cholesterol which thus eliminates the dependency of medications to treat these conditions.'
  },
  {
    title: 'HOW LONG IS LONG-TERM SUPPORT?',
    text: 'Most weight loss programs end their support as soon as you finish the program. 20/20 LifeStyles offers a 12-week maintenance program to help you transition back to your normal life and gives you ongoing access to dietitians, personal trainers, and licensed counselors.'
  },
  {
    title: 'HOW SOON CAN I START THE PROGRAM?',
    text: 'After you complete all required enrollment steps, we will then get you scheduled into the program. Prior to beginning with your Personal Trainer and Dietitian, you will need to complete your initial appointments. This includes meeting with your Lifestyle Counselor, your 20/20 Physician, and completing your initial testing. These appointments are 60-90 minutes long, and must be done in a specific order and at least 2 days apart. This allows time for all data to be processed.'
  }
];

export { page, accordions, meta };
