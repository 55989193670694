import { createTypes } from 'redux-compose-reducer';
import { get } from 'utils/wordPressApiRequester';
import { clearBlankParamsFromObject } from 'utils/clearBlankParamsFromObject';

export const TYPES = createTypes('experts', [
  'getExpertsFilters',
  'getInitialExperts',
  'getAllExperts',
  'setExpertsState',
  'switchExpertsLazyLoading',
  'switchExpertsLoading',
  'resetFilters'
]);

export const getExpertsFilters = () => async (dispatch) => {
  const route = 'powerapi/v1/siteacf';
  try {
    const { data } = await get(route);
    await dispatch({ type: TYPES.getExpertsFilters, data });
  } catch (e) {
    console.error(e);
  }
};

export const getInitialExperts = () => async (dispatch) => {
  try {
    dispatch({ type: TYPES.resetFilters });
    dispatch({ type: TYPES.switchExpertsLoading, data: true });
    await get('wp/v2/2020experts?per_page=4&page=1').then((res) => {
      dispatch({ type: TYPES.getAllExperts, data: res.data, page: 1 });
    });
    dispatch({ type: TYPES.switchExpertsLoading, data: false });
  } catch (e) {
    console.error(e);
  }
};

export const getAllExperts = ({
  title,
  page,
  isLoader
}) => (dispatch) => {
  const loaderType = isLoader ? TYPES.switchExpertsLazyLoading : TYPES.switchExpertsLoading;
  const params = {
    per_page: 4,
    page,
    title
  };
  const finalParams = clearBlankParamsFromObject(params);
  const queryString = Object.keys(finalParams).map(key => (`${key}=${finalParams[key]}`)).join('&');
  return Promise.resolve(dispatch({ type: loaderType, data: true }))
    .then(() => get(`wp/v2/2020experts?${queryString}&`))
    .then((res) => {
      dispatch({ type: TYPES.getAllExperts, data: res.data, page });
    })
    .then(() => {
      dispatch({ type: TYPES.switchExpertsLoading, data: false });
      dispatch({ type: TYPES.switchExpertsLazyLoading, data: false });
    })
    .catch((e) => {
      dispatch({ type: TYPES.switchExpertsLoading, data: false });
      dispatch({ type: TYPES.switchExpertsLazyLoading, data: false });
      // add error handler
      console.error(e);
      throw e;
    });
};

export const setExpertsState = (data, isLoader) => async (dispatch, getState) => {
  await dispatch({ type: TYPES.setExpertsState, data });
  const { expertsState } = getState().experts;
  const { page } = expertsState;
  const pageNumber = isLoader ? page : 1;
  await dispatch(getAllExperts({ ...expertsState, page: pageNumber, isLoader }));
};

export const resetFilters = () => async (dispatch, getState) => {
  dispatch({ type: TYPES.resetFilters });

  const { expertsState } = getState().experts;
  await dispatch(getAllExperts({ ...expertsState }));
};
