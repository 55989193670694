import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import {
  Sections,
  Breadcrumbs,
  PageTitle,
  MetaData
} from 'components';
import { page, sections, meta } from '../../mocks/pages/ProgramPage';
import styles from './ProgramPage.scss';

const ProgramPage = ({ location }) => (
  <div>
    <MetaData meta={meta} />
    <div className='noMobilePadding'>
      <div className={styles.breadcrumb}>
        <Breadcrumbs location={location} />
      </div>
      <div className={styles.pageTitle}>
        <PageTitle page={page} />
      </div>
      <Sections sections={sections} />
    </div>
  </div>
);

ProgramPage.propTypes = {
  location: PropTypes.object
};

export default withStyles(styles)(ProgramPage);
