import React from 'react';

const meta = {
  title: 'Success Stories',
  description: 'Gain inspiration from the thousands of successful weight loss clients',
  keywords: ["weight loss", "nutrition in bellevue"]
}

const page = {
  name: 'Weight Loss Success Stories',
  subTitle: (
    <div>Thousands of people have achieved remarkable results with 20/20 Lifestyles.
    And we’re not just talking about losing weight: Many of our clients cure their Type II
    diabetes, normalize their blood pressure and cholesterol, and avoid bariatric surgery.
    </div>
  )
};

export {
  page,
  meta
};
