import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { Select } from 'antd';

import styles from './Titles.scss';

const Option = Select.Option;

const dropdownAlign = { offset: [-2, 0] };

class Titles extends PureComponent {
  static propTypes = {
    titles: PropTypes.object,
    title: PropTypes.string,
    expertsState: PropTypes.object.isRequired,
    setExpertsState: PropTypes.func.isRequired
  };

  onChange = (value) => {
    const { setExpertsState } = this.props;

    setExpertsState({
      title: value === 'All Experts' ? null : value
    });
  };

  render() {
    const { titles } = this.props;
    const { title } = this.props.expertsState;

    return (
      <div className={styles.selectWrapper}>
        <Select
          className='select'
          style={{ width: '100%' }}
          placeholder='All Experts'
          dropdownAlign={dropdownAlign}
          dropdownClassName={styles.list}
          onChange={this.onChange}
          value={title || 'All Experts'}
        >
          <Option value='All Experts' key='All Experts'>All Experts</Option>
          {Object.keys(titles).map((keyName, i) => (
            <Option value={titles[keyName]} key={i}>{titles[keyName]}</Option>
          ))}
        </Select>
      </div>
    );
  }
}

export default withStyles(styles)(Titles);
