import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { getSeminars } from 'actions/seminars';
import {
  Breadcrumbs,
  PageTitle,
  MetaData
} from 'components';
import { AttendASeminarForm } from './components';
import { page, meta } from '../../mocks/pages/AttendASeminarPage';
import styles from './AttendASeminarPage.scss';

class AttendASeminarPage extends Component {
  static propTypes = {
    location: PropTypes.object,
    getSeminars: PropTypes.func.isRequired,
    seminars: PropTypes.arrayOf(PropTypes.object)
  };

  static fetching({ dispatch }) {
    return [
      dispatch(getSeminars())
    ];
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    const {
      getSeminars
    } = this.props;

    getSeminars();
  }

  render() {
    const { location, seminars } = this.props;

    return (
      <div>
        <MetaData meta={meta} />
        <div className='noMobilePadding'>
          <div className={styles.breadcrumb}>
            <Breadcrumbs location={location} />
          </div>
          <div className={styles.pageTitle}>
            <PageTitle page={page} />
          </div>
          {seminars.length > 0 ?
            <AttendASeminarForm seminar={seminars[0]} /> : <div />
          }
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(AttendASeminarPage);
