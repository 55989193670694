exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ProgramPage__breadcrumb--2Rm-H{margin:40px 50px 0px}@media (max-width: 550px){.ProgramPage__breadcrumb--2Rm-H{margin:40px 20px 0px}}.ProgramPage__pageTitle--30XcO{padding:0 20px}\n", ""]);

// exports
exports.locals = {
	"breadcrumb": "ProgramPage__breadcrumb--2Rm-H",
	"pageTitle": "ProgramPage__pageTitle--30XcO"
};