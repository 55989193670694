import React from 'react';
import { Redirect } from 'react-router';
import {
  homePath,
  successStoriesPath,
  expertsPath,
  resourcesToolPath,
  blogPath,
  recipesPath,
  faqPath,
  trackerPath,
  workoutsPath,
  programPagePath,
  whyItWorksPath,
  corporateSolutionsPath,
  aboutUsPath,
  contactUsPath,
  termsOfUsePath,
  termsOfSalePath,
  termsOfServicePath,
  privacyPath,
  careersPath,
  scheduleConsultationPath,
  maintenance,
  mealPlansPath,
  seminarPath,
  healthMonitoringPath,
  referAFriendPath,
  pricingPagePath,
  attendASeminar
} from 'utils/paths';
import {
  HomePage,
  SuccessStoriesPage,
  ExpertsPage,
  BlogPage,
  RecipesPage,
  RecipeTemplate,
  AttendASeminarPage
} from 'containers';
import {
  ResourcesToolsPage,
  FaqPage,
  TrackerPage,
  WorkoutsPage,
  ProgramPage,
  WhyItWorks,
  CorporateSolutions,
  ScheduleConsultationPage,
  MaintenancePage,
  MealPlansPage,
  HealthMonitoringPage,
  ReferAFriendPage,
  PricingPage
} from 'components';
import {
  AboutUs,
  ContactUs,
  TermsOfUse,
  Careers,
  TermsOfSale,
  TermsOfService,
  PrivacyPolicy
} from '../components/FooterPages';
import { routes as routeNames } from '../routes';

class RedirectToHomeError extends Error {
  constructor(message) {
    super(message);
    this.name = 'RedirectToHomeError';
  }
}

export const redirectToHome = () => {
  if (process.env.SERVER_SIDE) {
    // redirect on the server side
    throw new RedirectToHomeError('Redirect to home page');
  }
  return <Redirect to={homePath} />;
};

export const redirectToConsultation = () => {
  return <Redirect to={scheduleConsultationPath} />;
};

const routes = [
  {
    path: homePath,
    exact: true,
    component: HomePage
  },
  {
    path: maintenance,
    exact: true,
    component: MaintenancePage
  },
  {
    path: successStoriesPath,
    exact: true,
    component: SuccessStoriesPage
  },
  {
    path: expertsPath,
    exact: true,
    component: ExpertsPage
  },
  {
    path: resourcesToolPath,
    exact: true,
    component: ResourcesToolsPage
  },
  {
    path: blogPath,
    exact: true,
    component: BlogPage
  },
  {
    path: recipesPath,
    exact: true,
    component: RecipesPage
  },
  {
    path: `${routeNames.RESOURCES_AND_TOOLS_RECIPES}/:recipeSlug?`,
    component: RecipeTemplate,
    exact: true
  },
  {
    path: faqPath,
    exact: true,
    component: FaqPage
  },
  {
    path: trackerPath,
    exact: true,
    component: TrackerPage
  },
  {
    path: workoutsPath,
    exact: true,
    component: WorkoutsPage
  },
  {
    path: mealPlansPath,
    exact: true,
    component: MealPlansPage
  },
  {
    path: programPagePath,
    exact: true,
    component: ProgramPage
  },
  {
    path: whyItWorksPath,
    exact: true,
    component: WhyItWorks
  },
  {
    path: corporateSolutionsPath,
    exact: true,
    component: CorporateSolutions
  },
  {
    path: healthMonitoringPath,
    exact: true,
    component: HealthMonitoringPage
  },
  {
    path: pricingPagePath,
    exact: true,
    component: PricingPage
  },
  {
    path: aboutUsPath,
    exact: true,
    component: AboutUs
  },
  {
    path: contactUsPath,
    exact: true,
    component: ContactUs
  },
  {
    path: termsOfUsePath,
    exact: true,
    component: TermsOfUse
  },
  {
    path: termsOfSalePath,
    exact: true,
    component: TermsOfSale
  },
  {
    path: termsOfServicePath,
    exact: true,
    component: TermsOfService
  },
  {
    path: careersPath,
    exact: true,
    component: Careers
  },
  {
    path: privacyPath,
    exact: true,
    component: PrivacyPolicy
  },
  {
    path: scheduleConsultationPath,
    exact: true,
    component: ScheduleConsultationPage
  },
  {
    path: attendASeminar,
    exact: true,
    component: AttendASeminarPage
  },
  {
    path: referAFriendPath,
    exact: true,
    component: ReferAFriendPage
  },
  {
    path: seminarPath,
    component: redirectToConsultation
  },
  {
    path: '*',
    component: redirectToHome
  }
];

export default routes;
