exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Careers__breadcrumb--2PCor{margin:40px 50px 0px}@media (max-width: 550px){.Careers__breadcrumb--2PCor{margin:40px 20px 0px}}.Careers__pageTitle--1mTZ2{padding:0 20px}\n", ""]);

// exports
exports.locals = {
	"breadcrumb": "Careers__breadcrumb--2PCor",
	"pageTitle": "Careers__pageTitle--1mTZ2"
};