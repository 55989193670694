import React from 'react';

const meta = {
  title: 'Terms of Sales',
  noFollow: true
  // description: 'Welcome to 2020 Lifestyles',
  // keywords: ["weight loss", "nutrition in bellevue"]
}

const page = {
  name: 'Terms of Sale',
};

const sections = [
  {
    sectionExtraClassName: 'noTopPadding',
    textBlocks: [
      {
        align: 'left',
        text: (
          <div>
            <p>Last Updated: April 18, 2012</p>

            <p>THE LEGAL AGREEMENTS SET OUT BELOW GOVERN YOUR USE OF THE 20/20 LIFESTYLES STORE (“SERVICE”), WHICH IS PROVIDED BY PROFESSIONAL RECREATION ORGANIZATION, INC. (D/B/A 20/20 LIFESTYLES) (HEREINAFTER REFERRED TO AS “WE” OR “US”) AND YOUR PURCHASES MADE THROUGH THE SERVICE. TO AGREE TO THESE TERMS, CLICK "AGREE." IF YOU DO NOT AGREE TO THESE TERMS OF SALE, DO NOT CLICK "AGREE," AND DO NOT USE THE SERVICES.</p>

            <p>ELECTRONIC CONTRACTING</p>

            <p>Your use of the Service includes the ability to enter into agreements and/or to make transactions electronically. YOU ACKNOWLEDGE THAT YOUR ELECTRONIC SUBMISSIONS CONSTITUTE YOUR AGREEMENT AND INTENT TO BE BOUND BY AND TO PAY FOR SUCH AGREEMENTS AND TRANSACTIONS. YOUR AGREEMENT AND INTENT TO BE BOUND BY ELECTRONIC SUBMISSIONS APPLIES TO ALL RECORDS RELATING TO ALL TRANSACTIONS YOU ENTER INTO ON THIS SITE, INCLUDING NOTICES OF CANCELLATION, POLICIES, CONTRACTS, AND APPLICATIONS. In order to access and retain your electronic records, you may be required to have certain hardware and software, which are your sole responsibility.</p>

            <p>We are not responsible for typographic errors.</p>

            <p>YOU AGREE TO BE BOUND BY THESE TERMS OF SALE, THE TERMS AND CONDITIONS OF USE AND ALL OTHER APPLICABLE TERMS AND POLICIES (the foregoing collectively referred to herein as the “Agreement).</p>

            <p>20/20 Lifestyles is the provider of the Service, which permits you to purchase or rent digital content and other products and services ("20/20 Lifestyles Products") for end user use only under the terms and conditions set forth in this Agreement.</p>

            <p>ELIGIBILITY AND REQUIREMENTS FOR USE OF THE SERVICES</p>

            <p>This Service is available only for individuals who are 18 years of age or older, and by clicking “Agree” you represent you are 18 years of age or older.</p>

            <p>The Service is available to you only in the United States, its territories, and possessions. You agree not to use or attempt to use the Service from outside these locations. We may use technologies to verify your compliance.</p>

            <p>Use of the Service requires compatible devices, Internet access, and certain software (fees may apply); may require periodic updates; and may be affected by the performance of these factors. High-speed Internet access is strongly recommended for regular use and is required for video. The latest version of required software is recommended to access Service and may be required for certain transactions or features and to download 20/20 Lifestyles Products previously purchased from the Service. You agree that meeting these requirements, which may change from time to time, is your responsibility. The Service is not part of any other product or offering, and no purchase or obtaining of any other product shall be construed to represent or guarantee you access to the Service.</p>

            <p>You agree to provide accurate and complete information when you register with, and as you use, the Service ("Service Registration Data"), and you agree to update your Service Registration Data to keep it accurate and complete. You agree that we may store and use the Service Registration Data you provide for use in maintaining and billing fees to your Account.</p>

            <p>PRICING, AVAILABILITY & TAXES</p>

            <p>All prices for products and services (and any applicable taxes, shipping and handling charges) are shown in U.S. dollars. All items are subject to availability and we reserve the right to impose quantity limits on any order, to reject all or part of an order and to discontinue products or services without notice to you, even if you have already placed your order. All prices are subject to change without notice, and you agree that taxes may be adjusted from the amount shown on your screen at the time of checkout. Several factors may cause this, such as variances between processor programs and changes in tax rates.</p>

            <p>You are responsible for all sales, use and similar taxes payable in connection with your purchase of products or services. When we think we are required to do so, we will collect taxes in connection with your purchase.</p>

            <p>We do not collect sales or use taxes in all states. For states imposing sales or use taxes, your purchase is subject to use tax unless it is specifically exempt from taxation. Your purchase is not exempt merely because it is made over the Internet or by other remote means. Many states require purchasers to file a sales/use tax return at the end of the year reporting all of the taxable purchases that were not taxed and to pay on those purchases. Details of how to file these returns may be found at the website of your respective taxing authority.</p>

            <p>PAYMENT</p>

            <p>We currently accept payment by credit card. By submitting your order, you represent and warrant that you (i) are authorized to use the designated credit card and (ii) authorize us, or our third party payment processor, to charge your order (including taxes, shipping, handling and any other amounts described at the time of purchase) to that card. If the card cannot be verified, is invalid or is otherwise not acceptable, your order may be suspended or cancelled. You must resolve any problem we encounter in order to proceed with your order. In addition, by submitting an order, you authorize us, or our third party payment processor, to charge the amount you specify as the purchase amount and to process and store such payment information and any other related personal information.</p>

            <p>SHIPPING & HANDLING</p>

            <p>You agree to pay any applicable shipping and handling charges shown at the time you place your order. We reserve the right to increase, decrease, add or eliminate charges from time to time and without prior notice, so you agree to check all charges before placing your order. All tangible products are shipped by the carrier we select. At checkout, you will be prompted to choose a delivery time for the products you purchased. Shipping fees are based on delivery, weight and selected delivery time. On most purchases, your shipping fees will automatically compute at checkout, prior to the completion of your order. Generally, shipping is by standard ground delivery. Any shipping times shown on our websites or mobile applications are estimates only and actual delivery dates may vary.</p>

            <p>SUBSCRIPTIONS FOR DIGITAL CONTENT AND/OR SERVICES</p>

            <p>If you purchase a subscription for digital content (e.g., articles, newsletters etc.) and/or services (e.g., Instant Answers or Weekly Insights), you will be charged for your subscription on a recurring basis. You authorize us to charge applicable recurring subscription fees to your designated credit card. If your credit card information changes during the term of your subscription, you must immediately update the credit card information associated with your account. If we are unable to charge your credit card for an installment, we may (i) immediately suspend or terminate your subscription; (ii) seek collection of the outstanding amount owed under the subscription and/or (iii) seek legal action against you for breach of this Agreement. All subscription fees are subject to change on a prospective basis upon written notice from us. If you do not accept the new fees, you should terminate your subscription immediately.</p>

            <p>If you decide to cancel your subscription for digital content and/or services, you may notify us of your intent to cancel by emailing support@2020lifestyles.com. Cancellation will become effective at the end of your current billing cycle, and you will continue to have the same access to, and benefits of, your subscription for the remainder of that cycle.</p>

            <p>DIGITAL CONTENT RENTAL (Videos)</p>

            <p>If your purchase is for the rental of digital content, you may begin viewing the videos anytime after the purchase is complete. Once you've completed your order, return to 20/20 BeWell-20/20 LifeStyles Online. Your videos will be located in My Videos / My Orders. You can watch your video instantly on your computer, iPad, iPhone or iPod Touch. Once you have started playing a video, you have 24 hours to finish watching. Once you start watching your video, you can view the video as many times as you like within the 24 hour period. If you would like to watch a video after the initial 24 hour period has expired, you may rent it again for a discounted price. Digital content (videos) are viewable only by the registered user who originally rented the video. A high-speed connection to the internet is required for viewing.</p>

            <p>RETURNS</p>

            <p>You may return any unopened and unused products within thirty (30) days of your receipt of such products. We do not accept returns for opened or used products or for products that are not returned within the thirty (30) day timeframe.</p>

            <p>Return shipping and handling fees are complimentary when you use the return label included in your original order. To return your product(s):</p>

            <p>Complete the Return Form (enclosed in your package) and include it with the product(s) you are returning. Please include your order number, your name and your phone number.
            Return the product(s) in the original packaging, if available. Otherwise, pack your return in a well-padded envelope or box to prevent damage in transit.
            Place the return label on the outside of the package and drop the package off at the designated carrier's location.
            We do not accept returns for digital content and/or services that you purchase from us. In addition, any in-store purchases must be returned to the original retail location.</p>

            <p>REFUNDS</p>

            <p>If you return an unopened and unused product within thirty (30) days of receipt, you will receive a full product refund, less the original shipping and handling fees. Opened or used products, or those that are not returned within the thirty (30) day window, are not eligible for refunds.</p>

            <p>Refunds will be applied to the original method of payment. After receipt of the returned product, we may take up to thirty (30) days to process the refund. If you are a registered user, you will receive an email confirmation when your return is processed.</p>

            <p>We reserve the right to issue refunds or credits for digital content and/or services in our sole discretion. If we issue a refund or credit, we are under no obligation to issue the same or a similar refund or credit in the future.</p>

            <p>ERRORS</p>

            <p>We attempt to be as accurate as possible; however, we do not warrant that all of our product and service descriptions, photographs, pricing or other information is accurate, complete, reliable, current or error-free. In the event of an error, we reserve the right to correct such error and revise your order accordingly (including charging the correct price) or to cancel the order and refund any amount charged. Your sole remedy in the event of such error is to cancel your order and obtain a refund.</p>

            <p>USE OF PURCHASED OR RENTED CONTENT</p>

            <p>You agree that the Service and certain 20/20 Lifestyles Products include security technology that limits your use of 20/20 Lifestyles Products and that, whether or not 20/20 Lifestyles Products are limited by security technology, you shall use them in compliance with the applicable usage rules established by us and our licensors (“Usage Rules”), and that any other use of the 20/20 Lifestyles Products may constitute a copyright infringement. Any security technology is an inseparable part of the 20/20 Lifestyles Products. We reserve the right to modify the Usage Rules at any time. You agree not to violate, circumvent, reverse-engineer, decompile, disassemble, or otherwise tamper with any of the security technology related to such Usage Rules for any reason—or to attempt or assist another person to do so. Usage Rules may be controlled and monitored by us for compliance purposes, and we reserve the right to enforce the Usage Rules without notice to you. You agree not to access the Service by any means other than through software that is provided by us for accessing the Service. You shall not access or attempt to access an Account that you are not authorized to access. You agree not to modify the software in any manner or form, or to use modified versions of the software, for any purposes including obtaining unauthorized access to the Service. Violations of system or network security may result in civil or criminal liability.</p>

            <p>USAGE RULES</p>
            <ul>
              <li>You shall be authorized to use 20/20 Lifestyles Products only for personal, noncommercial use.</li>
              <li>You shall not be authorized to use copy, store, and burn 20/20 Lifestyles Products except as the Service allows for viewing of Digital Content pursuant to a valid subscription or rental purchase.</li>
              <li>You shall not be authorized to sell, rent, lease, distribute, broadcast, sublicense or otherwise assign any right to the Digital Content to any third party.</li>
              <li>You shall not remove any proprietary notices or labels on the Digital Content.</li>
              <li>You shall not attempt to disable, bypass, modify, defeat, or otherwise circumvent any digital rights management system used as part of the Service or attempt to capture, copy or download an Digital Content.</li>
              <li>You shall not use the Video Service or Digital Content for any commercial or illegal purpose.</li>
              <li>You acknowledge that, because some aspects of the Service, 20/20 Lifestyles Products, and administration of the Usage Rules entails our ongoing involvement, if we change any part of or discontinues the Service, which we may do at in our sole discretion, you may not be able to use 20/20 Lifestyles Products to the same extent as prior to such change or discontinuation, and you agree that we shall have no liability to you in such case.</li>
            </ul>
            <p>THIRD-PARTY MATERIALS</p>

            <p>Certain content, 20/20 Lifestyles Products, and services available via the Service may include materials from third parties. We may provide links to third-party websites as a convenience to you. You agree that we are not responsible for examining or evaluating the content or accuracy and we do not warrant and will not have any liability or responsibility for any third-party materials or websites, or for any other materials, products, or services of third parties. You agree that you will not use any third-party materials in a manner that would infringe or violate the rights of any other party and that we are not in any way responsible for any such use by you.</p>

            <p>IMPORTANT SAFETY INFORMATION</p>

            <p>To avoid muscle, joint, or eye strain during your use of the products offered through the Service, you should always take frequent breaks, and take a longer rest if you experience any soreness, fatigue, or discomfort. A very small percentage of people may experience seizures or blackouts when exposed to flashing lights or patterns, including but not limited to while playing video games or watching videos. Symptoms may include dizziness, nausea, involuntary movements, loss of awareness, altered vision, tingling, numbness, or other discomforts. Consult a doctor before using the products offered through the Service if you have ever suffered these or similar symptoms, and stop using such products immediately and see a doctor if they occur during your use of such products.</p>

            <p>INTELLECTUAL PROPERTY</p>

            <p>You agree that the Service, including but not limited to 20/20 Lifestyles Products, graphics, user interface, audio clips, video clips, editorial content, and the scripts, applications and software used to implement the Service, contains proprietary information and material that is owned by Professional Recreation Organization, Inc. and/or its licensors, and is protected by applicable intellectual property and other laws, including but not limited to copyright. You agree that you will not use such proprietary information or materials in any way whatsoever except for use of the Service in compliance with this Agreement. No portion of the Service may be reproduced in any form or by any means, except as expressly permitted in these terms. You agree not to modify, rent, lease, loan, sell, distribute, or create derivative works based on the Service in any manner, and you shall not exploit the Service in any unauthorized way whatsoever, including, but not limited to, by trespass or burdening network capacity.</p>

            <p>Notwithstanding any other provision of this Agreement, Professional Recreation Organization, Inc. and its licensors reserve the right to change, suspend, remove, or disable access to any 20/20 Lifestyles Products, content, or other materials comprising a part of the Service at any time without notice. In no event will we be liable for making these changes. We may also impose limits on the use of or access to certain features or portions of the Service, in any case and without notice or liability.</p>

            <p>All copyrights in and to the Service (including the compilation of content, postings, links to other Internet resources, and descriptions of those resources) and related software are owned by Professional Recreation Organization, Inc. and/or its licensors, who reserve all their rights in law and equity. THE USE OF THE SOFTWARE OR ANY PART OF THE SERVICE, EXCEPT FOR USE OF THE SERVICE AS PERMITTED IN THIS AGREEMENT, IS STRICTLY PROHIBITED AND INFRINGES ON THE INTELLECTUAL PROPERTY RIGHTS OF OTHERS AND MAY SUBJECT YOU TO CIVIL AND CRIMINAL PENALTIES, INCLUDING POSSIBLE MONETARY DAMAGES, FOR COPYRIGHT INFRINGEMENT.</p>

            <p>“20 20 Lifestyles”, “20/20 Be Well”, “20/20 Kids”, “My Best Ten”, and other Professional Recreation Organization, Inc. trademarks, service marks, graphics, and logos used in connection with the Service are trademarks or registered trademarks of Professional Recreation Organization, Inc. in the U.S. and/or other countries. Other trademarks, service marks, graphics, and logos used in connection with the Service may be the trademarks of their respective owners. You are granted no right or license with respect to any of the aforesaid trademarks and any use of such trademarks.</p>

            <p>TERMINATION</p>

            <p>If you fail, or we suspect that you have failed, to comply with any of the provisions of this Agreement, we may, at our sole discretion, without notice to you may: (i) terminate this Agreement and/or your Account, and you will remain liable for all amounts due under your Account up to and including the date of termination; and/or (ii) terminate the license to the software; and/or (iii) preclude access to the Service (or any part thereof).</p>

            <p>We reserve the right to modify, suspend, or discontinue the Service (or any part or content thereof) at any time with or without notice to you, and we will not be liable to you or to any third party should we exercise such rights.</p>

            <p>DISCLAIMER OF WARRANTIES; LIABILITY LIMITATION</p>

            <p>PROFESSIONAL RECREATION ORGANIZATION, INC. DOES NOT GUARANTEE, REPRESENT, OR WARRANT THAT YOUR USE OF THE SERVICE WILL BE UNINTERRUPTED OR ERROR-FREE, AND YOU AGREE THAT FROM TIME TO TIME WE MAY REMOVE THE SERVICE FOR INDEFINITE PERIODS OF TIME, OR CANCEL THE SERVICE AT ANY TIME, WITHOUT NOTICE TO YOU.</p>

            <p>YOU EXPRESSLY AGREE THAT YOUR USE OF, OR INABILITY TO USE, THE SERVICE IS AT YOUR SOLE RISK. THE SERVICE AND ALL PRODUCTS AND SERVICES DELIVERED TO YOU THROUGH THE SERVICE ARE (EXCEPT AS EXPRESSLY STATED BY US) PROVIDED "AS IS" AND "AS AVAILABLE" FOR YOUR USE, WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING ALL IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NONINFRINGEMENT. BECAUSE SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES, THE ABOVE EXCLUSION OF IMPLIED WARRANTIES MAY NOT APPLY TO YOU.</p>

            <p>IN NO CASE SHALL PROFESSIONAL RECREATION ORGANIZATION, INC., ITS DIRECTORS, OFFICERS, EMPLOYEES, AFFILIATES, AGENTS, CONTRACTORS, OR LICENSORS BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, PUNITIVE, SPECIAL, OR CONSEQUENTIAL DAMAGES ARISING FROM YOUR USE OF ANY OF THE SERVICE OR FOR ANY OTHER CLAIM RELATED IN ANY WAY TO YOUR USE OF THE SERVICE, INCLUDING, BUT NOT LIMITED TO, ANY ERRORS OR OMISSIONS IN ANY CONTENT, OR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT (OR PRODUCT) POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SERVICE, EVEN IF ADVISED OF THEIR POSSIBILITY. BECAUSE SOME STATES OR JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR THE LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, IN SUCH STATES OR JURISDICTIONS, OUR LIABILITY SHALL BE LIMITED TO THE EXTENT PERMITTED BY LAW.</p>

            <p>PROFESSIONAL RECREATION ORGANIZATION, INC. SHALL USE REASONABLE EFFORTS TO PROTECT INFORMATION SUBMITTED BY YOU IN CONNECTION WITH THE SERVICE, BUT YOU AGREE THAT YOUR SUBMISSION OF SUCH INFORMATION IS AT YOUR SOLE RISK, AND WE HEREBY DISCLAIM ANY AND ALL LIABILITY TO YOU FOR ANY LOSS OR LIABILITY RELATING TO SUCH INFORMATION IN ANY WAY.</p>

            <p>PROFESSIONAL RECREATION ORGANIZATION, INC. DOES NOT REPRESENT OR GUARANTEE THAT THE SERVICE WILL BE FREE FROM LOSS, CORRUPTION, ATTACK, VIRUSES, INTERFERENCE, HACKING, OR OTHER SECURITY INTRUSION, AND WE DISCLAIM ANY LIABILITY RELATING THERETO. SOME PRODUCTS CAN BE DOWNLOADED ONLY ONCE; AFTER BEING DOWNLOADED, THEY CANNOT BE REPLACED IF LOST FOR ANY REASON. YOU SHALL BE RESPONSIBLE FOR BACKING UP YOUR OWN SYSTEM, INCLUDING ANY PRODUCTS PURCHASED OR RENTED FROM THE SERVICE.</p>

            <p>CLASS ACTION WAIVER</p>

            <p>YOU AND PROFESSIONAL RECREATION ORGANIZATION, INC. ("PRO") AGREE THAT ANY PROCEEDINGS TO RESOLVE OR LITIGATE ANY DISPUTE, WHETHER IN ARBITRATION, IN COURT, OR OTHERWISE, WILL BE CONDUCTED SOLELY ON AN INDIVIDUAL BASIS, AND THAT NEITHER YOU NOR PRO WILL SEEK TO HAVE ANY DISPUTE HEARD AS A CLASS ACTION, A REPRESENTATIVE ACTION, A COLLECTIVE ACTION, A PRIVATE ATTORNEY-GENERAL ACTION, OR IN ANY PROCEEDING IN WHICH YOU OR PRO ACTS OR PROPOSES TO ACT IN A REPRESENTATIVE CAPACITY. YOU AND PRO FURTHER AGREE THAT NO ARBITRATION OR PROCEEDING WILL BE JOINED, CONSOLIDATED, OR COMBINED WITH ANOTHER ARBITRATION OR PROCEEDING WITHOUT THE PRIOR WRITTEN CONSENT OF YOU, PRO, AND ALL PARTIES TO ANY SUCH ARBITRATION OR PROCEEDING.</p>

            <p>WAIVER AND INDEMNITY</p>

            <p>BY USING THE SERVICE, YOU AGREE, TO THE EXTENT PERMITTED BY LAW, TO INDEMNIFY AND HOLD PROFESSIONAL RECREATION ORGANIZATION, INC., ITS DIRECTORS, OFFICERS, EMPLOYEES, AFFILIATES, AGENTS, CONTRACTORS, AND LICENSORS HARMLESS WITH RESPECT TO ANY CLAIMS ARISING OUT OF YOUR BREACH OF THIS AGREEMENT, YOUR USE OF THE SERVICE, OR ANY ACTION TAKEN BY US AS PART OF OUR INVESTIGATION OF A SUSPECTED VIOLATION OF THIS AGREEMENT OR AS A RESULT OF A FINDING OR DECISION BY US THAT A VIOLATION OF THIS AGREEMENT HAS OCCURRED. THIS MEANS THAT YOU CANNOT SUE OR RECOVER ANY DAMAGES FROM PROFESSIONAL RECREATION ORGANIZATION, INC., ITS DIRECTORS, OFFICERS, EMPLOYEES, AFFILIATES, AGENTS, CONTRACTORS, AND LICENSORS AS A RESULT OF ITS DECISION TO REMOVE OR REFUSE TO PROCESS ANY INFORMATION OR CONTENT, TO WARN YOU, TO SUSPEND OR TERMINATE YOUR ACCESS TO THE SERVICE, OR TO TAKE ANY OTHER ACTION DURING THE INVESTIGATION OF A SUSPECTED VIOLATION OR AS A RESULT OF OUR CONCLUSION THAT A VIOLATION OF THIS AGREEMENT HAS OCCURRED. THIS WAIVER AND INDEMNITY PROVISION APPLIES TO ALL VIOLATIONS DESCRIBED IN OR CONTEMPLATED BY THIS AGREEMENT.</p>

            <p>NO MEDICAL ADVICE</p>

            <p>The Service and the 20/20 LifeStyles Products are not intended to and do not provide medical advice. All material appearing on or distributed through the Service, including but not limited to Digital Content, products, text, graphics, images, video, digital media and third-party content, (collectively “Content”) is for your personal, informational and noncommercial use only and is not intended as medical advice or to be a substitute for professional medical advice, diagnosis or treatment. Always seek the advice of your physician or other qualified health provider with any questions you may have regarding a medical condition. Never disregard professional medical advice or delay in seeking it because of something you have read, heard or viewed directly or indirectly through the Service or the Content.</p>

            <p>If you think you may have a medical emergency, call you doctor or 911 immediately. Reliance on any Content or other information provided by us, the Service, our employees, our agents, other users of the Service or other persons appearing on or contributing to the Services at our invitation is solely at your own risk.</p>

            <p>CHANGES</p>

            <p>We reserve the right to change or modify this Agreement at any time and in our sole discretion. Any changes or modifications will be effective immediately upon posting the revised Agreement to our websites and/or mobile applications and you waive any right you may have to receive specific notice of such changes or modifications. Your continued use of the Service following the posting of changes or modifications will confirm your acceptance of such changes or modifications; therefore, you should frequently review this Agreement and any other applicable policies to understand the terms and conditions that apply to your use of the Service. If you do not agree to the amended terms, conditions and notices you must stop using the Service. Any use of the Service in violation of this Agreement may result in, among other things, termination or suspension of your right to use the Service. We recommend you review this Agreement and all other applicable policies prior to each purchase you make. If you have any questions regarding this Agreement, please contact us at support@2020lifestyles.com.</p>

            <p>MISCELLANEOUS</p>

            <p>This Agreement constitutes the entire agreement between you and Professional Recreation Organization, Inc. and governs your use of the Service, superseding any prior agreements between you and Professional Recreation Organization, Inc. You also may be subject to additional terms and conditions that may apply when you use affiliate services, third-party content, or third-party software. If any part of this Agreement is held invalid or unenforceable, that portion shall be construed in a manner consistent with applicable law to reflect, as nearly as possible, the original intentions of the parties, and the remaining portions shall remain in full force and effect. Our failure to enforce any right or provisions in this Agreement will not constitute a waiver of such or any other provision. We will not be responsible for failures to fulfill any obligations due to causes beyond our control.</p>

            <p>The Service is operated by Professional Recreation Organization, Inc. from its offices in the United States. You agree to comply with all local, state, federal, and national laws, statutes, ordinances, and regulations that apply to your use of the Service. All transactions on the Service are governed by Washington law, without giving effect to its conflict of law provisions. Your use of the Service may also be subject to other laws. You expressly agree that exclusive jurisdiction and venue for any claim or dispute with Professional Recreation Organization, Inc. or relating in any way to your use of the Service shall be only in the state and federal courts in King County in the State of Washington. Risk of loss and title for all electronically delivered transactions pass to the purchaser in Washington upon electronic transmission to the recipient. No employee or agent of Professional Recreation Organization, Inc. has the authority to vary this Agreement.</p>

            <p>We may notify you with respect to the Service by sending an email message to your Account email address or a letter via postal mail to your Account mailing address, or by a posting on the Service. Notices shall become effective immediately.</p>

            <p>We reserve the right to take steps we believe are reasonably necessary or appropriate to enforce and/or verify compliance with any part of this Agreement. You agree that we have the right, without liability to you, to disclose any Registration Data and/or Account information to law enforcement authorities, government officials, and/or a third party, as we believe is reasonably necessary or appropriate to enforce and/or verify compliance with any part of this Agreement (including but not limited to our right to cooperate with any legal process relating to your use of the Service and/or 20/20 Lifestyles Products, and/or a third-party claim that your use of the Service and/or 20/20 Lifestyles Products is unlawful and/or infringes such third party's rights).</p>
          </div>
        ),
      },
    ]
  },
];

export {
  page,
  sections,
  meta
};
