import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import BrightcovePlayer from '@brightcove/react-player-loader';
import { getStoriesForSlider } from 'actions/successStories';
import { getPostsForWidget, getPostsFilters } from 'actions/posts';
import { routes } from 'routes';
import loader from 'assets/images/2020lifestyles-loader.gif';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { Sections, MetaData } from 'components';
import {
  SuccessStoriesSlider,
  BlogWidget
} from './components';
import { sections, meta, topSections } from '../../mocks/pages/HomePage';
import styles from './HomePage.scss';

const ACCOUNT_ID = '1257553580001';

class HomePage extends PureComponent {
  static propTypes = {
    getStoriesForSlider: PropTypes.func.isRequired,
    storiesForSlider: PropTypes.arrayOf(PropTypes.object),
    getPostsForWidget: PropTypes.func.isRequired,
    getPostsFilters: PropTypes.func.isRequired,
    postsForWidget: PropTypes.arrayOf(PropTypes.object),
    categories: PropTypes.object
  };

  static fetching({ dispatch }) {
    return [
      dispatch(getStoriesForSlider()),
      dispatch(getPostsForWidget()),
      dispatch(getPostsFilters())
    ];
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const {
      getStoriesForSlider,
      getPostsForWidget,
      getPostsFilters
    } = this.props;

    getStoriesForSlider();
    getPostsFilters();
    getPostsForWidget();
  }

  render() {
    const {
      storiesForSlider,
      postsForWidget,
      categories
    } = this.props;
    return (
      <div>
        <MetaData meta={meta} />
        <div className={styles.heroVideo}>
          <BrightcovePlayer
              accountId={ACCOUNT_ID}
              videoId='6322241066112'
              options={{
                autoplay: true,
                loop: true,
                muted: true
              }}
          />
        </div>
        <Sections sections={topSections} />
        <div className={styles.successStoriesSection}>
          <h2 className={styles.successStoriesHeading}>Success Stories</h2>
          <div className={styles.successStoriesContainer}>
            {storiesForSlider.length ?
              <SuccessStoriesSlider storiesForSlider={storiesForSlider} /> :
              <div className={styles.loader}>
                <img src={loader} alt='loader' className={styles.loaderIcon} />
              </div>
            }
          </div>
          <Link key='success-stories' className={styles.button} to={routes.SUCCESS_STORIES}>View All Stories</Link>
        </div>
        <Sections sections={sections} />
        {postsForWidget.length && categories ?
          <div className={styles.blogWidgetSection}>
            <h2 className={styles.blogWidgetHeading}>Knowledge center</h2>
            <BlogWidget categories={categories} postsForWidget={postsForWidget} />
          </div> : null
        }
      </div>
    );
  }
}

export default withStyles(styles)(HomePage);
