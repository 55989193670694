import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import PropTypes from 'prop-types';
import { Tabs } from 'antd';

import styles from './WidgetTabs.scss';

const { TabPane } = Tabs;

const WidgetTabs = props => (
  <Tabs
    animated={{
      inkBar: false,
      tabPane: false
    }}
    className={styles.tabsWrapper}
    defaultActiveKey='0'
  >
    {props.children.map((child, i) => <TabPane key={i} tab={child.props.tab}>{child}</TabPane>)}
  </Tabs>
);

WidgetTabs.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export default withStyles(styles)(WidgetTabs);
