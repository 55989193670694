import Axios from 'axios';
import qs from 'qs';

const wordPressAxios = Axios.create({
  baseURL: 'https://360degrees.blog/wp-json',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  },
  responseType: 'json',
  timeout: 60000
});

wordPressAxios.interceptors.request.use((config) => {
  if (config.method === 'get') {
    config.params = {
      ...config.params
    };
    config.paramsSerializer = params => qs.stringify(params, { encode: true });
    if (config.arrayFormat) {
      config.paramsSerializer = params => qs.stringify(params, { arrayFormat: config.arrayFormat, encode: true });
    }
  }
  return config;
});

wordPressAxios.interceptors.response.use(
  response => response,
  async (error) => {
    const { response } = error;
    if (process.env.SERVER_SIDE && error.code === 'ECONNABORTED') {
      return Promise.reject(error);
    }

    return Promise.reject(response);
  }
);

export default wordPressAxios;
