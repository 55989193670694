exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Icon__iconWrapper--3Fuin{display:inline-block;line-height:0}.Icon__defaultIconWrapper--16jXm{display:inline-block;line-height:0;font-size:50px}\n", ""]);

// exports
exports.locals = {
	"iconWrapper": "Icon__iconWrapper--3Fuin",
	"defaultIconWrapper": "Icon__defaultIconWrapper--16jXm"
};