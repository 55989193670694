import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import ReactPlayer from 'react-player';
import BrightcovePlayer from '@brightcove/react-player-loader';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { Modal } from 'components';
import styles from './ExpertCard.scss';
import placeholderImg from '../../../../../../assets/images/person-placeholder-5.png';

const overlayStyles = {
  backgroundColor: 'rgba(0, 0, 0, 0.75)'
};

class ExpertCard extends Component {
  state = {
    visibleExpert: false
  };

  openexpertModal = () => {
    this.setState({
      visibleExpert: true
    });
  };
  closeexpertModal = () => {
    this.setState({
      visibleExpert: false
    });
  };

  render() {
    const { visibleExpert } = this.state;
    const {
      expert
    } = this.props;
    return (
      <Fragment>
        <div className={styles.card}>
          {expert.featured_image_full ?
            <img
              className={styles.thumbImg}
              src={expert.featured_image_full}
              alt={parse(expert.title.rendered)}
            /> :
            <img
              className={styles.thumbImg}
              src={placeholderImg}
              alt={parse(expert.title.rendered)}
            />
          }
          <div className={styles.content}>
            <h3 className={styles.title}>{parse(expert.title.rendered)}</h3>
            <h4 className={styles.subTitle}>{expert.ACF.title}</h4>
            <button className={styles.button} onClick={() => this.openexpertModal()}>View Bio</button>
          </div>
        </div>
        {visibleExpert ?
          <Modal
            visible={visibleExpert}
            closeModal={this.closeexpertModal}
            wrapClassName={styles.modalWrapper}
            maskStyle={overlayStyles}
            width={1150}
            className={styles.expertModal}
          >
            <div className={styles.textWrapper}>
              {expert.featured_image_full ?
                <img
                  className={styles.img}
                  src={expert.featured_image_full}
                  alt={parse(expert.title.rendered)}
                /> :
                <img
                  className={styles.img}
                  src={placeholderImg}
                  alt={parse(expert.title.rendered)}
                />
              }
              <div className={styles.content}>
                <h2 className={styles.expertName}>{parse(expert.title.rendered)}</h2>
                <h4 className={styles.expertTitle}>{expert.ACF.title}</h4>
                <div className={cx(styles.description)}>
                  <div>{parse(expert.content.rendered)}</div>
                  <div className={styles.expertMedia}>
                    {expert.ACF.video_link ?
                      <div className={styles.videoFrameWrp}>
                        {expert.ACF.video_link.indexOf("youtube") > -1 ?
                          <ReactPlayer
                            url={expert.ACF.video_link}
                            className={styles.video}
                            controls
                          /> : null
                        }
                        {expert.ACF.video_link.indexOf("brightcove") > -1 ?
                          <div className={styles.brightcovePlayer}>
                            <BrightcovePlayer
                              accountId='1257553580001'
                              videoId={expert.ACF.video_link.split('?videoId=')[1]}
                            />
                          </div>: null
                        }
                      </div> : null
                    }
                  </div>
                </div>
              </div>
            </div>
          </Modal> : <div />
        }
      </Fragment>
    );
  }
}

ExpertCard.propTypes = {
  expert: PropTypes.object
};

export default withStyles(styles)(ExpertCard);
