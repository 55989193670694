import React from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { Link } from 'react-router-dom';
import { routes } from 'routes';
import recipePlaceholder from 'assets/images/recipe-place-holder.png';
import styles from './RecipeCard.scss';

const RecipeCard = ({ recipe }) => (
  <Link to={`${routes.RESOURCES_AND_TOOLS_RECIPES}/${recipe.slug}`} className={styles.recipeLink}>
    <div className={styles.card}>
      <div className={styles.content}>
        <h3 className={styles.title}>{parse(recipe.title.rendered)}</h3>
        <div className={styles.meta}>
          {recipe.ACF.time_of_day.map((time, index) => (
            <div className={styles.acf} key={index}>
              <p>{time}</p><span>|</span>
            </div>
          ))}
          {recipe.ACF.dish.map((item, index) => (
            <div className={styles.acf} key={index}>
              <p>{item}</p><span>|</span>
            </div>
          ))}
          <div className={styles.acf}>
            <p>{recipe.ACF.difficulty}</p><span>|</span>
          </div>
          <div className={styles.acf}>
            <p>Stage {recipe.ACF.stage}</p>
          </div>
        </div>
      </div>
      {recipe.featured_image ?
        <img
          className={styles.thumbImg}
          src={recipe.featured_image_medium}
          alt={parse(recipe.title.rendered)}
        /> :
        <div
          className={styles.thumbImg}
          style={{ backgroundImage: `url(${recipePlaceholder})` }}
        />
      }
    </div>
  </Link>
);

RecipeCard.propTypes = {
  recipe: PropTypes.object
};

export default withStyles(styles)(RecipeCard);
