import wordPressAxios from './wordPressAxios';

export function get(url, data = {}, options = {}) {
  return wordPressAxios.get(url, { params: data, ...options });
}

/**
 * Send POST request to inside of project
 * @param  {String} url       Relative path
 * @param  {Object} data      JSON data
 * @param  {Object} options   Config for requester
 * @return {Promise}          Thenable/Catheable
 */
export function post(url, data = {}, options = {}) {
  return wordPressAxios.post(url, data, options);
}

/**
 * Send PUT request to inside of project
 * @param  {String} url       Relative path
 * @param  {Object} data      JSON data
 * @param  {Object} options   Config for requester
 * @return {Promise}          Thenable/Catheable
 */
export function put(url, data = {}, options = {}) {
  return wordPressAxios.put(url, data, options);
}

/**
 * Send PATCH request to inside of project
 * @param  {String} url       Relative path
 * @param  {Object} data      JSON data
 * @param  {Object} options   Config for requester
 * @return {Promise}          Thenable/Catheable
 */
export function patch(url, data = {}, options = {}) {
  return wordPressAxios.patch(url, data, options);
}

/**
 * Send DELETE request to inside of project
 * @param  {String} url       Relative path
 * @param  {Object} data      JSON data
 * @param  {Object} options   Config for requester
 * @return {Promise}          Thenable/Catheable
 */
export function destroy(url, data = {}, options = {}) {
  return wordPressAxios.delete(url, data, options);
}
