exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".TermsOfSale__breadcrumb--2vOBW{margin:40px 50px 0px}@media (max-width: 550px){.TermsOfSale__breadcrumb--2vOBW{margin:40px 20px 0px}}.TermsOfSale__pageTitle--1dUCj{padding:0 20px}\n", ""]);

// exports
exports.locals = {
	"breadcrumb": "TermsOfSale__breadcrumb--2vOBW",
	"pageTitle": "TermsOfSale__pageTitle--1dUCj"
};