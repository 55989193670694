// Navigation paths
export const homePath = '/';
export const successStoriesPath = '/success-stories';
export const expertsPath = '/experts';
export const resourcesToolPath = '/resources-and-tools';
export const blogPath = '/resources-and-tools/blog';
export const recipesPath = '/resources-and-tools/recipes';
export const faqPath = '/resources-and-tools/faq';
export const trackerPath = '/resources-and-tools/tracker';
export const workoutsPath = '/resources-and-tools/work-outs';
export const rewardsPath = '/resources-and-tools/rewards';
export const programPagePath = '/program-information';
export const whyItWorksPath = '/program-information/why-it-works';
export const corporateSolutionsPath = '/program-information/corporate-solutions';
export const geneticsPath = '/program-information/genetics';
export const aboutUsPath = '/about-us';
export const contactUsPath = '/contact-us';
export const termsOfUsePath = '/terms-of-use';
export const termsOfSalePath = '/terms-of-sale';
export const termsOfServicePath = '/terms-of-service';
export const privacyPath = '/privacy-policy';
export const careersPath = '/careers';
export const scheduleConsultationPath = '/schedule-a-consultation';
export const formPath = '/send';
export const referralFormPath = '/send-referral';
export const seminarFormPath = '/send-seminar';
export const maintenance = '/maintenance';
export const mealPlansPath = '/resources-and-tools/meal-plans';
export const seminarPath = '/seminar';
export const healthMonitoringPath = '/program-information/scientific-health-monitoring';
export const referAFriendPath = '/refer-a-friend';
export const pricingPagePath = '/program-information/program-fees';
export const attendASeminar = '/attend-a-seminar';
// Social urls
