import React from 'react';

const meta = {
  title: 'Terms of Use',
  noFollow: true
  // description: 'Welcome to 2020 Lifestyles',
  // keywords: ["weight loss", "nutrition in bellevue"]
}

const page = {
  name: 'Terms of Use',
};

const sections = [
  {
    sectionExtraClassName: 'noTopPadding',
    textBlocks: [
      {
        align: 'left',
        text: (
          <div>
            <p>Last Updated: January 3, 2013</p>

            <p>PLEASE READ THESE TERMS AND CONDITIONS OF USE CAREFULLY. BY ACCESSING OR USING OUR SITES, YOU AGREE TO BE BOUND BY THESE TERMS OF USE. IF YOU DO NOT AGREE TO ALL OF THESE TERMS, DO NOT ACCESS OR USE OUR SITES.</p>

            <p>These Terms and Conditions of Use ("Terms") apply solely to your access to, and use of, any websites, digital and electronic content or other online services provided by or belonging of Professional Recreation Organization, Inc. (d/b/a 20/20 LifeStyles) (hereinafter referred to as, "we" or "us") and our other online or mobile applications and services, including the Video Service described herein (collectively, the "Sites"). We hereby grant you a limited, non-exclusive license to utilize the Sites as set forth herein. These Terms do not alter the terms or conditions of any other agreement you may have with us or our subsidiaries or affiliates for products, services or otherwise.</p>

            <p>We reserve the right to change or modify the terms, conditions and notices under which the Sites are offered, at any time and in our sole discretion. Any changes or modifications will be effective immediately upon posting the revisions to the Sites and you waive any right you may have to receive specific notice of such changes or modifications. Your continued use of the Sites following the posting of changes or modifications will confirm your acceptance of such changes or modifications; therefore, you should frequently review these Terms and any applicable policies to understand the terms and conditions that apply to your use of the Sites. If you do not agree to the amended terms, conditions and notices, you must stop using the Sites. Any use of the Sites in violation of these Terms may result in, among other things, termination or suspension of your right to use the Sites.</p>

            <p>If you have any questions regarding the use of the Sites, please contact <a href='mailto:support@2020lifestyles.com'>support@2020lifestyles.com</a>.</p>

            <p>1. The Sites Do Not Provide Medical Advice<br />
            All material appearing on the Sites or distributed through the Sites, including but not limited to text, graphics, images, video, digital media and third-party content, including but not limited to Digital Content described herein (collectively “Content”) is for informational purposes only. The Content is not intended to be a substitute for professional medical advice, diagnosis or treatment. The Content/program is not intended for women who are pregnant. If you are nursing, please consult with your physician before beginning this program. Always seek the advice of your physician or other qualified health provider with any questions you may have regarding a medical condition. Never disregard professional medical advice or delay in seeking it because of something you have read, heard or viewed on the Sites.<br />
            If you think you may have a medical emergency, call you doctor or 911 immediately. Reliance on any information provided by us, the Sites, our employees, our agents, other users of the Sites or other persons appearing on or contributing to the Sites at our invitation is solely at your own risk.</p>

            <p>2. Privacy Policy<br />
            Please refer to our Privacy Policy for information about how we collect, use and disclose personally identifiable information from our users.</p>

            <p>3. Terms of Sale<br />

            All sales from the Sites are governed by our Terms of Sale. Please refer to these Terms of Sale for the terms, conditions and policies applicable to your purchase of products and services from us. The Terms of Sale are subject to change without notice, at any time and in our sole discretion, so you should review the Terms of Sale each time you make a purchase from the Sites.</p>

            <p>4. Eligibility, Registration and Account<br />

            To be eligible to use the Sites, you must be at least 18 years of age and a resident of the United States. You represent and warrant that you have not previously been suspended from the Sites and that you have the right and capacity to accept and be bound by these Terms.<br />

            In order to access certain areas of the Sites, you must create an account. You agree to (a) provide accurate, truthful, current and complete information when creating your account; (b) maintain and promptly update your account information; (c) maintain the security of your account by not sharing your password with others and restricting access to your account on your computer and/or mobile device; (d) promptly notify us if you discover or otherwise suspect any security breaches related to the Sites and (e) take responsibility for all activities that occur under your account and accept all risks of unauthorized access.</p>

            <p>5. Copyright and Limited License<br />

            Unless otherwise indicated, the Sites and all content and other materials therein, including without limitation our logos and all designs, text, graphics, pictures, information, data, software, sound files, other files and the selection and arrangement thereof (collectively, "Site Materials") are the proprietary property of us or our licensors or users and are protected by U.S. and international copyright laws.<br />

            You are granted a limited, non-sublicensable license to access and use the Sites and Site Materials; however, such license is subject to these Terms and any applicable End User License Agreement and does not include: (a) any resale or commercial use of the Sites or Site Materials; (b) the distribution, public performance or public display of Site Materials, (c) modifying or otherwise making any derivative uses of the Sites, Site Materials or any portion thereof; (d) use of any data mining, robots or similar data gathering or extraction methods; (e) downloading (other than the page caching) of any portion of the Sites or Site Materials or any information contained therein, except as expressly permitted; or (f) any use of the Sites or Site Materials other than for their intended purposes. Any use of the Sites or Site Materials other than as specifically authorized herein, without our prior written permission, is strictly prohibited and will automatically terminate the license granted herein. Such unauthorized use may also violate applicable laws, including, without limitation, copyright and trademark laws and applicable communications regulations and statutes. Unless explicitly stated herein, nothing in these Terms shall be construed as conferring any license to intellectual property rights, whether by estoppel, implication or otherwise. This license is revocable at any time.</p>

            <p>6. Repeat Infringer Policy

            In accordance with the Digital Millennium Copyright Act ("DMCA") and other applicable law, we have adopted a policy of terminating, in appropriate circumstances and in our sole discretion, subscribers who are deemed to be repeat infringers. We may also, in our sole discretion, limit access to the Sites and/or terminate the accounts of subscribers who infringe any intellectual property rights of others, whether or not there is any repeat infringement.</p>

            <p>7. Copyright Complaints<br />

            If you believe that anything available on the Sites infringes upon any copyright you own or control, you may request removal of those materials (or access thereto) from the Sites by contacting our Designated Agent and providing the following information:</p>
            <ul>
              <li>Identification of the copyrighted work that you believe to be infringed. Please describe the work, and where possible include a copy or the location (e.g., URL) of an authorized version of the work.</li>
              <li>Identification of the material that you believe to be infringing and its location. Please describe the material, and provide us with its URL or any other pertinent information that will allow us to locate the material.</li>
              <li>Your name, address, telephone number and (if available) e-mail address.</li>
              <li>A statement that you have a good faith belief that the complained of use of the materials is not authorized by the copyright owner, its agent, or the law.</li>
              <li>A statement that the information that you have supplied is accurate, and indicating that "under penalty of perjury," you are the copyright owner or are authorized to act on the copyright owner's behalf.</li>
              <li>A signature or the electronic equivalent from the copyright holder or authorized representative.</li>
            </ul>
            <p>Name of Designated Agent: Steve Buxton<br />
            Address of Designated Agent: 4455 148th Ave. N.E., Bellevue, WA 98007<br />
            Telephone Number of Designated Agent: (425) 885-5566<br />
            Facsimile Number of Designated Agent: (425) 881-6492<br />
            Email Address of Designated Agent: sbuxton@proclub.com</p>

            <p>Please see <a href='http://www.law.cornell.edu/uscode/html/uscode17/usc_sec_17_00000512----000-.html' target='_blank'>17 U.S.C. §512(c)(3)</a> for the requirements of a proper notification. You should note that if you knowingly misrepresent in your notification that the material or activity is infringing, you will be liable for all damages, including costs and attorneys' fees, incurred by us or the alleged infringer as the result of our relying upon such misrepresentation in removing or disabling access to the material or activity claimed to be infringing.</p>

            <p>8. Trademarks<br />

            "PRO Sports Club," "20/20 LifeStyles," "20/20 LifeStyles Online," "20/20 BeWell," our logos and any other product or service name or slogan contained in the Sites are our trademarks or those of our suppliers or licensors and may not be copied, imitated or used, in whole or in part, without the prior written permission of us or the applicable trademark holder. You may not use any metatags or any other "hidden text" utilizing "20/20 LifeStyles" or any other name, trademark, product or service name belonging to us without our prior written permission. In addition, the look and feel of the Sites, including all page headers, custom graphics, button icons and scripts, is our service mark, trademark and/or trade dress and may not be copied, imitated or used, in whole or in part, without our prior written permission. All other trademarks, registered trademarks, product names and logos mentioned on the Sites are the property of their respective owners. Reference to any products, services, processes or other information, by trade name, trademark, manufacturer, supplier or otherwise does not constitute or imply endorsement, sponsorship or recommendation thereof by us.</p>

            <p>9. Links to Third Party Sites<br />

            The Sites may contain links to other websites ("Linked Sites"). The Linked Sites are not under our control and we are not responsible for the contents of any Linked Site, including without limitation any link contained in a Linked Site, or any changes or updates to a Linked Site. We are not responsible for webcasting or any other form of transmission received from any Linked Site. We are providing these links to you only as a convenience, and the inclusion of any link does not imply endorsement by us of the Linked Site or any association with its operators. When you leave the Sites, you should be aware that our terms and policies no longer govern. You should review the applicable terms and policies, including privacy and data gathering practices, of any Linked Site.</p>

            <p>10. Hyperlinks<br />

            You are granted a limited, non-exclusive right to create a text hyperlink to the Sites for noncommercial purposes, provided such link does not portray us or any of our products or services in a false, misleading, derogatory or otherwise defamatory manner and provided further that the linking site does not contain any adult or illegal material or any material that is offensive, harassing or otherwise objectionable. This limited right may be revoked at any time. You may not use our logos or other proprietary graphics to link to the Sites without our express written permission. Further, you may not use, frame or utilize framing techniques to enclose any of our trademarks, logos or other proprietary information, including the images found at the Sites, the content of any text or the layout/design of any page or form contained on a page of the Sites without our express written consent. Except as noted above, you are not conveyed any right or license by implication, estoppel or otherwise in or under any patent, trademark, copyright or proprietary right belonging to us or any third party.</p>

            <p>11. Third Party Content<br />

            We may provide third party content on the Sites ("Third Party Content") as a service to those interested in this information. We do not monitor or have control over Third Party Content. Unless otherwise expressly stated by us, we do not endorse or adopt, and we are not responsible or liable for, any Third Party Content. We do not make any representations or warranties of any kind regarding the Third Party Content, including, without limitation, any representations or warranties regarding its accuracy, completeness or non-infringement. We undertake no responsibility to update or review any Third Party Content and users use such content contained herein at their own risk.</p>

            <p>12. No Unlawful or Prohibited Use<br />

            As a condition of your use of the Sites, you warrant that you will not use the Sites for any purpose that is unlawful or prohibited by these terms, conditions, and notices. You may not use the Sites in any manner that could damage, disable, overburden, or impair the Sites or interfere with any other party's use and enjoyment of the Sites. You may not obtain or attempt to obtain any materials or information through any means not intentionally made available or provided for through the Sites.</p>

            <p>13. User Content and Communication Services<br />

            The Sites may contain bulletin board services, chat areas, text messaging functionality, real-time video conferencing, news groups, forums, communities, personal web pages, calendars and/or other message or communication facilities designed to enable you to communicate with the public at large or with a group (collectively, "Communication Services"). The Communication Services permit the submission, posting and transmission of user-generated content, which may include messages, materials, data, information, text, music, sound, photos, videos, graphics, applications, links, code or other items or material (collectively, "User Content"). You are solely responsible for the User Content you post and you agree not to submit, post, upload to, transmit, distribute, download, store, create or otherwise publish through the Sites any of the following:</p>
            <ul>
              <li>User Content that is unlawful, libelous, defamatory, offensive, obscene, pornographic, indecent, vulgar, lewd, sexually explicit, harassing, threatening, abusive, invasive of privacy or publicity rights, inflammatory, fraudulent or otherwise objectionable;</li>
              <li>User Content that would constitute, encourage or provide instructions for a criminal offense, violate the rights of any party or that would otherwise create liability or violate any local, state, national or international law;</li>
              <li>User Content that may infringe any patent, trademark, trade secret, copyright or other intellectual property or proprietary right of any party;</li>
              <li>User Content that impersonates any person or entity or otherwise misrepresents your affiliation with a person or entity;</li>
              <li>Private information of any third party, including, without limitation, addresses, phone numbers, email addresses, Social Security numbers and credit card numbers;</li>
              <li>Viruses, corrupted files, or any other harmful, disruptive or destructive software or programs that may damage the operation of another's computer;</li>
              <li>Advertisements or offers to sell or buy any goods or services for any business purpose, unless such Communication Service specifically allows such messages;</li>
              <li>Surveys, contests, pyramid schemes, chain letters or spam; and</li>
              <li>User Content that, in our sole judgment, is objectionable or which restricts or inhibits any other person from using or enjoying the Sites or which may expose us or our users to any harm or liability of any type.</li>
            </ul>
            <p>You further agree not to (a) falsify or delete any author attributions, legal or other proper notices or proprietary designations or labels of the origin of source of software or other material contained in an uploaded file or (b) harvest or otherwise collect information about others, including email addresses, without their consent.</p>
            <p>We do not control, take responsibility for or assume liability for any User Content posted, stored or uploaded by you or any third party, or for any loss or damage thereto, nor are we liable for any user conduct or any mistakes, defamation, slander, libel, omissions, falsehoods, obscenity, pornography or profanity you may encounter. If you become aware of User Content that violates these Terms (with the exception of copyright infringement which is addressed separately above), you may report it by clicking on a "Report Abuse" link. In addition, 20/20 LifeStyles managers and hosts are not authorized spokespersons and their views do not necessarily reflect those of 20/20 LifeStyles. Enforcement of these Terms is solely in our discretion and the absence of enforcement of these Terms in some instances does not constitute a waiver of our right to enforce the Terms in other instances. In addition, these Terms do not create any private right of action on the part of any third party or any reasonable expectation or promise that the Sites will not contain any content that is prohibited by the Terms. Although we have no obligation to screen, edit or monitor the Communication Services, we reserve the right, and have absolute discretion, to review and remove materials posted to a Communication Service. We further reserve the right to terminate your access to any or all of the Communication Services at any time, without notice and for any reason whatsoever.</p>

            <p>14. Rights in User Content<br />

            We do not claim ownership of the User Content you post or submit to the Sites. If you post User Content, unless we indicate otherwise, you hereby grant us and our affiliates a perpetual, irrevocable, worldwide, nonexclusive license to publish, reproduce, display, perform, distribute, adapt, edit, modify, translate, create derivative works based upon and otherwise use and sublicense such User Content throughout the world in any form or media. You also grant us and our affiliates and sublicensees the right to use the name you submit in connection with any User Content, to the extent that it is made publicly available on the Sites. Without limiting the foregoing, this license grant includes the right to (a) make editorial revisions; (b) use and publish the User Content in any manner and form, including on the Sites or in other media, whether now or hereafter created; (c) use the User Content for internal business purposes; (d) reproduce and distribute the User Content for marketing and publicity purposes and (e) sublicense the User Content to third parties for any reason.<br />

            By posting User Content to the Sites, you represent and warrant that (a) such User Content is not subject to any confidentiality obligations; (b) you own and control all of the rights to the User Content or you otherwise have all necessary rights to submit and post such User Content to the Sites and to grant the licenses herein and (c) the User Content and your use and posting thereof in connection with the Sites do not, and will not, violate these Terms or any applicable laws, rules, regulations or the rights of any person or entity.</p>

            <p>15. Submissions<br />

            Separate and apart from User Content, you can submit questions, comments, suggestions, ideas, plans, notes, drawings, original or creative materials or other information about us and our Sites, products or services (collectively, "Submissions"). Submissions, whether submitted through the Sites or otherwise, are non-confidential. We shall own, and you hereby assign to us, all right, title and interest, including all intellectual property rights, in and to such Submissions, and we shall be entitled to the unrestricted use and dissemination of these Submissions for any purpose, commercial or otherwise, without acknowledgement or compensation to you. You agree to execute any documentation required by us, in our sole discretion, to confirm such assignment to, and unrestricted use and dissemination by, us of such Submissions.</p>

            <p>16. Indemnification<br />

            You agree to defend, indemnify and hold harmless us and our subsidiaries, affiliates, independent contractors, service providers and consultants, and their respective directors, employees and agents, from and against any claims, damages, costs, liabilities and expenses (including, but not limited to, reasonable attorneys' fees) arising out of or related to (a) your use of the Sites; (b) any User Content or Submission you post, store or otherwise transmit on or through the Sites; (c) your violation of these Terms or other applicable terms, policies or agreements or (d) your violation of any rights of another.</p>

            <p>17. Liability Disclaimer<br />

            WE AND/OR OUR SUPPLIERS MAKE NO REPRESENTATIONS ABOUT THE SUITABILITY, RELIABILITY, AVAILABILITY, TIMELINESS AND ACCURACY OF THE INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS CONTAINED ON THE SITES. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE SITES AND ALL INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS PROVIDED IN CONNECTION THEREWITH ARE PROVIDED "AS IS" WITHOUT WARRANTY OR CONDITION OF ANY KIND, WHETHER EXPRESS OR IMPLIED. WE AND/OR OUR LICENSORS, SUPPLIERS AND CONTRIBUTORS HEREBY DISCLAIM ALL WARRANTIES AND CONDITIONS, INCLUDING ALL IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT. WHILE WE ATTEMPT TO MAKE YOUR ACCESS AND USE OF THE SITES SAFE, WE CANNOT AND DO NOT REPRESENT OR WARRANT THAT THE SITES OR ITS SERVER(S) ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS; THEREFORE, YOU SHOULD USE INDUSTRY-RECOGNIZED SOFTWARE TO DETECT AND DISINFECT VIRUSES FROM ANY DOWNLOAD.<br />

            ADVICE RECEIVED VIA THE SITES SHOULD NOT BE RELIED UPON FOR PERSONAL, MEDICAL, LEGAL OR FINANCIAL DECISIONS AND YOU SHOULD CONSULT AN APPROPRIATE PROFESSIONAL FOR SPECIFIC ADVICE TAILORED TO YOUR SITUATION. YOU ACKNOWLEDGE THAT YOUR DIET AND EXERCISE ACTIVITIES INVOLVE RISKS, WHICH MAY INCLUDE THE RISK OF BODILY INJURY OR DEATH, AND THAT YOU ASSUME THOSE RISKS. YOU SHOULD CONSULT A LICENSED PHYSICIAN PRIOR TO BEGINNING OR MODIFYING ANY DIET OR EXERCISE PROGRAM THAT YOU UNDERTAKE, AND YOU ACKNOWLEDGE THAT WE HAVE ADVISED YOU OF THE NECESSITY OF OBTAINING SUCH CONSULTATIONS. THE INFORMATION CONTAINED ON THE SITES IS FOR EDUCATIONAL AND ENTERTAINMENT PURPOSES ONLY AND IS IN NO WAY INTENDED TO PROVIDE MEDICAL ADVICE. MEDICAL INFORMATION MAY CHANGE RAPIDLY AND WE CANNOT VERIFY THE ACCURACY OR TIMELINESS OF ANY INFORMATION IT PROVIDES. IN ADDITION TO THE OTHER LIMITATIONS AND DISCLAIMERS IN THESE TERMS, WE DISCLAIM ANY AND ALL LIABILITY OR LOSS IN CONNECTION WITH YOUR USE OF THE SITES OR ANY DIET, EXERCISE OR OTHER ACTIVITY THAT YOU UNDERTAKE IN CONNECTION WITH YOUR USE OF THE SITES.<br />

            We do not recommend or endorse any specific tests, physicians, products, procedures, opinions or other information that may be mentioned on the Sites or in any Digital Content. Reliance on any information provided by us or our employees, users or any other third parties on the Sites, the Video Service and the Digital Content is solely at your own risk.</p>

            <p>18. Limitation of Liability; Damages Cap<br />

            TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL WE AND/OR OUR SUPPLIERS BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES, OR ANY DAMAGES WHATSOEVER, INCLUDING WITHOUT LIMITATION DAMAGES FOR LOSS OF USE, DATA OR PROFITS ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE OR PERFORMANCE OF THE SITES, THE DELAY OR INABILITY TO USE THE SITES OR RELATED SERVICES THE PROVISION OF OR FAILURE TO PROVIDE SERVICES, OR FOR ANY INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS OBTAINED THROUGH THE SITES, OR OTHERWISE ARISING OUT OF THE USE OF THE SITES, WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, EVEN IF WE OR ANY OF OUR SUPPLIERS HAVE BEEN ADVISED OF THE POSSIBILITY OF DAMAGES. BECAUSE SOME STATES/JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU. IF YOU ARE DISSATISFIED WITH ANY PORTION OF THE SITES, OR WITH ANY OF THESE TERMS, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE SITES.<br />

            WITHOUT LIMITING THE DISCLAIMER OF WARRANTIES AND LIABILITY AND LIMITATION OF LIABILITY IN OUR TERMS AND CONDITIONS OF USE: (I) IN NO EVENT SHALL OUR OR OUR SOFTWARE LICENSORS' TOTAL LIABILITY TO YOU FOR ALL DAMAGES (OTHER THAN AS MAY BE REQUIRED BY APPLICABLE LAW IN CASES INVOLVING PERSONAL INJURY) ARISING OUT OF OR RELATED TO YOUR USE OR INABILITY TO USE THE SITES, THE VIDEO SERVICE, THE DIGITAL CONTENT OR THE SOFTWARE EXCEED THE AMOUNT OF FIFTY DOLLARS ($50.00); AND (II) IN NO EVENT SHALL OUR OR OUR CONTRIBUTOR’S OR DIGITAL CONTENT PROVIDERS' TOTAL LIABILITY TO YOU FOR ALL DAMAGES ARISING FROM YOUR USE OF THE SITES, VIDEO SERVICE, THE DIGITAL CONTENT, THE SOFTWARE OR INFORMATION, MATERIALS OR PRODUCTS INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH THE SITES AND THE VIDEO SERVICE, EXCEED THE AMOUNT YOU PAID TO US TO PURCHASE, RENT, OR VIEW THE CONTENT RELATED TO YOUR CLAIM FOR DAMAGES. THESE LIMITATIONS WILL APPLY TO YOU EVEN IF THE REMEDIES FAIL OF THEIR ESSENTIAL PURPOSE.</p>

            <p>19. Class Action Waiver<br />

            YOU AND PROFESSIONAL RECREATION ORGANIZATION, INC. ("PRO") AGREE THAT ANY PROCEED¬INGS TO RESOLVE OR LITIGATE ANY DISPUTE, WHETHER IN ARBITRATION, IN COURT, OR OTHERWISE, WILL BE CONDUCTED SOLELY ON AN INDIVIDUAL BASIS, AND THAT NEITHER YOU NOR PRO WILL SEEK TO HAVE ANY DISPUTE HEARD AS A CLASS ACTION, A REPRESENTATIVE ACTION, A COLLECTIVE ACTION, A PRIVATE ATTORNEY-GENERAL ACTION, OR IN ANY PROCEEDING IN WHICH YOU OR PRO ACTS OR PROPOSES TO ACT IN A REPRESENTATIVE CAPACITY. YOU AND PRO FURTHER AGREE THAT NO ARBITRATION OR PROCEEDING WILL BE JOINED, CONSOLIDATED, OR COMBINED WITH ANOTHER ARBITRATION OR PROCEEDING WITHOUT THE PRIOR WRITTEN CONSENT OF YOU, PRO, AND ALL PARTIES TO ANY SUCH ARBITRATION OR PROCEEDING.</p>

            <p>20. Modifications to the Sites<br />

            We reserve the right to modify or discontinue, temporarily or permanently, the Sites, the Video Service or any features or portions thereof without prior notice. You agree that we will not be liable for any modification, suspension or discontinuance of the Sites, the Video Service or any part thereof.</p>

            <p>21. Applicable Law and Venue<br />

            These Terms and your use of the Sites and the Video Service shall be governed by and construed in accordance with the laws of the State of Washington, without resort to its conflict of law provisions. You agree that any action at law or in equity arising out of or relating to these Terms shall be filed only in the state and federal courts located in King County, Washington and you hereby irrevocably and unconditionally consent and submit to the exclusive jurisdiction of such courts over any suit, action or proceeding arising out of these Terms.</p>

            <p>22. Termination/Access Restriction<br />

            Notwithstanding anything contained in these Terms, we reserve the right, in our sole discretion, to terminate or block your access to the Sites, the Video Service and the related services, or any portion thereof, at any time, without notice.</p>

            <p>23. Severability<br />

            If any part of these Terms is determined to be invalid or unenforceable pursuant to applicable law, including but not limited to the warranty disclaimers and liability limitations set forth above, then the invalid or unenforceable provision will be deemed superseded by a valid, enforceable provision that most closely matches the intent of the original provision and the remainder of the agreement shall continue in effect.</p>

            <p>24. Access to Video and Related Digital Media<br />

            The Sites offer you the opportunity to purchase rights to access and view video and other digital media (“Digital Content”) via a Compatible Device (“Video Service”). The Video Service is intended to make such content available only through streaming and not through a download.<br />

            In order to be able to stream Digital Content from the Video Service and to view Digital Content on the Service, you will need to use a personal computer, portable media player, or other device that meets the system and compatibility requirements that we establish from time to time and is otherwise capable of interacting with the Service (each such device, a "Compatible Device"). We may change the requirements for Compatible Devices from time to time and, in some cases, whether a device is (or remains) a Compatible Device may depend on software or systems provided or maintained by the device manufacturer or other third parties. As a result, devices that are Compatible Devices at one time may cease to be Compatible Devices in the future.</p>

            <p>The Video Service may allow you to: (i) rent Digital Content for viewing over a limited period of time ("Rental Digital Content"), (ii) purchase Digital Content for viewing over an indefinite period of time ("Purchased Digital Content"), (iii) access Digital Content on a subscription basis for viewing over a limited period of time during a subscription period ("Subscription Digital Content"), (iv) access Digital Content on a free or promotional basis for viewing over a limited period of time ("Free Digital Content") or (v) access Digital Content for a fixed number of viewings (Limited Views Content). Digital Content may be available on the Service as Rental Digital Content, Purchased Digital Content, Subscription Digital Content, Free Digital Content, Limited Views Content or any combination of those. The basis on which Digital Content is available on the Video Service may change from time to time in our discretion.</p>

            <p>Subject to your payment of any applicable fees (including applicable taxes) to rent, purchase, or otherwise obtain access to Digital Content, and your compliance with all other terms we specify for Digital Content or the Video Service, we grant you a non-exclusive, non-transferable, non-sublicensable, limited right and license, during the applicable Viewing Period, to access, view, use and display the Digital Content for Non-Commercial, Private Use. As used herein, "Non-Commercial, Private Use" means a presentation of Digital Content for which no fee or consideration of any kind (other than that which you pay to us to view the Digital Content) is charged or received, which takes place in your private home or apartment or, if outside your private home or apartment (e.g., in a hotel room, dorm room, office, or airport waiting lounge) is limited to a private viewing for you and your invitees. Non-Commercial, Private Use specifically excludes any public presentation (e.g., a presentation in a dorm lounge) and any presentation by a place of public accommodation or other commercial establishment (e.g., a bar or restaurant), even if no fee is charged for viewing the Digital Content. To simplify your viewing and management of Digital Content that has a limited viewing period (such as Rental Digital Content and Subscription Digital Content), we may automatically remove that Digital Content (or any access codes allowing your access to the Digital Content) from your Compatible Device after the end of its viewing period, and you consent to such automatic removal.</p>

            <p>When you stream Digital Content, the resolution and quality of the Digital Content you receive will depend on a number of factors, including the type of Compatible Device on which you are streaming the Digital Content and your bandwidth, which may go up and down over the course of your viewing. If we detect that Digital Content we are streaming to you may be interrupted or may otherwise not play properly due to bandwidth constraints or other factors, we may decrease the resolution and file size of the Digital Content we stream to you in an effort to provide an uninterrupted viewing experience. While we strive to provide you a high quality viewing experience, we make no guarantee as to the resolution or quality of the Digital Content you will receive when streaming, even if you have paid extra for access to high definition content.</p>

            <p>You may not transfer, copy or display the Digital Content, except as permitted in this Agreement. In addition, you may not:</p>
            <ul>
              <li>sell, rent, lease, distribute, broadcast, sublicense or otherwise assign any right to the Digital Content to any third party;</li>
              <li>remove any proprietary notices or labels on the Digital Content;</li>
              <li>attempt to disable, bypass, modify, defeat, or otherwise circumvent any digital rights management system used as part of the Service or attempt to capture, copy or download an Digital Content; or</li>
              <li>use the Video Service or Digital Content for any commercial or illegal purpose.</li>
            </ul>
            <p>We may make available to you, from time to time, software for your use in connection with the Service (any and all such software, individually and collectively, the "Software"). You may use the Software only in connection with the Service. You may not separate any individual component of the Software for use other than in connection with the Service, may not incorporate any portion of it into your own programs or compile any portion of it in combination with your own programs, may not transfer it for use with another service, or use it, or any portion of it, over a network and may not sell, rent, lease, lend, loan, distribute or sub-license the Software or otherwise assign any rights to the Software in whole or in part. We may discontinue some or all of any Software we provide, and we may terminate your right to use any Software at any time and in such event may modify it to make it inoperable. You may not, and you will not encourage, assist or authorize any other person to, modify, reverse engineer, decompile or disassemble, or otherwise tamper with, the Software, whether in whole or in part, or create any derivative works from or of the Software. In order to keep your Software up-to-date, we may automatically update/upgrade the Software on your Compatible Device.
            </p>
            <p>The Software may provide us with data about your Compatible Device and its interaction with the Video Service (such as device type and unique device identifiers that allow us to link your Compatible Device to your Video Service account). The Software may also provide us with information related to the Digital Content you download and stream and your use of that Digital Content (such whether and when you viewed the Digital Content, which may, among other things, help us measure the viewing period for Rental Digital Content). Any information we receive is subject to our privacy policy located at Privacy Policy.</p>

            <p>You understand that by using the Video Service, you may encounter content that may be deemed offensive, indecent or objectionable, which content may or may not be identified as having explicit language or other features. Nevertheless, you agree to use the Service at your sole risk, and we shall have no liability to you for content that may be found to be offensive, indecent or objectionable. Content types (including genres, sub-genres, and categories and sub-categories and the like) and descriptions are provided for convenience, and you acknowledge and agree that we do not guarantee their accuracy.</p>

            <p>We reserve the right to modify, suspend, or discontinue the Video Service, or any part thereof, at any time and without notice to you, and we will not be liable to you should it exercise such rights, even if your use of Digital Content is impacted by the change.</p>
          </div>
        ),
      },
    ]
  },
];

export {
  page,
  sections,
  meta
};
