import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import loader from 'assets/images/2020lifestyles-loader.gif';
import { SuccessStoryCard } from 'components';

import styles from './ListView.scss';

class ListView extends PureComponent {
  state = {
    pageCount: 1
  }

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.storiesState.gender !== this.props.storiesState.gender ||
      prevProps.storiesState.diet !== this.props.storiesState.diet
    ) {
      this.setState({ pageCount: 1 });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll);
  }

  onScroll = () => {
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }

    this.timeout = setTimeout(() => {
      const { storiesState, setStoriesState, successStories, storiesLazyLoading, storiesTotalLength } = this.props;
      const storiesWrappePosition = this.storiesWrapper.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      if (storiesState.gender !== null || storiesState.diet !== null) {
        this.setState({ pageCount: 1 });
      }

      if (storiesWrappePosition.top + storiesWrappePosition.height < windowHeight &&
            successStories.length < storiesTotalLength &&
           !storiesLazyLoading) {
        this.setState({ pageCount: this.state.pageCount + 1 });
        setStoriesState({
          page: this.state.pageCount
        }, true);
      }
    }, 200);
  };

  setStoriesWrapperRef = (node) => {
    this.storiesWrapper = node;
    return this.storiesWrapper;
  };

  render() {
    const {
      successStories,
      storiesLazyLoading,
      storiesLoading
    } = this.props;
    return (
      <div className={styles.storiesListWrapper}>
        <div
          className={styles.storiesList}
          ref={node => this.setStoriesWrapperRef(node)}
        >
          {successStories.map((item, index) => (
            <div key={index} className={styles.story}>
              <SuccessStoryCard successStory={item} />
            </div>
          ))}
        </div>
        {storiesLoading ?
          <div className={styles.loader}>
            <img src={loader} alt='loader' className={styles.loaderIcon} />
          </div> : <div />
        }
        {storiesLazyLoading ?
          <div className={styles.loader}>
            <img src={loader} alt='loader' className={styles.loaderIcon} />
          </div> : <div />
        }
      </div>
    );
  }
}

ListView.propTypes = {
  successStories: PropTypes.arrayOf(PropTypes.object),
  setStoriesState: PropTypes.func.isRequired,
  storiesState: PropTypes.object.isRequired,
  storiesLazyLoading: PropTypes.bool.isRequired,
  storiesTotalLength: PropTypes.number.isRequired,
  storiesLoading: PropTypes.bool.isRequired
};

ListView.defaultProps = {
  successStories: []
};

export default withStyles(styles)(ListView);
