import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import {
  // Sections,
  Breadcrumbs,
  PageTitle
} from 'components';
import { page } from '../../../mocks/pages/Careers';
import styles from './Careers.scss';

class Careers extends Component {
  static propTypes = {
    location: PropTypes.object
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const { location } = this.props;
    return (
      <div className='noMobilePadding'>
        <div className={styles.breadcrumb}>
          <Breadcrumbs location={location} />
        </div>
        <div className={styles.pageTitle}>
          <PageTitle page={page} />
        </div>
        {/* <Sections sections={sections} /> */}
      </div>
    );
  }
}

export default withStyles(styles)(Careers);
