import * as React from 'react';
import PropTypes from 'prop-types';

/**
 * WithStylesContext
 * Returns a child component with insertCss function allowing isomorphic rendering to work.
 */
class WithStylesContext extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    context: PropTypes.object.isRequired
  };

  static contextTypes = {
    insertCss: PropTypes.func
  };

  static childContextTypes = {
    insertCss: PropTypes.func.isRequired
  };

  getChildContext() {
    return this.props.context;
  }

  render() {
    return React.Children.only(this.props.children);
  }
}


export default WithStylesContext;
