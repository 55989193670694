import React from 'react';
import { routes } from 'routes';

import homeSlide1 from 'assets/images/HomePage/Slider/2020-lifestyles-first-slider-about-seminar.jpg';
import homeSlide2 from 'assets/images/HomePage/Slider/2020-lifestyles-weight-loss-bellevue-medically-based.jpg';
import homeSlide3 from 'assets/images/HomePage/Slider/2020-lifestyles-health-tracker-healthy-eating-weight-loss.jpg';
import homeSlide5 from 'assets/images/HomePage/Slider/2020-lifestyles-why-it-works.jpg';
import homeSlide6 from 'assets/images/HomePage/Slider/web-banner-genetics.jpg';
import homeSlide4 from 'assets/images/HomePage/Slider/2020-lifestyles-weight-loss-personal-training-fitness.jpg';

import blogBlockImg from 'assets/images/resources/RESOURCESTOOLS_BLOG_250x150.jpg';
import recipesBlockImg from 'assets/images/resources/RESOURCESTOOLS_HEALTHYRECIPES_250x150.jpg';
import rewardsBlockImg from 'assets/images/resources/RESOURCESTOOLS_REWARDS_250x150.jpg';
import faqBlockImg from 'assets/images/resources/RESOURCESTOOLS_FAQS_250x150.jpg';

const meta = {
  title: 'Medically Based Weight Loss Program ',
  description:
    'Personalized exercise, nutrition, and lifestyle weight loss program in Bellevue and Seattle',
  keywords: ['weight loss', 'nutrition in bellevue']
};

const heroSlider = [
  {
    id: 1,
    title: 'Free Weight Loss Consultation',
    buttons: [
      {
        type: 'link',
        name: 'Schedule A Consultation',
        link: routes.SCHEDULE_A_CONSULTATION
      }
      // {
      //   type: 'link',
      //   name: 'Attend a Seminar',
      //   link: '/attend-a-seminar'
      // }
    ],
    image: homeSlide1,
    altText: 'Schedule A Consultation'
  },
  {
    id: 2,
    title: 'The Weight Loss Program Developed by a Doctor',
    button: {
      // type: 'popup',
      // name: 'Second Popup',
      // description: {
      //   title: 'Popup Title 2',
      //   text: (
      //     <div>
      //       <h3>This is a heading</h3>
      //       <p>Pop Up Text 2</p>
      //     </div>
      //   )
      // }
      type: 'link',
      name: 'Learn more',
      link: routes.PROGRAM_INFORMATION
    },
    image: homeSlide2,
    altText: 'The Weight Loss Program Developed by a Doctor'
  },
  {
    id: 3,
    title: 'Uncover the link between genes & weight loss',
    button: {
      type: 'link',
      name: 'Learn more',
      link: `${routes.PROGRAM_INFORMATION}#genetics`,
      anchored: true
    },
    image: homeSlide6,
    altText: 'Uncover the link between genes & weight loss'
  },
  {
    id: 4,
    title: 'Sign up for our free health tracker',
    button: {
      type: 'link',
      name: 'See How It Works',
      link: routes.RESOURCES_AND_TOOLS_TRACKER
      // isExternal: true
    },
    image: homeSlide3,
    altText: 'Sign up for our free health tracker'
  },
  {
    id: 5,
    title: 'Find out why 20/20 LifeStyles works',
    button: {
      type: 'link',
      name: 'See the key components',
      link: routes.PROGRAM_INFORMATION_WHY_IT_WORKS
    },
    image: homeSlide5,
    altText: 'Find out why 20/20 LifeStyles works'
  },
  {
    id: 6,
    title: "We've helped thousands of people transform their lives",
    button: {
      type: 'link',
      name: 'See Success Stories',
      link: routes.SUCCESS_STORIES
      // description: {
      //   title: 'Popup Title',
      //   text: (
      //     <div>
      //       <h3>This is a heading</h3>
      //       <p>Pop Up Text</p>
      //     </div>
      //   )
      // }
    },
    image: homeSlide4,
    altText: "We've helped thousands of people transform their lives"
  }
];

const topSections = [
  {
    // sectionExtraClassName: 'noBottomPadding',
    // textBeforeTitle: 'another test',
    pageHeading: 'CHANGE YOUR LIFE FOR GOOD WITH 20/20 LIFESTYLES',
    textBlocks: [
      {
        align: 'center',
        // title: 'a test',
        text: (
          <div>
            <p>
              20/20 LifeStyles is the country’s leading weight loss program.
              Medically-based and guided by physicians, we’ve helped thousands
              of people transform their life and improve their health. As a
              participant, you’ll work one-on-one with our team of doctors,
              registered dietitians, certified personal trainers, and licensed
              counselors. 20/20 LifeStyles provides a 100% personalized program
              that focuses on long term weight management rather than just
              weight loss. No other weight loss program gives you more.
            </p>
            <p>
              A healthier life is waiting for you. Let our team of experts help
              get you there.
            </p>
            <p>
              20/20 LifeStyles is available at PRO Club in Bellevue or Seattle.
            </p>
          </div>
        ),
        button: {
          link: routes.SCHEDULE_A_CONSULTATION,
          text: 'schedule a free consultation',
          isExternal: false,
          align: true
        }
      }
    ]
  }
];

const sections = [
  {
    title: 'The tools for your goals',
    sectionExtraClassName: 'homeResourcesBlock',
    textBlocks: [
      {
        text: '20/20 LifeStyles will be with you every step of the way so you can lose weight and keep it off. Visit our resources to help you succeed.'
      },
      {
        text: (
          <div className='homeResourcesBlocks'>
            <div className='resourcesBlock'>
              <a href={routes.RESOURCES_AND_TOOLS_RECIPES}>
                <img className='image' src={recipesBlockImg} alt='Recipes' />
                <h4 className='title'>Recipes</h4>
              </a>
            </div>
            <div className='resourcesBlock'>
              <a href={routes.RESOURCES_AND_TOOLS_BLOG}>
                <img className='image' src={blogBlockImg} alt='Blog' />
                <h4 className='title'>Blog</h4>
              </a>
            </div>
            <div className='resourcesBlock'>
              <a href={routes.RESOURCES_AND_TOOLS_FAQS}>
                <img className='image' src={faqBlockImg} alt='FAQ' />
                <h4 className='title'>FAQ</h4>
              </a>
            </div>
          </div>
        )
      }
    ]
  }
];

export { heroSlider, topSections, sections, meta };
