exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".RecipesPage__loader--1psuI{min-height:100px;display:-webkit-box;display:-ms-flexbox;display:flex;-moz-justify-content:center;-ms-justify-content:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-align-items:center;-moz-align-items:center;-ms-align-items:center;align-items:center}.RecipesPage__loaderIcon--yrOMi{height:100px}.notFoundMsg{padding:40px 20px 70px;text-align:center;color:#333;font-weight:500}\n", ""]);

// exports
exports.locals = {
	"loader": "RecipesPage__loader--1psuI",
	"loaderIcon": "RecipesPage__loaderIcon--yrOMi"
};