import { composeReducer } from 'redux-compose-reducer';

const initialState = {
  data: {
    seminars: []
  }
};

function getSeminars(state, { data }) {
  const seminars = data.seminars;
  return {
    ...state,
    data: {
      ...state.data,
      seminars
    }
  };
}



export default composeReducer(
  'seminars',
  {
    getSeminars
  },
  initialState
);
