exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Breadcrumbs__breadcrumb--3viQg{position:relative;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-align-items:center;-moz-align-items:center;-ms-align-items:center;align-items:center;margin-bottom:30px}@media screen and (max-width: 767px){.Breadcrumbs__breadcrumb--3viQg{max-width:85%}}.ant-breadcrumb{font-size:16px;font-weight:500;text-transform:capitalize}@media screen and (max-width: 767px){.ant-breadcrumb{dddisplay:-webkit-box;display:-ms-flexbox;display:flex;-webkit-align-items:center;-moz-align-items:center;-ms-align-items:center;align-items:center;-webkit-flex-wrap:wrap;-moz-flex-wrap:wrap;-ms-flex-wrap:wrap;flex-wrap:wrap}}.ant-breadcrumb>span:last-child{color:#8D8D8D}.ant-breadcrumb>span:last-child a{color:#8D8D8D}.ant-breadcrumb a{color:#3b97b8}.ant-breadcrumb a:hover,.ant-breadcrumb a:focus,.ant-breadcrumb a:active{text-decoration:none;color:#0E3E61}.ant-breadcrumb-separator{margin:0 7px;color:#3b97b8;font-weight:700}\n", ""]);

// exports
exports.locals = {
	"breadcrumb": "Breadcrumbs__breadcrumb--3viQg"
};