export const routes = {
  VERSION: '/ver',
  HOME: '/',
  SUCCESS_STORIES: '/success-stories',
  EXPERTS: '/experts',
  RESOURCES_AND_TOOLS: '/resources-and-tools',
  RESOURCES_AND_TOOLS_BLOG: '/resources-and-tools/blog',
  RESOURCES_AND_TOOLS_RECIPES: '/resources-and-tools/recipes',
  RESOURCES_AND_TOOLS_FAQS: '/resources-and-tools/faq',
  RESOURCES_AND_TOOLS_TRACKER: '/resources-and-tools/tracker',
  RESOURCES_AND_TOOLS_WORK_OUTS: '/resources-and-tools/work-outs',
  RESOURCES_AND_TOOLS_MEAL_PLANS: '/resources-and-tools/meal-plans',
  PROGRAM_INFORMATION: '/program-information',
  PROGRAM_INFORMATION_WHY_IT_WORKS: '/program-information/why-it-works',
  PROGRAM_INFORMATION_CORPORATE_SOLUTIONS:
    '/program-information/corporate-solutions',
  PROGRAM_INFORMATION_PRICING: '/program-information/program-fees',
  PROGRAM_INFORMATION_SCIENTIFIC_HEALTH_MONITORING:
    '/program-information/scientific-health-monitoring',
  ABOUT_US: '/about-us',
  CONTACT_US: '/contact-us',
  TERMS_OF_USE: '/terms-of-use',
  TERMS_OF_SALE: '/terms-of-sale',
  TERMS_OF_SERVICE: '/terms-of-service',
  PRIVACY_POLICY: '/privacy-policy',
  CAREERS: '/careers',
  SCHEDULE_A_CONSULTATION: '/schedule-a-consultation',
  ATTEND_A_SEMINAR: '/attend-a-seminar',
  REFER_A_FRIEND: '/refer-a-friend',
  MAINTENANCE: '/maintenance'
};

export default routes;
