import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { TimeOfDays, Stages, Dishes, Difficulties } from './components';

import styles from './Filters.scss';

class Filters extends PureComponent {
  static propTypes = {
    time_of_days: PropTypes.object.isRequired,
    dishes: PropTypes.object.isRequired,
    difficulties: PropTypes.object.isRequired,
    stages: PropTypes.object.isRequired,
    recipesState: PropTypes.object.isRequired,
    setRecipesState: PropTypes.func,
    defaultStage: PropTypes.string,
    location: PropTypes.object
  };

  render() {
    const {
      time_of_days,
      dishes,
      difficulties,
      stages,
      recipesState,
      setRecipesState,
      defaultStage,
      location
    } = this.props;

    return (
      <div className={cx('clearfix', styles.filters)}>
        <TimeOfDays
          recipesState={recipesState}
          time_of_days={time_of_days}
          setRecipesState={setRecipesState}
        />
        <Dishes
          recipesState={recipesState}
          dishes={dishes}
          setRecipesState={setRecipesState}
        />
        <Difficulties
          recipesState={recipesState}
          difficulties={difficulties}
          setRecipesState={setRecipesState}
        />
        <Stages
          recipesState={recipesState}
          stages={stages}
          setRecipesState={setRecipesState}
          defaultStage={defaultStage}
          location={location}
        />
      </div>
    );
  }
}

export default withStyles(styles)(Filters);
