exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".BrowserRequirements__modalWrapper--W8oNK{display:-webkit-box;display:-ms-flexbox;display:flex;-moz-justify-content:center;-ms-justify-content:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-align-items:center;-moz-align-items:center;-ms-align-items:center;align-items:center}.BrowserRequirements__modalWrapper--W8oNK .ant-modal{top:0;margin:0 10px;min-height:auto !important}.BrowserRequirements__textWrapper--2w_i5{color:#2d3239;margin:50px 24px 40px;font-size:16px;text-align:justify}.BrowserRequirements__textWrapper--2w_i5 a{color:#3B97B8;font-weight:bold}.BrowserRequirements__textWrapper--2w_i5 a:hover,.BrowserRequirements__textWrapper--2w_i5 a:focus{color:#3B97B8}@media (max-width: 767px){.BrowserRequirements__textWrapper--2w_i5{margin:50px 10px 30px}}\n", ""]);

// exports
exports.locals = {
	"modalWrapper": "BrowserRequirements__modalWrapper--W8oNK",
	"textWrapper": "BrowserRequirements__textWrapper--2w_i5"
};