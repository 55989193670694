import React from 'react';

import comparisonImg from 'assets/images/2020_comparisionchart_website.jpg';

const noMarginBottom = { marginBottom: '0px' };

const meta = {
  title: 'Program Fees',
}

const pricings = [
  {
    name: 'Phase 1',
    prices: [
      {
        tableHead: {
          Plan: 'Plan',
          BMI: 'BMI',
          Member: 'Member',
          NonMember: 'Non-member',
          Duration: 'Duration'
        },
        plan: 'Plan 1',
        startBMI: 27,
        endBMI: 30,
        memberPricing: '$7,900',
        nonMemberPricing: '$8,700',
        duration: '12 weeks'
      },
      {
        tableHead: {
          Plan: 'Plan',
          BMI: 'BMI',
          Member: 'Member',
          NonMember: 'Non-member',
          Duration: 'Duration'
        },
        plan: 'Plan 2',
        startBMI: 31,
        endBMI: 33,
        memberPricing: '$10,300',
        nonMemberPricing: '$11,300',
        duration: '16 weeks'
      },
      {
        tableHead: {
          Plan: 'Plan',
          BMI: 'BMI',
          Member: 'Member',
          NonMember: 'Non-member',
          Duration: 'Duration'
        },
        plan: 'Plan 3',
        startBMI: 34,
        endBMI: 36,
        memberPricing: '$12,800',
        nonMemberPricing: '$13,900',
        duration: '20 weeks'
      },
      {
        tableHead: {
          Plan: 'Plan',
          BMI: 'BMI',
          Member: 'Member',
          NonMember: 'Non-member',
          Duration: 'Duration'
        },
        plan: 'Plan 4',
        startBMI: 37,
        endBMI: 40,
        memberPricing: '$14,900',
        nonMemberPricing: '$16,200',
        duration: '24 weeks'
      },
      {
        tableHead: {
          Plan: 'Plan',
          BMI: 'BMI',
          Member: 'Member',
          NonMember: 'Non-member',
          Duration: 'Duration'
        },
        plan: 'Plan 5',
        startBMI: 41,
        endBMI: 44,
        memberPricing: '$16,900',
        nonMemberPricing: '$18,400',
        duration: '28 weeks'
      },
      {
        tableHead: {
          Plan: 'Plan',
          BMI: 'BMI',
          Member: 'Member',
          NonMember: 'Non-member',
          Duration: 'Duration'
        },
        plan: 'Plan 6',
        startBMI: 45,
        memberPricing: '$18,900',
        nonMemberPricing: '$20,600',
        duration: '32 weeks'
      }
    ]
  },
  {
    name: 'Phase 2',
    prices: [
      {
        tableHead: {
          Member: 'Member',
          NonMember: 'Non-member',
          Duration: 'Duration'
        },
        memberPricing: '$3,400',
        nonMemberPricing: '$3,900',
        duration: '12 weeks'
      }
    ]
  }
]

const page = {
  name: 'PROGRAM FEES',
};

const topSection = [
  {
    sectionExtraClassName: 'noTopPadding',
    textBlocks: [
      {
        align: 'left',
        text: 'The fees for 20/20 LifeStyles include weekly one-on-one appointments with a registered dietitian, three sessions per week with a personal trainer, every other week session with a licensed counselor, medical supervision by a physician, and all access to PRO Club fitness facilities, group classes, and amenities for the duration of the program.'
      },
      {
        align: 'left',
        text: 'Insurance may be used to cover medically billed appointments, HSA/FSA may be used toward the program, and/or program fees may be subsidized by an employer benefit program. We offer financing and payment plans.'
      }
    ]
  },
];

const bottomSection = [
  {
    sectionExtraClassName: 'noTopPadding',
    textBlocks: [
      {
        align: 'left',
        title: 'Comparing Weight Loss Programs',
        text: 'Only 20/20 LifeStyles provides you with real experts and all the tools you need to be successful with long-term weight loss.'
      },
      {
        image: {
          src: comparisonImg,
          align: 'center'
        }
      }
    ]
  },
];

export {
  page,
  topSection,
  bottomSection,
  meta,
  pricings
};
