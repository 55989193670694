import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Breadcrumb } from 'antd';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import styles from './Breadcrumbs.scss';

const Breadcrumbs = (props) => {
  const { location, customName } = props;
  const pathSnippets = location.pathname.split('/').filter(i => i);
  const extraBreadcrumbItems = pathSnippets.map((snippet, index, array) => {
    const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
    return (
      <Breadcrumb.Item key={url}>
        { index !== array.length - 1 ?
          <Link to={url}>
            {snippet.split('-').join(' ').split('and').join('&')}
          </Link> :
          <span>{customName || snippet.split('-').join(' ').split('and').join('&')}</span>
        }
      </Breadcrumb.Item>
    );
  });
  const breadcrumbItems = [(
    <Breadcrumb.Item key='home'>
      <Link to='/'>Home</Link>
    </Breadcrumb.Item>
  )].concat(extraBreadcrumbItems);
  const className = (props && props.className) || '';
  return (
    <div className={cx(styles.breadcrumb, className)}>
      <div className='inner'>
        <Breadcrumb separator='>'>
          {breadcrumbItems}
        </Breadcrumb>
      </div>
    </div>
  );
};

Breadcrumbs.propTypes = {
  location: PropTypes.object,
  className: PropTypes.string,
  customName: PropTypes.string
};

export default withStyles(styles)(Breadcrumbs);
